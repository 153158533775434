import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Survey from "components/Survey";

function SurveyView({ code, registry }) {
  const dispatch = useDispatch();

  const api = useSelector((state) => state.API);
  const userData = useSelector((state) => state.userData);

  const user = React.useMemo(() => (userData ? { ...userData.biportal, token: userData.token } : null), [userData]);

  const handleSurveyClose = React.useCallback(async () => {
    const tree = await api.getTree();
    const foundTree = tree.find(({ registry }) => registry === "answer_registry");
    dispatch({
      type: "SET_CURRENT",
      value: {
        current: {
          id: "answer_registry",
          registry: "answer_registry",
          properties: { title: foundTree?.properties?.title, hint: null, icon: foundTree?.properties?.icon },
          type: "registry",
        },
      },
    });
    window.dispatchEvent(new CustomEvent("CLOSE_PAGE", { detail: code + "-survey" }));
  }, [api, dispatch, code]);

  if (!user) return;

  return <Survey code={code} user={user} onClose={handleSurveyClose} registry={registry} />;
}

export default React.memo(SurveyView);
