import React from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";

import { ActionDialog } from "shared/ui";

import SurveyPDF from "components/PDF/SurveyPDF";

function SurveyPdfLoader({ onOk, registry, title, code }) {
  const { enqueueSnackbar } = useSnackbar();
  const api = useSelector((state) => state.API);

  const [loading, setLoading] = React.useState(false);
  const [isPdfLoaded, setPdfLoaded] = React.useState(true);

  const handlePdfLoaded = React.useCallback(() => setPdfLoaded(true), []);

  const handleDownloadClick = React.useCallback(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_API + "/pdf", {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
        token: api.token,
        registry,
        code
      }),
    })
      .then((res) => {
        if (!res.ok) return null;
        return res.blob();
      })
      .then((res) => {
        if (!res) {
          enqueueSnackbar("Ошибка", { variant: "error" });
          return;
        }
        const a = document.createElement("a");
        a.download = (title || code) + ".pdf";
        a.href = URL.createObjectURL(res);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => setLoading(false));
  }, [api, code, enqueueSnackbar, registry, title]);

  return (
    <ActionDialog maxWidth="1160px" onClose={onOk} open title={title}>
      <Container>
        <PdfContainer>
          <SurveyPDF onLoadSuccess={handlePdfLoaded} token={api.token} code={code} />
        </PdfContainer>
        <DownloadButtonContainer>
          <Button disabled={loading || !isPdfLoaded} onClick={handleDownloadClick} variant="contained">
            Скачать
            {loading && <CircularProgress style={{ marginLeft: 8 }} size={16} />}
          </Button>
        </DownloadButtonContainer>
      </Container>
    </ActionDialog>
  );
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  gap: 16px;
`;

const PdfContainer = styled("div")`
  flex: 1;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 2px;
`;

const DownloadButtonContainer = styled("div")`
  display: flex;
  justify-content: right;
`;

export default React.memo(SurveyPdfLoader);
