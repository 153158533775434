import React from "react";
import styled from "styled-components";

import { ReactComponent as CheckedIcon } from "./checkbox-checked.svg";
import { ReactComponent as UncheckedIcon } from "./checkbox-empty.svg";

interface CustomCheckboxInterface {
  checked: boolean;
  onChange: (value: boolean) => void;
}

function CustomCheckbox({ checked, onChange }: CustomCheckboxInterface) {
  const handleCheckboxToggle = React.useCallback(() => onChange(!checked), [checked, onChange]);

  return <CheckboxWrapper onClick={handleCheckboxToggle}>{checked ? <CheckedIcon /> : <UncheckedIcon />}</CheckboxWrapper>;
}

const CheckboxWrapper = styled("div")`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border-radius: 50%;
  transition: background 0.15s ease-in-out;

  &:hover {
    background: rgba(0, 113, 227, 0.04);
  }

  &:active {
    background: rgba(0, 113, 227, 0.08);
  }
`;

export default React.memo(CustomCheckbox);
