import React from "react";

import { ReactComponent as LeftIcon } from "@mdi/svg/svg/chevron-left.svg";
import { ReactComponent as RightIcon } from "@mdi/svg/svg/chevron-right.svg";

import {
  BlockContainer,
  CardBody,
  CardContainer,
  CardTitle,
  CardsContainer,
  ScrollButton,
  ScrollButtonsContainer,
  TitleContainer,
  TitleText,
} from "../Components";

import { ReactComponent as TitleIcon } from "./often-visited.svg";

function OftenVisitedView() {
  return (
    <BlockContainer>
      <TitleContainer>
        <TitleIcon />
        <TitleText>Часто посещаемые разделы</TitleText>
        <ScrollButtonsContainer>
          <ScrollButton disabled>
            <LeftIcon />
          </ScrollButton>
          <ScrollButton>
            <RightIcon />
          </ScrollButton>
        </ScrollButtonsContainer>
      </TitleContainer>
      <CardsContainer>
        <CardContainer>
          <CardTitle>Реестр заявок на регистрацию пользователей</CardTitle>
          <CardBody>Формирование заявок на регистрацию</CardBody>
        </CardContainer>
        <CardContainer>
          <CardTitle>Единый регистр пользователей</CardTitle>
          <CardBody>Формирование заявок на регистрацию</CardBody>
        </CardContainer>
        <CardContainer>
          <CardTitle>Реестр согласования документов</CardTitle>
          <CardBody>Обеспечение юридической значимости документов</CardBody>
        </CardContainer>
        <CardContainer>
          <CardTitle>Реестр нарушений</CardTitle>
          <CardBody>Административное производство</CardBody>
        </CardContainer>
      </CardsContainer>
    </BlockContainer>
  );
}

export default React.memo(OftenVisitedView);
