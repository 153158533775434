import React from "react";
import { capitalize } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import ItemDialog from "components/itemDialog";

const PROPS_SCHEMA = [
  ["name", "text"],
  ["code", "text"],
  ["id", "text"],
  ["description", "text"],
  ["icon", "file"],
  ["picture", "file"],
  ["production_address", "text"],
  ["development_address", "text"],
  ["test_address", "text"],
  ["sort_order", "text"],
  ["create_datetime", "Date"],
  ["edit_datetime", "Date"],
  ["tags", "text"],
  ["author_display_name", "text"],
  ["author_login", "text"],
  ["author_id", "text"],
  ["editor_display_name", "text"],
  ["editor_login", "text"],
  ["editor_id", "text"],
  ["status_name", "text"],
  ["status_code", "text"],
  ["status_id", "text"],
  ["status_icon", "file"],
  ["workflow_name", "text"],
  ["workflow_code", "text"],
  ["workflow_role_id", "text"],
  ["workflow_icon", "file"],
];

const PropertiesEditor = () => {
  const dispatch = useDispatch();

  const api = useSelector((state) => state.API);
  const propertiesData = useSelector((state) => state.propertiesData);

  const schema = React.useMemo(() => {
    if (propertiesData?.current?.id !== "server") return null;
    return {
      props: PROPS_SCHEMA.map(([property, type]) => {
        const isImage = type === "file";
        const isDate = type === "Date";
        const isId = property === "id";
        return {
          value: property,
          type: type,
          label: capitalize(property.replaceAll("_", " ")),
          data: {
            autoSelectDate: isDate,
            disabled: isImage || isId,
            disableClean: isId,
          },
        };
      }).sort((a, b) => a.label - b.label),
    };
  }, [propertiesData]);

  const handlePropertiesClose = React.useCallback(
    () => dispatch({ type: "SET_PROPERTIES_DATA", value: null }),
    [dispatch]
  );

  return (
    <ItemDialog
      api={api}
      isDialog={false}
      schema={schema}
      isEdit={true}
      type={propertiesData?.current?.type}
      onOk={() => {
        console.log("ON OK PropertyEditor");
      }}
      onClose={handlePropertiesClose}
      prop={propertiesData}
      selfOrParentId={propertiesData?.current?.id}
      self={propertiesData}
    />
  );
};

export default React.memo(PropertiesEditor);
