function getChild(apiGet, props, stopNodes, autoExpandNodes, nodeInfo) {
  getNode(apiGet, nodeInfo, null, null, props, stopNodes, autoExpandNodes);
}

const updateNodeFromProperties = (node) => {
  // когда объект удалён, но сервер еще не прислал сообщение об изменение -> node.data.current == null
  node.icon = () => node.data.current?.properties?.icon;
  node.name = () => node.data.current?.properties?.title;
  node.actual = () => node.data.current?.properties?.actual !== false;
  node.iconType = node.data.current?.type;
  return node;
};

export const NodeFromItem = (
  apiGet,
  data,
  props,
  stopNodes = [],
  autoExpandNodes = []
) => {
  const isLeaf = data.current.size === 0;

  const type = data.current?.type;
  const stopNode = stopNodes.indexOf(type) !== -1;
  const expanded = autoExpandNodes.indexOf(type) !== -1;

  return updateNodeFromProperties({
    data,
    children:
      isLeaf || stopNode
        ? null
        : getChild.bind(null, apiGet, props, stopNodes, autoExpandNodes),
    id: data.current.id,
    size: isLeaf || stopNode ? 0 : data.current.size,
    props,
    stopNode,
    expanded,
  });
};

// props used in DoubleListDialog
export function getNode(
  apiGet,
  nodeInfo,
  rootId,
  setStateFunc,
  props,
  stopNodes = [],
  autoExpandNodes = []
) {
  const parentID = nodeInfo ? nodeInfo.node.data.current.id : rootId;

  // StaticSelect риходит с rootId = null
  if (parentID === undefined) {
    nodeInfo ? nodeInfo.done([]) : setStateFunc([]);
    return;
  }

  apiGet(parentID).then((res) => {
    const node = [];
    res?.forEach &&
      res.forEach((i) =>
        node.push(NodeFromItem(apiGet, i, props, stopNodes, autoExpandNodes))
      );
    //    if (parentID === rootId) {
    //      node.expanded = true
    //    }
    if (node.length === 1) {
      node[0].expanded = true;
    }
    nodeInfo ? nodeInfo.done(node) : setStateFunc(node);
  });
}

export async function syncNode(
  apiGet,
  nodeInfo,
  treeData,
  rootId,
  stopNodes = [],
  autoExpandNodes = []
) {
  nodeInfo &&
    !Array.isArray(nodeInfo.node.children) &&
    (nodeInfo.node.children = []);
  const map = {}; // ; нужна

  (nodeInfo?.node.children || treeData).forEach(
    (item) => (map[item.id] = item)
  );

  const parentID = nodeInfo ? nodeInfo.node.data.current.id : rootId;
  const res = await apiGet(parentID);

  if (!res?.forEach) return;

  const node = nodeInfo ? nodeInfo.node.children : treeData;
  node.length = 0;
  res.forEach((i, ndx, self) => {
    const item = NodeFromItem(
      apiGet,
      i,
      nodeInfo?.node?.props,
      stopNodes,
      autoExpandNodes
    );
    const was = map[item.id];
    if (was) {
      item.children = was.children;
      item.expanded = was.expanded;
    }
    node.push(item);
  });
  nodeInfo && (nodeInfo.node.size = node.length);
}
