/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { ButtonEmpty, ButtonNoBind, TooltipR } from "shared/ui/ToolBar";

import { ReactComponent as MoveRightIcon } from "@mdi/svg/svg/chevron-right.svg";
import { ReactComponent as MoveRightAllIcon } from "@mdi/svg/svg/chevron-double-right.svg";
import { ReactComponent as MoveLeftIcon } from "@mdi/svg/svg/chevron-left.svg";
import { ReactComponent as MoveLeftAllIcon } from "@mdi/svg/svg/chevron-double-left.svg";

import { ActionDialog } from "shared/ui";
import {
  moveFromTo,
  moveFromToAll,
  MultiSelectTree,
  walk,
  getNode,
} from "./MultiList";

export const DoubleList = (props) => {
  const {
    setChanged,
    style = {},
    apiSource,
    updateValue,
    updateSourceList,
    apiDestination,
    destinationData,
    sourceData,
    fromLabel,
    toLabel,
    onChange,
  } = props;

  const from = {
    d: useState([]),
    s: useState([]),
    n: useState([]),
    filter: useRef(),
    ready: useState(false),
    update: function (d) {
      from.d[1](d);
      setChanged?.(true);
      onChange?.(d, to1.d[0]);
    },
  };

  const to1 = {
    d: useState([]),
    s: useState([]),
    n: useState([]),
    filter: useRef(),
    ready: useState(false),
    update: function (d) {
      to1.d[1](d);
      setChanged?.(true);
      onChange?.(from.d[0], d);
    },
  };
  const to2 = {
    d: useState([]),
    s: useState([]),
    n: useState([]),
    filter: useRef(),
    ready: useState(false),
    update: function (d) {
      to2.d[1](d);
      setChanged?.(true);
    },
  };

  const destinationDataArray = Array.isArray(destinationData)
    ? destinationData
    : [destinationData];
  const to = destinationDataArray.map((data, ndx) => [to1, to2][ndx]);

  if (updateSourceList)
    updateSourceList.current = (data) => (
      getNode(
        () => Promise.resolve(data),
        null,
        sourceData,
        (state) => {
          from.d[1](state);
          from.s[1]([]);
          from.ready[1](true);
        },
        { _id: 1 },
        props.stopNodes,
        props.autoExpandNodes
      )
    );

  if (updateValue)
    updateValue.current = (sourceLanguage) => {
      if (!sourceLanguage) return;
      const foundSource = to[0].d[0].find(({ id }) => id === sourceLanguage);
      if (!foundSource) return;
      to[0].d[1]((prev) => prev.filter(({ id }) => id !== sourceLanguage));
      from.d[1]((prev) =>
        [...prev, foundSource].sort((a, b) =>
          a.data.current.properties.title > b.data.current.properties.title
            ? 1
            : -1
        )
      );
    };

  const keyFunction = (event) => {
    if (
      event.ctrlKey === true &&
      (event.keyCode === 8 || event.keyCode === 46)
    ) {
      to.forEach((i) => i.n[1]([]));
      from.n[1]([]);
      to.forEach((i) => i.s[1]([]));
      from.s[1]([]);
    }
  };

  useEffect(() => {
    if (
      !(
        from.ready[0] === true &&
        to.reduce((f, i) => f && i.ready[0] === true, true)
      )
    ) {
      return;
    }

    // TODO
    // get flat list of tables and remove from another list tables what already has
    // or another strategy? for example, ask server only needed tables
    // in this case (select Tables) we can only do: remove existing tables from list of db-tables (but we need flat list of tables names)
  }, [from.ready[0], ...to.map((i) => i.ready[0])]);

  useEffect(() => {
    if (!sourceData) {
      return;
    }

    to.forEach((i) => {
      i.d[0].length = 0;
      i.s[1]([]);
      i.n[1]([]);
      i.ready[0] = false;
    });

    from.d[0].length = 0;
    from.s[1]([]);
    from.n[1]([]);
    from.ready[0] = false;

    // FIXME: отменть promise при unmount
    apiSource &&
      getNode(
        apiSource,
        null,
        sourceData,
        (state) => {
          from.d[1](state);
          from.ready[1](true);
        },
        { _id: 1 },
        props.stopNodes,
        props.autoExpandNodes
      );

    // destinationData может быть null
    apiDestination &&
      to.forEach((i, ndx) =>
        getNode(
          apiDestination,
          null,
          destinationDataArray[ndx],
          (state) => {
            if (state.length === 1 && "expanded" in state[0])
              state[0].expanded = false;
            i.d[1](state);
            i.ready[1](true);
          },
          { _id: 2 + ndx },
          props.stopNodes,
          props.autoExpandNodes
        )
      );

    document.addEventListener("keydown", keyFunction);
  }, [sourceData]); // destinationData - недолжна меняться

  useEffect(() => {
    return () => {
      document.removeEventListener("keydown", keyFunction);
    };
  }, []);

  const handleOk = () => {
    const getNodeKey = ({ node }) => node.id;

    const _removed = Array.from(
      { length: destinationDataArray.length },
      (e) => []
    );
    const _inserted = Array.from(
      { length: destinationDataArray.length },
      (e) => []
    );

    const handle_removed = (removed, ndx, info) => {
      if (info.node.props["_id"] === ndx) removed.push(info.node.data);
    };
    const handle_insered = (inserted, ndx, info) => {
      if (info.node.props["_id"] === ndx) inserted.push(info.node.data);
    };

    destinationDataArray.forEach((i, f_ndx) => {
      walk({
        treeData: from.d[0],
        getNodeKey,
        callback: handle_removed.bind(null, _removed[f_ndx], 2 + f_ndx),
        ignoreCollapsed: false,
      });
      walk({
        treeData: to[f_ndx].d[0],
        getNodeKey,
        callback: handle_insered.bind(null, _inserted[f_ndx], 1),
        ignoreCollapsed: false,
      });
      destinationDataArray.forEach((i, t_ndx) => {
        if (f_ndx === t_ndx) return;
        walk({
          treeData: to[t_ndx].d[0],
          getNodeKey,
          callback: handle_removed.bind(null, _removed[f_ndx], 2 + f_ndx),
          ignoreCollapsed: false,
        });
        walk({
          treeData: to[t_ndx].d[0],
          getNodeKey,
          callback: handle_insered.bind(null, _inserted[f_ndx], 2 + f_ndx),
          ignoreCollapsed: false,
        });
      });
    });

    return {
      removed: _removed.length === 1 ? _removed[0] : _removed,
      inserted: _inserted.length === 1 ? _inserted[0] : _inserted,
    };
  };
  props.handleOk && (props.handleOk.current = handleOk);

  const addIcon = document.dir === "rtl" ? MoveLeftIcon : MoveRightIcon;
  const addAllIcon =
    document.dir === "rtl" ? MoveLeftAllIcon : MoveRightAllIcon;
  const removeIcon = document.dir === "rtl" ? MoveRightIcon : MoveLeftIcon;
  const removeAllIcon =
    document.dir === "rtl" ? MoveRightAllIcon : MoveLeftAllIcon;

  const toolbar = (ndx) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0.5em",
      }}
    >
      <TooltipR text="Перенести показатели вправо">
        {ButtonNoBind(
          1,
          from.s[0].length ? "action" : "disabled",
          addIcon,
          moveFromTo.bind(null, from, to[ndx])
        )}
      </TooltipR>
      <TooltipR text="Перенести все показатели вправо">
        {ButtonNoBind(
          2,
          from.d[0].length ? "action" : "disabled",
          addAllIcon,
          moveFromToAll.bind(null, from, to[ndx])
        )}
      </TooltipR>
      <TooltipR text="Перенести показатели влево">
        {ButtonNoBind(
          4,
          to[ndx].s[0].length ? "action" : "disabled",
          removeIcon,
          moveFromTo.bind(null, to[ndx], from)
        )}
      </TooltipR>
      <TooltipR text="Перенести все показатели влево">
        {ButtonNoBind(
          5,
          to[ndx].d[0].length ? "action" : "disabled",
          removeAllIcon,
          moveFromToAll.bind(null, to[ndx], from)
        )}
      </TooltipR>
    </div>
  );

  return (
    <div style={{ display: "flex", flexGrow: 1, height: "100%", ...style }}>
      {MultiSelectTree(from, fromLabel, moveFromTo.bind(null, from, to[0]))}
      <div
        style={{ display: "flex", flex: "1 1 auto", flexDirection: "column" }}
      >
        {(Array.isArray(toLabel) ? toLabel : [toLabel]).map(
          (label, ndx, self) => (
            <React.Fragment key={ndx}>
              <div
                style={{
                  display: "flex",
                  flex: "1 1 auto",
                  flexDirection: "row",
                }}
              >
                {toolbar(ndx)}
                {MultiSelectTree(
                  to[ndx],
                  label,
                  moveFromTo.bind(null, to[ndx], from)
                )}
              </div>
              {ndx !== self.length - 1 && <div style={{ minHeight: "1em" }} />}
            </React.Fragment>
          )
        )}
      </div>
    </div>
  );
};

export const DoubleListDialog = (props) => {
  const { onClose, onOk, title } = props;
  const onResult = useRef();

  const handleClose = () => {
    onClose();
  };
  const handleOk = () => {
    onOk(onResult.current && onResult.current());
    handleClose();
  };

  return (
    <ActionDialog
      withAction
      open={true}
      onOk={handleOk}
      onClose={handleClose}
      title={title}
      direction={"row"}
      paperStyle={{ height: "75%", maxWidth: "50%" }}
    >
      <DoubleList {...props} handleOk={onResult} />
    </ActionDialog>
  );
};
