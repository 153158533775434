import React from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

import { INITIAL_HEIGHT, removePageOverflow } from "../utils";

import { CenteredText, Container, Divider, RightContainer, RightContent, Table, TableContent, TableRow, TableTitle } from "../components";

function PdfGenerator({ onLoadSuccess, api, landscape, preview, showdata, registryCode, rowCode, version }) {
  const pageContainer = React.useRef(null);

  const [groups, setGroups] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [rowData, setRowData] = React.useState(null);
  const [component, setComponent] = React.useState(null);
  const [references, setReferences] = React.useState({});
  const [registryError, setRegistryError] = React.useState(false);
  const [dataFromDescription, setDataFromDescription] = React.useState(null);
  const [dataFromList, setDataFromList] = React.useState(null);

  const initializeRegistry = React.useCallback(async () => {
    if (!registryCode || !rowCode) {
      return setRegistryError(true);
    }
    setLoading(true);
    setRegistryError(false);
    const registry = await api.call("registries/registry", { code: registryCode });
    if (typeof registry !== "object" || registry === null || registry?.error) return setRegistryError(true);

    const foundWidget = registry.widget.find(({ widget_type }) => widget_type === "print_view");
    if (!foundWidget || foundWidget.widget_type !== "print_view") return setRegistryError(true);

    const referenceKeys = Object.keys(registry.reference);
    const [data, dataFromDescription, dataFromList, ...references] = await Promise.all([
      api.send("registries/list", {
        page_size: 50,
        page_number: 0,
        sort_order: [],
        code: registryCode,
        filter: [[null, "code", "=", rowCode, false, null, "AND"]],
      }),
      api.send("registries/data/description", {
        code: rowCode,
        registry: registryCode,
      }),
      api.send("registries/data/list", {
        code: rowCode,
        registry: registryCode,
      }),
      ...referenceKeys.map((field) => api.call("registries/values", { code: registryCode, field })),
    ]);

    if (!Array.isArray(data)) return setRegistryError(true);
    if (Array.isArray(dataFromList)) setDataFromList(dataFromList);
    if (dataFromDescription?.field) setDataFromDescription(dataFromDescription);

    const foundRow = version ? data.find((row) => row.version === version) : data[0];
    if (!foundRow) return setRegistryError(true);

    const groups = [...foundWidget.field_group.sort((a, b) => (+a.code[a.code.length - 1] > +b.code[b.code.length - 1] ? 1 : -1))];
    const foundComponentGroup = foundWidget.field_group.find((group) => group.group_type === "component_table") || null;
    if (foundComponentGroup) {
      const components = await api.send("registries/list", {
        code: registryCode,
        sort_order: [["ext_code", "ASC"]],
        page_size: 50,
        page_number: 0,
        associator_filter: {
          associator_code: "associator_comp",
          subject_field: "code_comp",
          object_code: rowCode,
          object_field: "indicator",
          field_code: "code",
        },
      });

      if (Array.isArray(components))
        components.forEach((data, index) =>
          groups.push({
            data,
            group_type: "component",
            label: index + 1 + ". " + data.value,
          })
        );
    }

    setRowData(foundRow);
    setComponent(foundComponentGroup);
    setGroups(groups);
    setReferences(Object.fromEntries(referenceKeys.map((key, index) => [key, Array.isArray(references[index]) ? references[index] : []])));
    setLoading(false);
    onLoadSuccess?.();
  }, [api, registryCode, rowCode, version, onLoadSuccess]);

  React.useEffect(() => initializeRegistry(), [initializeRegistry]);

  React.useEffect(() => {
    if (loading || registryError) return;

    if (showdata && (!Array.isArray(dataFromList) || dataFromList.length === 0 || !dataFromDescription?.field)) {
      document.dispatchEvent(new Event("pdf-empty"));
      return;
    }

    let height = INITIAL_HEIGHT;
    for (let i = 0; i < pageContainer.current.children.length; i++) {
      const children = pageContainer.current.children.item(i);
      height = removePageOverflow(children, height, landscape);
    }
    document.dispatchEvent(new Event("pdf-ready"));
  }, [loading, registryError, landscape, showdata, dataFromList, dataFromDescription]);

  const getFieldValue = React.useCallback(
    (field, data, source) => {
      if (!field) return null;

      const type = `${field.field_type || field.type}`.toLowerCase();
      if (!type) return null;

      if (type === "reference" && references[source]) {
        const value = data[source];
        const foundReference = references[source].find(({ code }) => (typeof code === "number" ? code === +value : code === value));
        return field.source_field ? foundReference?.[field.source_field] || null : foundReference?.value || value;
      }

      if (type === "date") return data[source] ? dayjs(data[source]).locale("ru").format("DD.MM.YYYY") : null;
      if (type === "datetime") return data[source] ? dayjs(data[source]).locale("ru").format("DD.MM.YYYY HH:mm") : null;

      if (type === "label") return source;
      if (type === "checkbox") return data[source] ? "Да" : "Нет";
      if (type === "float" || type === "parentchildclassifier") return data[source];

      if (type === "json_object_array") {
        let array = null;
        try {
          const parsedArray = JSON.parse(data[source]);
          if (Array.isArray(parsedArray)) array = parsedArray;
        } catch (error) {}

        if (!array) return null;
        return (
          <div style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
            {array.map(({ value }, index) => (
              <span>
                {value}
                {index !== array.length - 1 && ","}
              </span>
            ))}
          </div>
        );
      }

      return data[source];
    },
    [references]
  );

  const validateField = React.useCallback(
    (field, data = rowData) => {
      if (!field.visibility) return true;
      const [code] = field.visibility.split("=");
      return typeof data[code] === "boolean" ? data[code] : Boolean(+data[code]);
    },
    [rowData]
  );

  const renderHeader = React.useCallback(
    (group, groupIndex) => {
      return (
        <div key={groupIndex}>
          {group.field.map((field, fieldIndex) => {
            if (!validateField(field)) return null;
            const value = getFieldValue(field, rowData, field.source);
            if (field.source === "ext_code")
              return <CenteredText>{`№ ${value} от ${dayjs(new Date()).locale("ru").format("DD.MM.YYYY")}`}</CenteredText>;
            return value ? <CenteredText dangerouslySetInnerHTML={{ __html: value }} key={fieldIndex} /> : null;
          })}
          <Divider id="divider" />
        </div>
      );
    },
    [getFieldValue, rowData, validateField]
  );

  const renderTable = React.useCallback(
    (group, groupIndex) => {
      return (
        <div key={groupIndex}>
          <CenteredText>{group.value}</CenteredText>
          <Divider id="divider" small />
          <Table id="table">
            {group.field.map((field) => {
              if (!validateField(field)) return null;
              return (
                <TableRow id="row">
                  <TableTitle>{field.value}</TableTitle>
                  <TableContent>{getFieldValue(field, rowData, field.source)}</TableContent>
                </TableRow>
              );
            })}
          </Table>
          <Divider id="divider" />
        </div>
      );
    },
    [getFieldValue, rowData, validateField]
  );

  const renderApplicationsHeader = React.useCallback(
    (group, groupIndex) => {
      return (
        <div key={groupIndex}>
          <div id="page-break" />
          <RightContainer>
            <RightContent>
              <CenteredText dangerouslySetInnerHTML={{ __html: getFieldValue(group.field[0], rowData, group.field[0].source) }} />
              <CenteredText small dangerouslySetInnerHTML={{ __html: getFieldValue(group.field[1], rowData, group.field[1].source) }} />
            </RightContent>
          </RightContainer>
          {group.field[2] && (
            <>
              <Divider id="divider" medium />
              <CenteredText dangerouslySetInnerHTML={{ __html: group.field[2].source }} />
            </>
          )}
          <Divider id="divider" />
        </div>
      );
    },
    [getFieldValue, rowData]
  );

  const renderApplication = React.useCallback(
    (group, groupIndex, isLastPage) => {
      if (!component) return null;
      return (
        <div key={groupIndex}>
          <CenteredText>{group.label}</CenteredText>
          <Divider id="divider" small />
          <Table id="table">
            {component.field.map((field) => {
              if (!validateField(field, group.data)) return null;
              return (
                <TableRow id="row">
                  <TableTitle>{field.value}</TableTitle>
                  <TableContent>{getFieldValue(field, group.data, field.source)}</TableContent>
                </TableRow>
              );
            })}
          </Table>
          {!isLastPage && <div id="page-break" />}
        </div>
      );
    },
    [getFieldValue, validateField, component]
  );

  const renderShowData = React.useCallback(
    (group, groupIndex, isLastGroup) => {
      if (!Array.isArray(dataFromList) || dataFromList.length === 0 || !dataFromDescription?.field) return null;

      return (
        <div key={groupIndex}>
          <CenteredText>{group.value}</CenteredText>
          <Divider id="divider" small />
          <TableContainer id="table">
            <tr id="row">
              {dataFromDescription.field.map((field, fieldIndex) => (
                <TableHeadCell key={fieldIndex}>{field.label}</TableHeadCell>
              ))}
            </tr>
            {dataFromList.map((data, index) => (
              <tr id="row" key={index}>
                {dataFromDescription.field.map((field, index) => (
                  <TableBodyCell key={index}>{getFieldValue(field, data, field.field)}</TableBodyCell>
                ))}
              </tr>
            ))}
          </TableContainer>
          {!isLastGroup && <Divider id="divider" />}
        </div>
      );
    },
    [dataFromDescription, dataFromList, getFieldValue]
  );

  const renderGroup = React.useCallback(
    (group, groupIndex) => {
      if (preview) {
        if (group.group_type === "header") return renderHeader(group, groupIndex);
        if (group.group_type === "signature") return renderApplicationsHeader(group, groupIndex);
        if (group.group_type === "showdata") return renderShowData(group, groupIndex, groups.length - 1 === groupIndex);
        if (group.group_type === "component") return renderApplication(group, groupIndex, groups.length - 1 === groupIndex);
        if (group.group_type === "component_table") return null;
        return renderTable(group, groupIndex, groups.length - 1 === groupIndex);
      }

      if (showdata) {
        if (group.group_type === "showdata") return renderShowData(group, groupIndex, groups.length - 1 === groupIndex);
        return null;
      }

      if (group.group_type === "header") return renderHeader(group, groupIndex);
      if (group.group_type === "signature") return renderApplicationsHeader(group, groupIndex);
      if (group.group_type === "component") return renderApplication(group, groupIndex, groups.length - 1 === groupIndex);
      if (group.group_type === "showdata") return null;
      if (group.group_type === "component_table") return null;
      return renderTable(group, groupIndex, groups.length - 1 === groupIndex);
    },
    [groups, preview, renderApplication, renderApplicationsHeader, renderHeader, renderShowData, renderTable, showdata]
  );

  if (registryError)
    return (
      <Container>
        <CenteredText>Ошибка в запросе</CenteredText>
      </Container>
    );

  if (loading)
    return (
      <Container>
        <CenteredText>
          <CircularProgress size={32} />
        </CenteredText>
      </Container>
    );

  return (
    <Container landscape={landscape} ref={pageContainer}>
      {groups.map(renderGroup)}
    </Container>
  );
}

const TableContainer = styled("table")`
  border: 1px solid #000;
  border-right: 0;
  border-bottom: 0;
  border-spacing: 0;
`;

const TableHeadCell = styled("th")`
  border: 1px solid #000;
  border-left: 0;
  border-top: 0;
  text-align: center;
  font-size: 16px;
`;

const TableBodyCell = styled("td")`
  border: 1px solid #000;
  border-left: 0;
  border-top: 0;
  text-align: center;
  font-size: 16px;
  padding: 4px;
`;

export default React.memo(PdfGenerator);
