import React from "react";
import styled from "styled-components";

import TableEditor from "shared/ui/TableEditor";

import { ReactComponent as UploadIcon } from "@mdi/svg/svg/file-upload-outline.svg";
// import { ReactComponent as ApplicationIcon } from "@mdi/svg/svg/application-import.svg";
import { ReactComponent as KeyIcon } from "@mdi/svg/svg/key.svg";

import Filters from "./Filters";

import FileUploadForm from "../../FileUploadForm";

const trDims = [
  ["time", "Статус документооборота", "Memo"],
  ["code", "Дата загрузки", "Memo"],
  ["foiv", "ФОИВ", "Memo"],
  ["upload_user", "Тип объекта", "Memo"],
  ["sign_user", "Наименование объекта", "Memo"],
  ["file_name", "Статус загрузки", "Memo"],
  ["file_size", "Наименование файла", "Memo"],
  ["upload_type", "Способ загрузки", "Memo"],
  ["upload_errors", "Ошибки загрузки", "Memo"],
].map(([id, title, type]) => ({
  id,
  properties: {
    title,
    name: id,
    axis: "data",
    position: 0,
    sortOrder: "none",
    filter: [],
    textFilter: {},
    type: type,
    isEditable: false,
  },
}));

function Upload() {
  const [uploadFormVisibility, setUploadFormVisibility] = React.useState(false);

  const schema = React.useMemo(
    () => ({
      value: "roles",
      type: "Table",
      data: {
        id: "bundles-upload-table",
        trDims,
        toolbar: [
          [UploadIcon, () => setUploadFormVisibility(true), true, null, null, null, "Загрузить"],
          [KeyIcon, () => {}, true, null, null, null, "Подписать"],
          // [ApplicationIcon, () => {}, true, null, null, null, "Посмотреть"],
        ],
      },
    }),
    []
  );

  return (
    <Container>
      <Filters />
      <TableEditor schema={schema} />
      <FileUploadForm uploadFormOpened={uploadFormVisibility} setUploadFormOpened={setUploadFormVisibility} />
    </Container>
  );
}

const Container = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default React.memo(Upload);
