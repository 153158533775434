import React, { Component } from "react";

import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import SortableTree from "shared/ui/tree";
import BitArray from "shared/utils/BitArray";

import { ToolButton, ToolButtonCustom, ButtonSeparator, StyledButtonGroup } from "shared/ui/ToolBar";

import { ReactComponent as IconSelectAll } from "@mdi/svg/svg/select-all.svg";
import { ReactComponent as IconSelectNone } from "@mdi/svg/svg/select-off.svg";
import { ReactComponent as IconSelectInv } from "@mdi/svg/svg/select-compare.svg";
import { ReactComponent as IconCheck } from "@mdi/svg/svg/check.svg";
import { ReactComponent as IconCancel } from "@mdi/svg/svg/window-close.svg";

import { withTheme } from "@mui/styles";
import styled from "styled-components";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Input from "@mui/material/Input";

const InputWrapper = withTheme(styled("div")`
  ${({ theme }) => `
  padding: ${theme.spacing(1, 1 / 3)};
  margin: 1px;
  border: 1px solid transparent;
  background-color: ${theme.palette.background.paper};
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${theme.palette.divider};

  &:hover {
    border-color: ${theme.palette.primary.light};
  }

  & input {
    box-sizing: border-box;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    padding: 0;
    outline: 0;
  }
  `}
`);

class DimensionValuesTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOk: false,
      loading: false,
      filter: "",
      multipleValues: false,

      treeData: [],
      visible: [],
      checkedData: [],
      images: props.images,
      levels: null,
      bitArray: null,
    };
    this.updateTreeData = this.updateTreeData.bind(this);
    this.api = this.props.api;
  }

  updateTreeData(treeData) {
    this.setState({ treeData });
  }

  isValid = () => this.api && (this.props.dim?._id || (this.props.facts && this.props.factsItem));

  getNode(parent) {
    if (!this.isValid()) return;

    this.setState({ loading: true });
    if (this.props.facts) {
      const multipleValues = true;
      const bitArray = new BitArray(this.props.facts.length);
      this.props.facts.forEach((node, ndx) => {
        bitArray.set(ndx, node.properties.visible);
      });
      const data = this.getFactTreeData("");
      this.setState({
        bitArray,
        levels: 1,
        multipleValues,
        filter: "",
        treeData: data,
      });
      this.setState({ loading: false });
      return;
    }

    this.api
      .dimensionValues(this.props.dim._id, 0, 100, this.state.filter)
      .then((res) => {
        const data = this.getTreeData(res);
        const bitArray = new BitArray(this.props.dim.properties.unique);
        if (this.props.dim.properties.filter.length !== 0) bitArray.values = this.props.dim.properties.filter;
        bitArray.not();

        this.setState({
          bitArray,
          levels: 1,
          multipleValues: !(this.props.dim.properties.multipleValues === false),
          filter: "",
          treeData: data,
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.dim || this.props.dim === prevProps.dim) && (!prevProps.facts || this.props.facts === prevProps.facts)) return;

    if (this.props.facts) {
      const bitArray = new BitArray(this.props.facts.length);
      this.props.facts.forEach((node, ndx) => {
        bitArray.set(ndx, node.properties.visible);
      });
      this.setState({ bitArray });
      return;
    }

    const bitArray = new BitArray(this.props.dim.properties.unique);
    if (this.props.dim.properties.filter.length !== 0) bitArray.values = this.props.dim.properties.filter;
    bitArray.not();

    this.setState({ bitArray });
  }

  getFactTreeData = (filter) => {
    const f = filter && filter !== "" ? filter.toLowerCase() : null;
    return this.props.facts.reduce((result, node, ndx) => {
      const title = (node.properties.title || node.properties.name || "").toLowerCase();
      if (!f || title.includes(f))
        result.push({
          icon: () => node.properties.icon,
          name: () => title,
          actual: () => node.properties.actual !== false,
          iconType: node.nodeType,
          noChildren: true,
          id: ndx,
        });
      return result;
    }, []);
  };

  getTreeData = (res) => {
    const data = res?.values?.map((i) => ({
      name: () => i.value,
      icon: () => `/flags/${"am.svg"}`,
      actual: () => true,
      noChildren: true,
      id: i.index,
    }));
    return data;
  };

  componentDidMount() {
    this.getNode();
  }

  componentWillUnmount() {}

  handleCancel = () => {
    this.props.popupState.close();
  };

  handleOk = () => {
    if (!this.isValid()) return;

    this.setState({ loading: true });

    if (this.props.facts) {
      const v = this.props.facts.reduce((v, node, ndx) => {
        const visible = this.state.bitArray.get(ndx);
        if (visible) v.push(node._id);
        // for real-time result
        node.properties.visible = visible;
        return v;
      }, []);
      this.api
        .setFactsVisibility(this.props.factsItem._id, v)
        .then((res) => {})
        .finally(() => {
          this.setState({ loading: false });
          this.props.popupState && this.props.popupState.close();
          this.props.onApplyFilter && this.props.onApplyFilter();
        });
      return;
    }

    this.props.dim.properties.filter = this.state.bitArray.copy().not().values;
    this.api
      .update(this.props.dim._id, this.props.dim.properties)
      .then((res) => {})
      .finally(() => {
        this.setState({ loading: false });
        this.props.popupState && this.props.popupState.close();
        this.props.onApplyFilter && this.props.onApplyFilter();
      });
  };

  nodeClicked = (event, rowInfo) => {
    const bitArray = this.state.bitArray.copy();
    bitArray.toggle(rowInfo.node.id);
    this.setState({ bitArray });
  };

  handleSelectAll = () => {
    const bitArray = this.state.bitArray.copy();
    bitArray.fill(true);
    this.setState({ bitArray });
  };

  handleSelectNone = () => {
    const bitArray = this.state.bitArray.copy();
    bitArray.fill(false);
    this.setState({ bitArray });
  };

  handleInversion = () => {
    const bitArray = this.state.bitArray.copy();
    bitArray.not();
    this.setState({ bitArray });
  };

  handleFilterClear = () => {
    this.handleFilter("");
  };

  handleFilterChange = (event) => {
    const value = event.target.value;
    this.handleFilter(value);
  };

  handleFilter = (filter) => {
    if (!this.isValid()) return;

    if (this.props.facts) {
      const treeData = this.getFactTreeData(filter);
      this.setState({ treeData, filter });
      return;
    }

    this.setState({ filter, loading: true });
    this.api
      .dimensionValues(this.props.dim._id, 0, 100, filter)
      .then((res) => {
        const treeData = this.getTreeData(res);
        this.setState({ treeData });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { treeData, filter, multipleValues } = this.state;
    return (
      <Paper
        square
        elevation={1}
        style={{
          width: "200px",
          minWidth: "200px",
          flexGrow: 1,
          minHeight: "300px",
          height: "300px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InputWrapper>
          <Input
            inputRef={(input) => input && input.focus()}
            placeholder="Search"
            fullWidth={true}
            disableUnderline={true}
            autoFocus={true}
            value={filter}
            onChange={this.handleFilterChange}
            startAdornment={<SearchIcon color="primary" style={{ fontSize: "1.5em" }} />}
            endAdornment={
              filter && filter !== ""
                ? ToolButtonCustom(<CloseIcon style={{ fontSize: "1.5em" }} color={"error"} />, this.handleFilterClear, true, "Clear", 1)
                : null
            }
          />
        </InputWrapper>

        {this.state.loading ? (
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" size={"32px"} />
          </div>
        ) : (
          <SortableTree
            rowHeight={24}
            treeData={treeData}
            onChange={this.updateTreeData}
            getNodeKey={({ node }) => node.id}
            generateNodeProps={(rowInfo) => ({
              onClick: (event) => this.nodeClicked(event, rowInfo),
              selected: this.state.bitArray.get(rowInfo.node.id),
              isShowSelectedIcon: true,
              isShowExpandIcon: false,
            })}
          />
        )}
        <StyledButtonGroup style={{ borderTopWidth: 1 }}>
          {multipleValues && ToolButton(IconSelectAll, this.handleSelectAll, true, "Select All", 1)}
          {multipleValues && ToolButton(IconSelectInv, this.handleInversion, true, "Select Alternative", 2)}
          {multipleValues && ToolButton(IconSelectNone, this.handleSelectNone, true, "Clear Selection", 3)}
          {ButtonSeparator()}
          {ToolButton(IconCancel, this.handleCancel, true, "Cancel", 4, "error")}
          {ToolButton(IconCheck, this.handleOk, true, "Ok", 5, "primary")}
        </StyledButtonGroup>
      </Paper>
    );
  }
}

export default withTheme(DimensionValuesTree);
