import cookie from "react-cookies";

export class BaseApiClass {
  constructor(props, tokenApi) {
    this.opt = { ...props };
    this.isTokenRefreshing = false;
    this.token = null;
    this.tokenApi = tokenApi;
  }

  async send(path, body = {}) {
    this.opt.dispatch?.({ type: "INCREASE_LOADINGS_COUNT" });
    try {
      const response = await fetch(path.startsWith("http") ? path : this.opt.server + (path && "/" + path), {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: body && JSON.stringify({ token: this.token, ...body }),
      });

      const contentType = response.headers.get("Content-Type");
      if (!response.ok && response.status >= 500 && contentType === "text/html") throw new Error("Сервер недоступен");

      const responseBody = await response.json();

      this.opt.dispatch?.({ type: "DECREASE_LOADINGS_COUNT" });

      if (!response.ok) {
        const result = { error: true, ok: false, message: responseBody?.message || "Ошибка запроса" };
        return this.handleTokenErrorResponse(result, () => this.send(path, body));
      }

      return this.handleTokenErrorResponse(responseBody, () => this.send(path, body));
    } catch (error) {
      this.opt.dispatch?.({ type: "DECREASE_LOADINGS_COUNT" });
      const result = { error: true, ok: false, message: error.message || "Ошибка запроса" };
      return this.handleTokenErrorResponse(result, () => this.send(path, body));
    }
  }

  async call(path, body, processResponce, isFile) {
    return new Promise(async (resolve) => {
      try {
        const res = await this.send(path, body, isFile);
        if (res?.error) {
          this.opt.dispatch?.({
            type: "SET_API_ERROR",
            value: { message: res[this.opt.errorText] || "error" },
          });
          resolve({ error: true });
        }
        resolve(processResponce?.(res) || res);
      } catch (e) {
        resolve({ error: true });
        console.log(e);
      }
    });
  }

  async handleTokenErrorResponse(response, repeatCall) {
    if (!response?.error || (!response?.message?.includes("401") && !response?.message?.includes("roles"))) return response;

    if (this.isTokenRefreshing) {
      return new Promise((resolve) => {
        const removeListening = () => {
          window.removeEventListener("SUCCESS_REFRESH_TOKEN", onSuccess);
          window.removeEventListener("FAILURE_REFRESH_TOKEN", onFailure);
        };

        const onSuccess = async () => {
          removeListening();
          resolve(await repeatCall());
        };
        const onFailure = () => {
          removeListening();
          resolve(null);
        };

        window.addEventListener("SUCCESS_REFRESH_TOKEN", onSuccess);
        window.addEventListener("FAILURE_REFRESH_TOKEN", onFailure);
      });
    }

    this.isTokenRefreshing = true;

    const login = cookie.load("login", { path: process.env.PUBLIC_URL + "/" });
    const password = cookie.load("password", { path: process.env.PUBLIC_URL + "/" });

    const loginResponse = await this.send("user/login", { login, password });
    this.isTokenRefreshing = false;

    if (!loginResponse || loginResponse?.error || !loginResponse?.sessionToken) {
      this.token = null;
      cookie.remove("login", { path: process.env.PUBLIC_URL + "/" });
      cookie.remove("password", { path: process.env.PUBLIC_URL + "/" });
      cookie.remove("sessionToken", { path: process.env.PUBLIC_URL + "/" });
      localStorage.removeItem("session_token");
      window.dispatchEvent(new Event("FAILURE_REFRESH_TOKEN"));
      return response;
    }

    this.token = loginResponse.sessionToken;
    localStorage.setItem("session_token", `"${this.token}"`);
    cookie.save("sessionToken", this.token, { path: process.env.PUBLIC_URL + "/" });
    window.dispatchEvent(new Event("SUCCESS_REFRESH_TOKEN"));
    return await repeatCall();
  }

  setToken(token) {
    this.token = token;
  }

  async login(login, password) {
    return await this.call(`${this.tokenApi}/login`, { login, password }, (res) => {
      this.token = res?.token;
    });
  }

  async refreshToken() {
    return await this.send(`${this.tokenApi}/check`);
  }

  async logout() {
    return await this.send(`${this.tokenApi}/logout`);
  }

  async getUserData() {
    return await this.send(`${this.tokenApi}/user`);
  }
}
