const PAGE_PADDING = 80;
const VERTICAL_HEIGHT = 1680;
const HORIZONTAL_HEIGHT = 1080;

export const INITIAL_HEIGHT = PAGE_PADDING;

function getMaxHeight(landscape) {
  return landscape ? HORIZONTAL_HEIGHT - PAGE_PADDING : VERTICAL_HEIGHT - PAGE_PADDING;
}

function findOverflowChild(element, initialHeight, landscape) {
  let height = initialHeight;

  for (let i = 0; i < element.children.length; i++) {
    const children = element.children.item(i);

    if (children.id === "page-break") {
      return { element: children, height, found: true };
    }

    if (height + children.offsetHeight <= getMaxHeight(landscape)) {
      height += children.offsetHeight;
      continue;
    }

    if (children.id === "space") {
      height = INITIAL_HEIGHT;
      continue;
    }

    if (children.id === "table") {
      const result = findOverflowChild(children, height, landscape);
      if (result.found) return result;
      height = result.height;
      continue;
    }

    return { element: element.children.item(i), height, found: true };
  }

  return { height, found: false };
}

function calculateDistanceToNextPage(height, landscape) {
  return PAGE_PADDING * 2 + getMaxHeight(landscape) - height + "px";
}

function createBorderElement(result, landscape) {
  const node = document.createElement("div");
  node.id = "space";
  node.style.height = calculateDistanceToNextPage(result.height, landscape);
  if (result.element.id === "row") node.style.borderBottom = "1px solid black";
  if (result.element.parentNode.children.item(0) === result.element) node.style.top = "-1px";
  node.style.left = "-1px";
  node.style.right = "-1px";
  node.style.position = "relative";
  node.style.background = "white";
  node.style.width = "calc(100% + 2px)";
  node.style.display = "flex";
  return node;
}

function createTableCellElement(result, landscape) {
  const node = document.createElement("td");
  node.style.height = calculateDistanceToNextPage(result.height, landscape);
  if (result.element.id === "row") node.style.borderBottom = "1px solid black";
  node.style.background = "white";
  node.style.left = "-1px";
  node.style.position = "relative";
  return node;
}

export function removePageOverflow(children, height, landscape) {
  const result = findOverflowChild(children, height, landscape);
  if (!result.found) return result.height;

  if (result.element.id === "divider" || result.element.id === "page-break") {
    result.element.id = "space";
    result.element.style.height = calculateDistanceToNextPage(result.height, landscape);
    return removePageOverflow(children, height, landscape);
  };

  if (result.element.tagName === "TR") {
    const trElement = document.createElement("tr");
    trElement.id = "space";
    
    for (let i = 0; i < result.element.children.length; i++) {
      const borderElement = createTableCellElement(result, landscape);
      trElement.appendChild(borderElement);
    }

    result.element.parentNode.insertBefore(trElement, result.element);
  } else {
    const borderElement = createBorderElement(result, landscape);
    result.element.parentNode.insertBefore(borderElement, result.element);
  }
  
  return removePageOverflow(children, height, landscape);
}

export function getDocumentTitle(registry) {
  switch (registry) {
    case "upload_registry": return "Пакет данных";
    case "indicator": return "Паспорт показателя официальной статистической информации";
    case "algorythm_registry": return "Паспорт методики расчета показателя официальной статистической информации";
    case "сalculation_registry": return "Расчет показателя официальной статистической информации";
    case "dataload_calendar": return "Расписание загрузки данных по показателю официальной статистической информации";
    case "rubric_registry": return "Рубрика показателей официальной статистической информации";
    case "algorithm_registry": return "Методика расчета показателя";
    case "terson": return "Паспорт территориального образования";
    default: return null;
  }
}