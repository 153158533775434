import React from "react";
import dayjs from "dayjs";
import { CircularProgress } from "@mui/material";

import {
  AnswerInterface,
  QuestionInterface,
  QuizInterface,
  SurveyFormInterface,
  SyntheticQuestionInterface,
} from "components/Survey/storages/QuizStorage/types";
import { api } from "components/Survey/utils/api";
import { parseFormAnswers } from "components/Survey/storages/QuizStorage/utils";
import { DEFAULT_ANSWERS } from "components/Survey/storages/QuizStorage";

import { CenteredText, Container, Divider, Table, TableContent, TableRow, TableTitle } from "../components";

import { INITIAL_HEIGHT, removePageOverflow } from "../utils";

interface RegistryFieldInterface {
  code: string;
  field_type: string;
  source: string;
  source_field: string | null;
  value: string;
}

interface RegistryGroupInterface {
  group_type: "header" | "table";
  value: string;
  field: RegistryFieldInterface[];
}

interface ReferenceInterface {
  code: string;
  value: string;
}

interface SurveyPDFInterface {
  token: string;
  code: string;
  onLoadSuccess?: () => void;
}

function SurveyPDF({ onLoadSuccess, token, code }: SurveyPDFInterface) {
  const pageContainer = React.useRef<HTMLDivElement | null>(null);

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [registryData, setRegistryData] = React.useState<Record<string, any> | null>(null);
  const [registryGroups, setRegistryGroups] = React.useState<RegistryGroupInterface[]>([]);
  const [references, setReferences] = React.useState<Record<string, ReferenceInterface[]>>({});

  const [answers, setAnswers] = React.useState<Record<string, AnswerInterface>>({});
  const [questions, setQuestions] = React.useState<SyntheticQuestionInterface[]>([]);

  const loadRegistryData = React.useCallback(async () => {
    const registryResponse = await api.post<any>("/registries/registry", { code: "answer_registry", token }, true);

    const referenceKeys = Object.keys(registryResponse.reference);
    const references = await Promise.all(
      referenceKeys.map((field) => api.post<any>("/registries/values", { code: "answer_registry", field, token }, true))
    );
    const referencesObject = Object.fromEntries(
      referenceKeys.map((key, index) => [key, Array.isArray(references[index]) ? references[index] : []])
    );

    const registryDataResponse = await api.post<any>(
      "/registries/list",
      {
        token,
        code: "answer_registry",
        filter: [[null, "code", "=", code, false, null, "AND"]],
        page_number: 0,
        page_size: 50,
        sort_order: [],
      },
      true
    );

    const foundTableWidget = registryResponse.widget.find((widget: any) => widget.widget_type === "print_view");
    if (!foundTableWidget) throw new Error();

    setReferences(referencesObject);
    setRegistryData(registryDataResponse[0]);
    setRegistryGroups(foundTableWidget.field_group);
  }, [token, code]);

  const initializePage = React.useCallback(async () => {
    setLoading(true);
    const formResponse = await api.post<SurveyFormInterface>("/view", { code, token });
    if ("error" in formResponse) throw new Error();

    await loadRegistryData();

    const queries = new URLSearchParams();
    queries.append("code", formResponse.survey);
    queries.append("lang", "ru");
    queries.append("token", token);

    const quizResponse = await api.get<QuizInterface>("/load?" + queries.toString());
    if ("error" in quizResponse) throw new Error();

    const questionNumbers: number[] = [];
    quizResponse.Questionary.forEach(
      (quest) => !questionNumbers.includes(+quest.question_num) && questionNumbers.push(+quest.question_num)
    );

    const sortedQuestionNumbers = questionNumbers.sort((a, b) => a - b);

    const answers: Record<string, AnswerInterface> = {};
    const questions = sortedQuestionNumbers.map((questNumber) => {
      const filteredQuestions = quizResponse.Questionary.filter((quest) => quest.question_num === questNumber);
      const formulaQuestions = filteredQuestions.filter((quest) => !!quest.config?.formula);
      const commonQuestions = filteredQuestions.filter((quest) => !quest.config?.formula);
      const isQuestionGroup = commonQuestions.length > 1 || typeof commonQuestions[0].question_group === "number";

      const firstQuestion = commonQuestions[0];
      const answer = quizResponse.References[firstQuestion.question];

      if (!answer) throw new Error();

      answer.Reference = answer.Reference.map((reference) => ({ ...reference, code: +reference.code }));
      if (firstQuestion.other_allowed)
        answer.Reference.push({
          code: answer.Reference.length + 1,
          value: firstQuestion.other_text || "",
          input_type: "Text",
          isOtherVariant: true,
        });

      commonQuestions.forEach((question) => {
        answers[question.code] = { ...DEFAULT_ANSWERS };
        parseFormAnswers({ answer, answers, formResponse, question });
      });

      const question: Record<string, any> = {
        answer,
        isQuestionGroup,
        code: firstQuestion.code,
      };

      if (isQuestionGroup) {
        question.caption = answer.caption;
        question.tooltip = null;
        question.description = null;
        question.questions = [];
        question.formulaQuestions = formulaQuestions;
        question.allQuestions = commonQuestions;
        question.availableQuestions = commonQuestions;
        question.filterQuestions = [];
        question.page = 1;
        question.maxPages = 1;
      } else {
        question.caption = firstQuestion.value;
        question.tooltip = firstQuestion.question_tooltip || null;
        question.description = firstQuestion.question_description || null;
        question.question = firstQuestion;
        if (firstQuestion.config?.datatype === "file") question.question.type = "File";
      }

      return question as SyntheticQuestionInterface;
    });

    setLoading(false);
    setAnswers(answers);
    setQuestions(questions);
    onLoadSuccess?.();
  }, [code, token, loadRegistryData, onLoadSuccess]);

  React.useLayoutEffect(() => {
    try {
      initializePage();
    } catch (error) {
      setError(true);
    }
  }, [initializePage]);

  React.useEffect(() => {
    if (!questions.length || !pageContainer.current) return;
    let height = INITIAL_HEIGHT;
    for (let i = 0; i < pageContainer.current.children.length; i++) {
      const children = pageContainer.current.children.item(i);
      height = removePageOverflow(children, height);
    }
    document.dispatchEvent(new Event("pdf-ready"));
  }, [questions]);

  const getFieldValue = React.useCallback((question: QuestionInterface, answer: AnswerInterface) => {
    if (question.type === "Date") {
      if (!answer.otherTypeAnswers) return "";
      return dayjs(answer.otherTypeAnswers).locale("ru").format("DD.MM.YYYY");
    }

    if (question.type) return answer.otherTypeAnswers;

    if (question.multiple_values) return answer.multipleAnswers.map(({ code, value }) => value || code).join(", ");

    return answer.answers?.value || answer.answers?.code;
  }, []);

  const getTableFieldValue = React.useCallback(
    (field: RegistryFieldInterface, data = registryData) => {
      if (!field || !data) return null;

      const source = field.source;
      const type = (field.field_type || "memo").toLowerCase();
      if (!type) return null;

      if (type === "reference" && references[source]) {
        const value = data[source];
        const foundReference = references[source].find(({ code }: ReferenceInterface) =>
          typeof code === "number" ? code === +value : code === value
        );
        return field.source_field
          ? foundReference?.[field.source_field as keyof typeof foundReference] || null
          : foundReference?.value || value;
      }

      if (type === "date") return data[source] ? dayjs(data[source]).locale("ru").format("DD.MM.YYYY") : null;
      if (type === "datetime") return data[source] ? dayjs(data[source]).locale("ru").format("DD.MM.YYYY HH:mm") : null;

      if (type === "label") return source;
      if (type === "checkbox") return data[source] ? "Да" : "Нет";

      if (type === "json_object_array") {
        let array = null;
        try {
          const parsedArray = JSON.parse(data[source]);
          if (Array.isArray(parsedArray)) array = parsedArray;
        } catch (error) {}

        if (!array) return null;
        return (
          <div style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
            {array.map(({ value }, index) => (
              <span>
                {value}
                {index !== array.length - 1 && ","}
              </span>
            ))}
          </div>
        );
      }

      return data[field.source];
    },
    [references, registryData]
  );

  const renderHeader = React.useCallback((group: RegistryGroupInterface, groupIndex: number) => {
    return (
      <div key={groupIndex}>
        <CenteredText>{group.field[0]?.source || "Опросный лист"}</CenteredText>
        <Divider medium id="divider" />
      </div>
    );
  }, []);

  const renderTable = React.useCallback(
    (group: RegistryGroupInterface, groupIndex: number) => {
      return (
        <div key={groupIndex}>
          <CenteredText>{group.value}</CenteredText>
          <Divider id="divider" small />
          <Table id="table">
            {group.field.map((field) => (
              <TableRow id="row">
                <TableTitle>{field.value}</TableTitle>
                <TableContent>{getTableFieldValue(field)}</TableContent>
              </TableRow>
            ))}
          </Table>
          <Divider id="divider" />
        </div>
      );
    },
    [getTableFieldValue]
  );

  const renderGroup = React.useCallback(
    (group: RegistryGroupInterface, groupIndex: number) => {
      if (group.group_type === "header") return renderHeader(group, groupIndex);
      return renderTable(group, groupIndex);
    },
    [renderHeader, renderTable]
  );

  if (error)
    return (
      <Container>
        <CenteredText>Ошибка в запросе</CenteredText>
      </Container>
    );

  if (loading)
    return (
      <Container>
        <CenteredText>
          <CircularProgress size={32} />
        </CenteredText>
      </Container>
    );

  if (!questions.length || !registryData) return null;

  return (
    <Container ref={pageContainer}>
      {registryGroups.map(renderGroup)}

      <div>
        <CenteredText>2. Данные анкеты</CenteredText>
        <Divider small />
      </div>
      {questions.map((syntheticQuestion, index) => {
        if (syntheticQuestion.isQuestionGroup) {
          const isLargeGroup = syntheticQuestion.allQuestions.length > 8;
          let haveAtLeastOneAnswer = false;
          return (
            <div key={syntheticQuestion.code}>
              <div dangerouslySetInnerHTML={{ __html: `${index + 1}. ${syntheticQuestion.caption}` }} />
              <Divider small />
              <Table id="table">
                {syntheticQuestion.allQuestions.map((question) => {
                  const value = getFieldValue(question, answers[question.code]);
                  if (!value && isLargeGroup) return null;
                  haveAtLeastOneAnswer = true;
                  return (
                    <TableRow key={question.code} id="row">
                      <TableTitle dangerouslySetInnerHTML={{ __html: question.value }} />
                      <TableContent>{value}</TableContent>
                    </TableRow>
                  );
                })}
                {isLargeGroup && !haveAtLeastOneAnswer && (
                  <TableRow id="row">
                    <TableContent>Нет ответов</TableContent>
                  </TableRow>
                )}
              </Table>
              <Divider />
            </div>
          );
        }

        return (
          <div key={syntheticQuestion.code}>
            <Table id="table">
              <TableRow key={syntheticQuestion.code} id="row">
                <TableTitle dangerouslySetInnerHTML={{ __html: `${index + 1}. ${syntheticQuestion.caption}` }} />
                <TableContent>{getFieldValue(syntheticQuestion.question, answers[syntheticQuestion.code])}</TableContent>
              </TableRow>
            </Table>
            <Divider />
          </div>
        );
      })}
    </Container>
  );
}

export default React.memo(SurveyPDF);
