import React from "react";
import styled from "styled-components";
import { OverridableComponent } from "@mui/types";
import { observer, useAsObservableSource } from "mobx-react-lite";
import {
  Checkbox,
  Dialog,
  Paper,
  PaperTypeMap,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { MAX_QUESTIONS_PER_PAGE, QuizStorage } from "components/Survey/storages/QuizStorage";

import { ToolButton } from "shared/ui/ToolBar";

import { ReactComponent as CheckAllIcon } from "@mdi/svg/svg/check-all.svg";

import Pagination from "../Pagination";

interface GroupFilterModalInterface {
  onClose: () => void;
}

function GroupFilterModal({ onClose }: GroupFilterModalInterface) {
  const quizStorage = useAsObservableSource(QuizStorage);
  const currentQuestion = quizStorage.currentQuestion;

  const [filter, setFilter] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [, setUpdate] = React.useState(true);

  const handleFilterChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilter(event.target.value);
    setPage(1);
  }, []);

  const questions = React.useMemo(() => {
    if (!currentQuestion.isQuestionGroup) return [];
    if (!filter) return currentQuestion.availableQuestions;
    return currentQuestion.availableQuestions.filter(({ value }) => value.toLowerCase().includes(filter.toLowerCase()));
  }, [currentQuestion, filter]);

  if (!currentQuestion.isQuestionGroup) return null;

  return (
    <Dialog open onClose={onClose}>
      <div style={{ display: "flex", width: 534, justifyContent: "center", flexDirection: "column", gap: 16, padding: 16 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <TextField
            style={{ flex: 1 }}
            value={filter}
            variant="outlined"
            size="small"
            placeholder="Фильтр"
            onChange={handleFilterChange}
          />
          {ToolButton(CheckAllIcon, QuizStorage.toggleAllFilters, true, "Выбрать все")}
        </div>
        <StyledTableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <span>Наименование</span>
                </TableCell>
                <CellContainer>Выбрано</CellContainer>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.slice((page - 1) * MAX_QUESTIONS_PER_PAGE, page * MAX_QUESTIONS_PER_PAGE).map((question) => {
                if (question.hidden) return null;
                const checked = !!currentQuestion.filterQuestions.find(({ code }) => code === question.code);
                return (
                  <TableRow key={question.code}>
                    <TableCell component="th" scope="row" dangerouslySetInnerHTML={{ __html: question.value }} />
                    <CellContainer withPadding>
                      <Checkbox
                        color="primary"
                        size="small"
                        checked={checked}
                        onChange={() => {
                          quizStorage.onToggleGroupQuestionFilter(question);
                          setUpdate((u) => !u);
                        }}
                      />
                    </CellContainer>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Pagination
          code={currentQuestion.code}
          page={page}
          maxPage={Math.ceil(questions.length / MAX_QUESTIONS_PER_PAGE)}
          onPageChange={setPage}
        />
      </div>
    </Dialog>
  );
}

const StyledTableContainer = styled(TableContainer)<{ component: OverridableComponent<PaperTypeMap<{}, "div">> }>`
  background: white;

  th {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;

const CellContainer = styled(TableCell)<{ withPadding?: boolean }>`
  text-align: center !important;
  padding: 2px 5px;
`;

export default observer(GroupFilterModal);
