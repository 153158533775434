import React from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";

import { ActionDialog } from "shared/ui";

import DocRefPage from "components/PDF/DocRefPage";

function PdfDocRefLoader({ onOk, registryCode, rowCode }) {
  const { enqueueSnackbar } = useSnackbar();
  const api = useSelector((state) => state.API);

  const [loading, setLoading] = React.useState(false);
  const [isPdfLoaded, setPdfLoaded] = React.useState(false);

  const handlePdfLoaded = React.useCallback(() => setPdfLoaded(true), []);
  const handleDownloadClick = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_API + "/pdf-web", {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          token: api.token,
          pages: [
            `${process.env.REACT_APP_PDF_URL}/documentationRef?registry=${registryCode}&code=${JSON.stringify(rowCode)}&token=${api.token}`,
          ],
        }),
      });

      if (!response.ok) {
        enqueueSnackbar("Ошибка", { variant: "error" });
        return;
      }

      const blob = await response.blob();
      const a = document.createElement("a");
      a.download = rowCode.join(",") + ".pdf";
      a.href = URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } finally {
      setLoading(false);
    }
  }, [api, enqueueSnackbar, rowCode, registryCode]);

  return (
    <ActionDialog maxWidth="1160px" onClose={onOk} open title="Документ">
      <Container>
        <PdfContainer>
          <DocRefPage onLoadSuccess={handlePdfLoaded} api={api} registryCode={registryCode} rowCode={rowCode} preview />
        </PdfContainer>
        <DownloadButtonContainer>
          <Button disabled={loading || !isPdfLoaded} variant="contained" onClick={handleDownloadClick}>
            Скачать
            {loading && <CircularProgress style={{ marginLeft: 8 }} size={16} />}
          </Button>
        </DownloadButtonContainer>
      </Container>
    </ActionDialog>
  );
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  gap: 16px;
`;

const PdfContainer = styled("div")`
  flex: 1;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 2px;
`;

const DownloadButtonContainer = styled("div")`
  display: flex;
  justify-content: right;
`;

export default React.memo(PdfDocRefLoader);
