import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Chip } from "@mui/material";

import { ReactComponent as ArrowLeft } from "@mdi/svg/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "@mdi/svg/svg/arrow-right.svg";

import { Icon } from "shared/ui/ToolBar";

const CustomChip = styled(Chip)`
  gap: 4px;
  svg {
    margin: 0 !important;
  }
`;

const FilterTagsContainer = styled("div")`
  display: flex;
  gap: 0.5em;
  align-items: center;
  max-width: 100%;

  .MuiChip-root {
    height: 26px;
    padding: 0 12px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .MuiChip-label {
    font-size: 13px;
    padding: 0;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin: 0 -6px 0 4px;
  }
`;

const FilterSelectedTagsContainer = styled("div")`
  display: flex;
  gap: 0.25em;
`;

const FilterSelectedTagsResizeContainer = styled("div")`
  display: flex;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const INITIAL_TAGS_STATE = {
  leftButtonIsHidden: true,
  leftButtonIsDisabled: true,
  rightButtonIsHidden: true,
  rightButtonIsDisabled: true,
};

function ScrollableContainer({ children, style }) {
  const [selectedTagsState, setSelectedTagsState] = useState(INITIAL_TAGS_STATE);
  const [selectedTagsReseizeRef, setSelectedTagsReseizeRef] = useState(null);
  const [selectedTagsRef, setSelectedTagsContainerRef] = useState(null);

  const handleScrollTagsLeft = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const value = selectedTagsReseizeRef.scrollLeft > step ? selectedTagsReseizeRef.scrollLeft - step : 0;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    const maxLeftScroll = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    const leftButtonIsDisabled = value === 0;
    const rightButtonIsDisabled = value === maxLeftScroll - 1;
    setSelectedTagsState((state) => ({
      ...state,
      leftButtonIsDisabled,
      rightButtonIsDisabled,
    }));
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  const handleScrollTagsRight = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const maxLeftScroll = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    const leftScrollSpace = maxLeftScroll - 1 - selectedTagsReseizeRef.scrollLeft;
    const value = leftScrollSpace > step ? selectedTagsReseizeRef.scrollLeft + step : maxLeftScroll;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    const leftButtonIsDisabled = value === 0;
    const rightButtonIsDisabled = value === maxLeftScroll;
    setSelectedTagsState((state) => ({
      ...state,
      leftButtonIsDisabled,
      rightButtonIsDisabled,
    }));
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  useEffect(() => {
    if (!selectedTagsRef) return;

    const resizeObserver = new ResizeObserver(() => {
      const haveHiddenContent = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
      setSelectedTagsState(
        haveHiddenContent
          ? {
              leftButtonIsHidden: false,
              leftButtonIsDisabled: true,
              rightButtonIsHidden: false,
              rightButtonIsDisabled: false,
            }
          : INITIAL_TAGS_STATE
      );
    });

    const unobserve = () => resizeObserver.unobserve(selectedTagsRef);
    resizeObserver.observe(selectedTagsRef);
    const unobserveResize = () => resizeObserver.unobserve(selectedTagsReseizeRef);
    resizeObserver.observe(selectedTagsReseizeRef);

    const haveHiddenContent = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
    setSelectedTagsState(
      haveHiddenContent
        ? {
            leftButtonIsHidden: false,
            leftButtonIsDisabled: true,
            rightButtonIsHidden: false,
            rightButtonIsDisabled: false,
          }
        : INITIAL_TAGS_STATE
    );

    return () => {
      unobserve();
      unobserveResize();
    };
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  return (
    <FilterTagsContainer style={style}>
      {!selectedTagsState.leftButtonIsHidden && (
        <CustomChip
          style={{ backgroundColor: "transparent" }}
          variant="outlined"
          disabled={selectedTagsState.leftButtonIsDisabled}
          onClick={handleScrollTagsLeft}
          label={Icon("action", ArrowLeft, 18, null, { margin: 0 })}
        />
      )}
      <FilterSelectedTagsResizeContainer ref={setSelectedTagsReseizeRef}>
        <FilterSelectedTagsContainer ref={setSelectedTagsContainerRef}>{children}</FilterSelectedTagsContainer>
      </FilterSelectedTagsResizeContainer>
      {!selectedTagsState.rightButtonIsHidden && (
        <CustomChip
          style={{ backgroundColor: "transparent" }}
          variant="outlined"
          disabled={selectedTagsState.rightButtonIsDisabled}
          onClick={handleScrollTagsRight}
          label={Icon("action", ArrowRight, 18, null, { margin: 0 })}
        />
      )}
    </FilterTagsContainer>
  );
}

export default React.memo(ScrollableContainer);
