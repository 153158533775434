const ALLOWED_REGISTRIES = [
  "indicator_np_fp",
  "algorithm_registry",
  "data_registry_np_fp",
  "calculation_registry",
  "document_registry",
  "export_registry",
  "dashboard_registry",
  "rubric_registry",
  "upload_registry"
]

function getTitleParams(data) {
  const titleParams = [];
  if (data?.ext_code) titleParams.push(data.ext_code);
  if (data?.value) titleParams.push(data.value);
  return titleParams.length > 0 ? `(${titleParams.join(" ")})` : "";
}

export function getEditModalTitle(registry, data) {
  if (!ALLOWED_REGISTRIES.includes(registry.code)) return "Изменить";
  return `Изменение записи ${getTitleParams(data)}`;
}

export function getCreateModalTitle(registry, data) {
  if (!ALLOWED_REGISTRIES.includes(registry.code)) return "Создать новую версию";
  return `Создание записи ${getTitleParams(data)}`;
}

export function getViewModalTitle(data) {
  return `Просмотр записи ${getTitleParams(data)}`;
}

export function getPdfModalTitle(data) {
  return `Печатная версия записи ${getTitleParams(data)}`;
}
