import React from "react";
import { useSelector } from "react-redux";

import { ContourBackdrop } from "shared/ui";

function ShowData({ code, registry, onCancel, onOk }) {
  const api = useSelector((state) => state.API);

  React.useEffect(() => {
    const fetchData = async () => {
      const [res, response] = await Promise.all([
        api.send("registries/data/description", { code, registry }),
        api.send("registries/data/list", { code, registry, page_size: 20 }),
      ]);

      if (!res?.field || res?.error) {
        onCancel();
        return;
      }

      const referenceFields = res.field.filter(({ type }) => type === "Reference");
      const references = await Promise.all(referenceFields.map(({ field }) => api.call("registries/values", { code: registry, field })));
      const referencesObject = Object.fromEntries(
        referenceFields.map(({ field }, index) => [field, Array.isArray(references[index]) ? references[index] : []])
      );

      const dataList = await response;
      if (!Array.isArray(dataList)) {
        onCancel();
        return;
      }

      onOk({ references: referencesObject, fields: res.field, listDataLength: dataList.length });
    };

    fetchData();
  }, [api, code, registry, onCancel, onOk]);

  return <ContourBackdrop />;
}

export default React.memo(ShowData);
