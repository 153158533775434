import React from "react";
import styled from "styled-components";

import Favorites from "./Favorites";
import Materials from "./Materials";
import OftenVisited from "./OftenVisited";

function Blocks() {
  return (
    <ContentWrapper>
      <OftenVisited />
      <Favorites />
      <Materials />
    </ContentWrapper>
  );
}

const ContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 40px 24px;
  gap: 32px;
`;

export default React.memo(Blocks);
