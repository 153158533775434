import React from "react";
import { debounce } from "lodash";
import styled from "styled-components";
import { Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";

import { fDate, fText } from "shared/ui/Editors";

import ScrollableContainer from "components/ScrollableContainer";

import Registry from "../Registry";

function Registries({ code }) {
  const api = useSelector((state) => state.API);

  const [registry, setRegistry] = React.useState(null);
  const [registryIndex, setRegistryIndex] = React.useState(0);

  const [filterValue, setFilterValue] = React.useState({});
  const [requestFilters, setRequestFilters] = React.useState([]);

  const initializeRegistry = React.useCallback(async () => {
    const registry = await api.call("registries/registries", { code });
    if (typeof registry !== "object" || registry === null || registry?.error) return;
    setRegistry(registry);
  }, [code, api]);

  React.useEffect(() => initializeRegistry(), [initializeRegistry]);

  const handleTabChange = React.useCallback((event, index) => setRegistryIndex(index), []);

  const updateRequestFilters = React.useMemo(
    () =>
      debounce((newFilterValue) => {
        setRequestFilters(
          Object.keys(newFilterValue).map((key) => {
            const foundControl = registry.control.find(({ code }) => code === key);
            let value = newFilterValue[key];

            if (value && foundControl.type === "date" && foundControl.condition === "<=") {
              const startDateCode = foundControl.code.replace("_to", "") + "_from";
              const foundStartDateControl = registry.control.find(({ code }) => code === startDateCode);

              const startDateValue = newFilterValue[foundStartDateControl?.code];
              if (startDateValue && startDateValue === value) value = value.replace("00:00:00.000Z", "23:59:59.000Z")
            }

            return [null, foundControl.field, foundControl.condition, value, false, null, "AND"];
          })
        );
      }, 300),
    [registry]
  );

  const handleDateFiltersChange = React.useCallback(
    (info, propertyName, event, value) => {
      const newFilterValue = { ...filterValue };
      value !== null && String(value) !== "Invalid Date" ? (newFilterValue[propertyName] = value) : delete newFilterValue[propertyName];
      updateRequestFilters(newFilterValue);
      setFilterValue(newFilterValue);
    },
    [filterValue, updateRequestFilters]
  );

  const handleTextFiltersChange = React.useCallback(
    (info, propertyName, event) => {
      const value = event.target.value;
      const newFilterValue = { ...filterValue };
      value ? (newFilterValue[propertyName] = value) : delete newFilterValue[propertyName];
      updateRequestFilters(newFilterValue);
      setFilterValue(newFilterValue);
    },
    [filterValue, updateRequestFilters]
  );

  const currentRegistryFilters = React.useMemo(
    () =>
      requestFilters.map((filter) => {
        const newFilter = [...filter];
        newFilter[1] = newFilter[1][registryIndex];
        return newFilter;
      }),
    [registryIndex, requestFilters]
  );

  const filterInputsProps = React.useMemo(
    () => ({
      disabled: false,
      value: filterValue,
      style: "compact",
    }),
    [filterValue]
  );

  const handleFiltersReset = React.useCallback(() => {
    setRequestFilters([]);
    setFilterValue({});
  }, []);

  if (!registry || !Array.isArray(registry.registry) || registry.registry.length === 0)
    return <CenteredContainer>Ошибка в данных</CenteredContainer>;

  return (
    <Container>
      <HeaderContainer>
        <ScrollableContainer>
          <FiltersContainer>
            {Array.isArray(registry.control) &&
              registry.control.map(({ condition, value, code, field, type }) => {
                if (type === "date")
                  return fDate(
                    { ...filterInputsProps, onChange_v2: handleDateFiltersChange },
                    value,
                    code,
                    null,
                    { autoSetValue: false },
                    code
                  );
                return fText({ ...filterInputsProps, onChange_v2: handleTextFiltersChange }, value, code, null, null, code);
              })}
          </FiltersContainer>
        </ScrollableContainer>
      </HeaderContainer>
      <Tabs value={registryIndex} onChange={handleTabChange}>
        {registry.registry.map(({ value, code }) => (
          <Tab key={code} label={value} />
        ))}
      </Tabs>
      {registry.registry.map(({ code }, index) => {
        if (index !== registryIndex) return null;
        return (
          <Registry
            mainTable
            filters={currentRegistryFilters}
            resetFilters={handleFiltersReset}
            parentCode={registry.code}
            code={code}
            key={code}
          />
        );
      })}
    </Container>
  );
}

const Container = styled("div")`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled("div")`
  padding: 1em 0.5em;
  padding-bottom: 0;
`;

const FiltersContainer = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;

  & > div {
    width: auto !important;
    min-width: 200px;
  }
`;

const CenteredContainer = styled("div")`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export default React.memo(Registries);
