import React from "react";
import styled from "styled-components";
import { Menu, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

import { Icon, ToolButtonComponent } from "./ToolBar";

function TableEditorToolbutton({ data: [icon, handler, disabledStatus, label, key, color, text, menu], toolbuttonDisableStatus, tableData, selectedRows, handleReload }) {
  const theme = useTheme();
  const [menuData, setMenuData] = React.useState([]);

  if (!menu) return null;
  
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <div {...bindTrigger(popupState)}>
            <ToolButtonComponent
              icon={icon} 
              handler={async () => {
                const data = await handler({
                  tableData,
                  rows: selectedRows,
                  reload: handleReload,
                });
                if (!Array.isArray(data)) return;
                setMenuData(data);
              }}
              enabled={async () => await toolbuttonDisableStatus(disabledStatus)} 
              color={color} 
              tooltip={text} 
            />
          </div>
          <Menu
            {...bindMenu(popupState)}
            autoFocus={false}
            style={theme.typography.text}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {menuData.map(({ handler, disabled, text, icon = null }, index) => (
              <StyledMenuItem
                key={index}
                value={index}
                disabled={disabled}
                onClick={() => { popupState.close(); handler(); }}
              >
                {icon && Icon("action", icon, 20)}
                <div>{text}</div>
              </StyledMenuItem>
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  )
}

const StyledMenuItem = styled(MenuItem)`
  background: transparent;
  transition: background 0.15s ease-in-out;
  font-size: 13px;
  color: #42474D;
  display: flex;
  gap: 8px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:hover {
    background: #EDF1F2;
  }
`;

export default React.memo(TableEditorToolbutton);
