// https://github.com/zesik/react-splitter-layout/blob/rtl/src/components/SplitterLayout.jsx

import React from "react";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";

import Pane from "./Pane";
import "./splitters.css";

function clearSelection() {
  if (document.body.createTextRange) {
    // https://github.com/zesik/react-splitter-layout/issues/16
    // https://stackoverflow.com/questions/22914075/#37580789
    const range = document.body.createTextRange();
    range.collapse();
    range.select();
  } else if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    document.selection.empty();
  }
}

function isRtl(element) {
  return (
    window.getComputedStyle &&
    window.getComputedStyle(element).direction === "rtl"
  );
}

const DEFAULT_SPLITTER_SIZE = 4;

class SplitterLayout extends React.Component {
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleSplitterMouseDown = this.handleSplitterMouseDown.bind(this);
    this.state = {
      secondaryPaneSize: 0,
      resizing: false,
    };
  }

  componentDidMount() {
    if (React.Children.count(this.props.children) > 1) {
      const n0 = this.props.children[0]?.props?.style?.display === "none";
      const n1 =
        !this.props.children[1] ||
        this.props.children[1]?.props?.style?.display === "none";
      if (n0 || n1) {
        window.addEventListener("resize", this.handleResize);
      }
    }

    document.addEventListener("mouseup", this.handleMouseUp);
    document.addEventListener("mousemove", this.handleMouseMove);
    document.addEventListener("touchend", this.handleMouseUp);
    document.addEventListener("touchmove", this.handleTouchMove);

    let secondaryPaneSize;
    if (typeof this.props.secondaryInitialSize !== "undefined") {
      secondaryPaneSize = this.props.secondaryInitialSize;
    } else {
      const containerRect = this.container.getBoundingClientRect();
      let splitterRect;
      if (this.splitter) {
        splitterRect = this.splitter.getBoundingClientRect();
      } else {
        // Simulate a splitter
        splitterRect = {
          width: DEFAULT_SPLITTER_SIZE,
          height: DEFAULT_SPLITTER_SIZE,
        };
      }
      secondaryPaneSize = this.getSecondaryPaneSize(
        containerRect,
        splitterRect,
        {
          left:
            containerRect.left + (containerRect.width - splitterRect.width) / 2,
          top:
            containerRect.top +
            (containerRect.height - splitterRect.height) / 2,
        },
        false
      );
    }
    this.setState({ secondaryPaneSize });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.secondaryPaneSize !== this.state.secondaryPaneSize &&
      this.props.onSecondaryPaneSizeChange
    ) {
      this.props.onSecondaryPaneSizeChange(this.state.secondaryPaneSize);
    }
    if (prevState.resizing !== this.state.resizing) {
      if (this.state.resizing) {
        if (this.props.onDragStart) {
          this.props.onDragStart();
        }
      } else if (this.props.onDragEnd) {
        this.props.onDragEnd();
      }
    }
  }

  componentWillUnmount() {
    if (React.Children.count(this.props.children) > 1) {
      const n0 = this.props.children[0]?.props?.style?.display === "none";
      const n1 =
        !this.props.children[1] ||
        this.props.children[1]?.props?.style?.display === "none";
      if (n0 || n1) {
        window.removeEventListener("resize", this.handleResize);
      }
    }
    document.removeEventListener("mouseup", this.handleMouseUp);
    document.removeEventListener("mousemove", this.handleMouseMove);
    document.removeEventListener("touchend", this.handleMouseUp);
    document.removeEventListener("touchmove", this.handleTouchMove);
  }

  getSecondaryPaneSize(
    containerRect,
    splitterRect,
    clientPosition,
    offsetMouse,
    rtl
  ) {
    let totalSize;
    let splitterSize;
    let offset;
    if (this.props.vertical) {
      totalSize = containerRect.height;
      splitterSize = splitterRect.height;
      offset = clientPosition.top - containerRect.top;
    } else {
      totalSize = containerRect.width;
      splitterSize = splitterRect.width;
      offset = clientPosition.left - containerRect.left;
    }
    if (offsetMouse) {
      offset -= splitterSize / 2;
    }
    if (offset < 0) {
      offset = 0;
    } else if (offset > totalSize - splitterSize) {
      offset = totalSize - splitterSize;
    }

    let secondaryPaneSize;
    if ((this.props.primaryIndex === 1) === (!rtl || this.props.vertical)) {
      secondaryPaneSize = offset;
    } else {
      secondaryPaneSize = totalSize - splitterSize - offset;
    }
    let primaryPaneSize = totalSize - splitterSize - secondaryPaneSize;
    if (this.props.percentage) {
      secondaryPaneSize = (secondaryPaneSize * 100) / totalSize;
      primaryPaneSize = (primaryPaneSize * 100) / totalSize;
      splitterSize = (splitterSize * 100) / totalSize;
      totalSize = 100;
    }

    if (primaryPaneSize < this.props.primaryMinSize) {
      secondaryPaneSize = Math.max(
        secondaryPaneSize - (this.props.primaryMinSize - primaryPaneSize),
        0
      );
    } else if (secondaryPaneSize < this.props.secondaryMinSize) {
      secondaryPaneSize = Math.min(
        totalSize - splitterSize - this.props.primaryMinSize,
        this.props.secondaryMinSize
      );
    }

    return secondaryPaneSize;
  }

  handleResize() {
    if (this.splitter && !this.props.percentage) {
      const containerRect = this.container.getBoundingClientRect();
      const splitterRect = this.splitter.getBoundingClientRect();
      const secondaryPaneSize = this.getSecondaryPaneSize(
        containerRect,
        splitterRect,
        {
          left: splitterRect.left,
          top: splitterRect.top,
        },
        false,
        isRtl(this.container)
      );
      this.setState({ secondaryPaneSize });
    }
  }

  handleMouseMove(e) {
    if (this.state.resizing) {
      const containerRect = this.container.getBoundingClientRect();
      const splitterRect = this.splitter.getBoundingClientRect();
      const secondaryPaneSize = this.getSecondaryPaneSize(
        containerRect,
        splitterRect,
        {
          left: e.clientX,
          top: e.clientY,
        },
        true,
        isRtl(this.container)
      );
      clearSelection();
      this.setState({ secondaryPaneSize });
    }
  }

  handleTouchMove(e) {
    this.handleMouseMove(e.changedTouches[0]);
  }

  handleSplitterMouseDown() {
    clearSelection();
    this.setState({ resizing: true });
  }

  handleMouseUp() {
    this.setState((prevState) =>
      prevState.resizing ? { resizing: false } : null
    );
  }

  render() {
    let containerClasses = "splitter-layout";
    if (this.props.customClassName) {
      containerClasses += ` ${this.props.customClassName}`;
    }
    if (this.props.vertical) {
      containerClasses += " splitter-layout-vertical";
    }
    if (this.state.resizing) {
      containerClasses += " layout-changing";
    }

    const children = React.Children.toArray(this.props.children).slice(0, 2);
    if (children.length === 0) {
      children.push(<div />);
    }

    const n0 = children[0]?.props?.style?.display === "none";
    const n1 = !children[1] || children[1]?.props?.style?.display === "none";

    const wrappedChildren = [];
    const primaryIndex =
      this.props.primaryIndex !== 0 && this.props.primaryIndex !== 1
        ? 0
        : this.props.primaryIndex;
    for (let i = 0; i < children.length; ++i) {
      let primary = true;
      let size = null;
      let percentage = this.props.percentage;
      if (children.length > 1 && i !== primaryIndex) {
        primary = false;
        size = this.state.secondaryPaneSize;
      }
      if ((i === 0 && n0) || (i === 1 && n1)) {
        // скрытый
        wrappedChildren.push(
          <Pane
            vertical={this.props.vertical}
            percentage={true}
            primary={false}
            size={0}
          >
            {children[i]}
          </Pane>
        );
        continue;
      }
      wrappedChildren.push(
        n1 || n0 ? (
          <Pane
            vertical={this.props.vertical}
            percentage={true}
            primary={false}
            size={100}
          >
            {children[i]}
          </Pane>
        ) : (
          <Pane
            vertical={this.props.vertical}
            percentage={this.props.percentage}
            primary={primary}
            size={size}
          >
            {children[i]}
          </Pane>
        )
      );
    }

    return (
      <div
        className={containerClasses}
        style={this.props.style}
        ref={(c) => {
          this.container = c;
        }}
      >
        {wrappedChildren[0]}
        {wrappedChildren.length > 1 && !(n0 || n1) && (
          <StyledHandleBar
            className={this.props.vertical ? "horizontal" : ""}
            role="separator"
            ref={(c) => {
              this.splitter = c;
            }}
            style={{ 
              borderRight: !this.props.leftShadow && "1px solid #DCE3E5", 
              borderLeft: this.props.leftShadow && "1px solid #DCE3E5", 
              marginLeft: this.props.leftShadow ? 0 : "-8px", 
              marginRight: this.props.leftShadow ? "-8px" : 0, 
              background: "transparent" 
            }}
            onMouseDown={this.handleSplitterMouseDown}
            onTouchStart={this.handleSplitterMouseDown}
          >
          </StyledHandleBar>
        )}
        {wrappedChildren.length > 1 && wrappedChildren[1]}
      </div>
    );
  }
}

SplitterLayout.defaultProps = {
  customClassName: "",
  vertical: false,
  percentage: false,
  primaryIndex: 0,
  primaryMinSize: 0,
  secondaryInitialSize: undefined,
  secondaryMinSize: 0,
  onDragStart: null,
  onDragEnd: null,
  onSecondaryPaneSizeChange: null,
  children: [],
};

const StyledHandleBar = withTheme(styled("div")`
  ${({ theme }) => `
  width: 8px;
  min-width: 8px;
  cursor: col-resize;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 20;
  background-color: ${theme.palette.background.paper};

&.horizontal {
    width: 100%;
    height: 8px;
    min-height: 8px;
    cursor: row-resize;
}

&.horizontal .handle-bar_drag {
      width: 20px;
      height: 4px;
      border-top:    1px solid ${theme.palette.divider};
      border-bottom: 1px solid ${theme.palette.divider};
      border-right: 0;
      border-left:  0;
}

& .handle-bar_drag {
    width: 4px;
    height: 20px;
    border-left:  1px solid ${theme.palette.divider};
    border-right: 1px solid ${theme.palette.divider};
}

&:active {
    background-color: ${theme.palette.action.focus};
}

&.handle-bar_clone {
    background-color: ${theme.palette.action.focus};
}

&.handle-bar_clone {
    position: absolute;
    opacity: .9;
    z-index: 12000;
}
`}
`);

const Splitter = withTheme(SplitterLayout);
export { Splitter };
