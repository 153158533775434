import React from "react";
import { Button } from "@mui/material";
import styled from "styled-components";

import { ReactComponent as CloseSvg } from "./icons/cancel_.svg";

import StageBlock from "./StageBlock";
import StatusDate from "./Descriptions/StatusDate";
import NameStatus from "./Descriptions/NameStatus";

function Stages() {
  return (
    <MainContainer>
      <StagesBlock>
        <HeaderStages>
          <HeaderTitle>
            <HeaderName>Этапы</HeaderName>
            <HeaderDescription>Список этапов согласования</HeaderDescription>
          </HeaderTitle>
          <ClickButton>
            <CloseSvg />
          </ClickButton>
        </HeaderStages>
        <MainContentBlock>
          <StagesContainer>
            <StageBlock state="ready" title="Ведомство - автор" subtitle="Подписание документа" expandable={false} />
            <StageBlock state="ready" title="Ведомство координатор" subtitle="Подписание резолюции" expandable>
              <StatusDate />
            </StageBlock>
            <StageBlock state="pending" title="Параллельное согласование ведомств" subtitle="Подписание резолюции" expandable>
              <NameStatus />
            </StageBlock>
            <StageBlock state="feature" title="Росстат" subtitle="Подписание резолюции" expandable={false} />
          </StagesContainer>
        </MainContentBlock>
      </StagesBlock>
    </MainContainer>
  );
}

const MainContainer = styled("div")`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StagesBlock = styled("div")`
  display: flex;
  flex-direction: column;
  max-width: 558px;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const HeaderStages = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid #dce3e5;
  padding: 16px;
  @media (max-width: 768px) {
    align-items: center;
  }
`;

const HeaderTitle = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  gap: 4px;
`;

const HeaderName = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

const HeaderDescription = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7b868c;
`;

const ClickButton = styled(Button)`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  border: none;
`;

const MainContentBlock = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 16px;
  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const StagesContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default React.memo(Stages);
