/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import UUID from "uuid-js";

import { withTheme } from "@mui/styles";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

import { DimButton, FactButton } from "./DimensionCardHyper";
import FilterList from "./DimensionCardHyper/FilterList";

import { setHyperData } from "./data";

import { ReactComponent as PlusCircleIcon } from "@mdi/svg/svg/chevron-right.svg";
import { ReactComponent as LeftIcon } from "@mdi/svg/svg/chevron-left.svg";
import { ReactComponent as FAxisIcon } from "@mdi/svg/svg/focus-field.svg";

import { Icon, ToolButtonIcon } from "shared/ui/ToolBar";

const TableView = (props) => {
  const [uniqueId] = useState(UUID.create(4).hex);
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [hoverId, setHoverId] = useState({ id: null });
  const [isDragState, setDragState] = useState(false);

  const skip = useRef(false);
  const [monitoringIds, setMonitoringIds] = useState([]);

  const isGrid = props.item?.current?.type === "Component";
  const isChart = props.item?.current?.type === "Chart";

  useEffect(() => {
    // отслеживает
    //   SliceDims
    //   ViewDims
    //   ViewFacts
    //   View
    const properties = props.item?.current?.properties;
    if (!properties) return;
    const monitoringIds = [
      properties.dims,
      properties.facts,
      properties.sliceDims,
      props.item?.current._id,
    ];

    Promise.all([
      props.api.get(properties.dims),
      props.api.get(properties.facts),
      props.api.get(properties.sliceDims),
    ])
      .then((collections) => {
        collections.forEach((collection) => {
          collection &&
            Array.isArray(collection) &&
            collection.forEach((item) => monitoringIds.push(item.current._id));
        });
        console.log(monitoringIds);
      })
      .finally(() => setMonitoringIds(monitoringIds));
  }, [props.id]);

  useEffect(() => {
    if (monitoringIds.length === 0) return;
    getData();
  }, [monitoringIds]);

  useEffect(() => {
    const update = monitoringIds.indexOf(props.itemChanged?.id) !== -1;
    if (update) getData();
  }, [props.itemChanged]);

  const handleHover = (id, pos) => {
    console.log("handleHover: ", id);
    skip.current = true;
    setHoverId({ id, pos });
  };

  const handleDrop = (dim, dimTo, isBefore) => {
    setHoverId({ id: null });
    setDragState(false);

    if (dim && dimTo) {
      console.log("handleDrop: ", dim, dimTo, isBefore);
      props.api
        .moveDim(
          dim._id,
          dimTo.properties.pos === undefined
            ? 0
            : dimTo.properties.pos + (isBefore ? 0 : 1),
          dimTo.properties?.axis
        )
        .then((res) => {
          console.log("MOVE DIM: ", res);
          getData();
        });
    } else {
      console.log("STOP DRAG");
    }
  };

  const handleStartDrag = (id) => {
    console.log("START DRAG");
    // delay for display placeholders
    requestAnimationFrame(() => setDragState(true));
  };

  const getData = () => {
    if (!props.item?.current?._id) {
      setHyperData(null, setData, isChart, props.item?.current);
      return;
    }

    setLoading(true);
    props.api
      .hyperData(props.item?.current?._id)
      .then((res) => {
        setHyperData(res, setData, isChart, props.item?.current);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const dimProps = {
    api: props.api,
    onApplyFilter: getData,
    isDragState: isDragState,
    hoverId,
    onHover: handleHover,
    onDrop: handleDrop,
    onStartDrag: handleStartDrag,
  };

  const dimBtnScroll = () => (
    <span style={{ marginLeft: "auto" }}>
      {ToolButtonIcon(
        Icon("action", LeftIcon, "1.5em", null, { fontSize: "1em" }),
        null,
        true,
        null,
        "btnLeft"
      )}
      {ToolButtonIcon(
        Icon("action", PlusCircleIcon, "1.5em", null, { fontSize: "1em" }),
        null,
        true,
        null,
        "btnRight"
      )}
    </span>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flexGrow: 1,
      }}
    >
      {isLoading && (
        <div
          style={{
            zIndex: 100,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            position: "absolute",
            display: "flex",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" size={"32px"} />
        </div>
      )}
      <div
        key={"filter_axis"}
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {data && isGrid && (
          <FactButton
            api={props.api}
            id={`${uniqueId}ff`}
            factsItem={data._factsItem}
            facts={data._facts}
            onApplyFilter={getData}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            marginRight: "1em",
          }}
        >
          {
            // Filter axis with optional Placeholder
            data &&
              isGrid &&
              data.dimensions?.f?.length > 0 &&
              data.dimensions.f.map((dim, ndx) => {
                return (
                  <DimButton
                    dim={dim}
                    id={`1f${dim._id}`}
                    key={ndx}
                    {...dimProps}
                  />
                );
              })
          }
          {
            // Placeholder for Filter axis
            isGrid && isDragState && data.dimensions?.f?.length === 0 && (
              <DimButton
                icon={FAxisIcon}
                dim={{
                  _id: null,
                  ndx: 0,
                  properties: { title: "Filter Axis", axis: "f" },
                }}
                id={`1f${0}`}
                key={"fAxisPlaceholder"}
                {...dimProps}
              />
            )
          }
        </div>
        {isGrid && data && data.dimensions?.f?.length > 0 && dimBtnScroll()}
      </div>
      {isGrid && <Divider style={{ marginBottom: "0.25em" }} />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          height: "0px",
          width: "100%",
        }}
      >
        <div
          style={{ overflow: "auto", display: "flex", flexDirection: "column" }}
        >
          {false && data && (
            <FilterList
              factsItem={data._factsItem}
              facts={data._facts}
              api={props.api}
              onApplyFilter={getData}
              key={-1}
            />
          )}
          {data &&
            isGrid &&
            data.dimensions?.v?.length > 0 &&
            data.dimensions.v.map((dim, ndx) => {
              return (
                <FilterList
                  dim={dim}
                  api={props.api}
                  onApplyFilter={getData}
                  key={dim._id}
                />
              );
            })}
        </div>
        {data && isGrid && data.dimensions?.v?.length > 0 && (
          <Divider orientation={"vertical"} style={{ margin: "0 0.5em" }} />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "1px" /* Safari */,
          }}
        >
        </div>

        {false && data && data.dimensions?.h?.length > 0 && (
          <Divider orientation={"vertical"} style={{ margin: "0 0.5em" }} />
        )}
        <div
          style={{ overflow: "auto", display: "flex", flexDirection: "column" }}
        >
          {false &&
            data.dimensions?.h?.length > 0 &&
            data.dimensions.h.map((dim, ndx) => {
              return (
                <FilterList
                  dim={dim}
                  api={props.api}
                  onApplyFilter={getData}
                  key={dim._id}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    api: state.API, //projectAPI,
    itemChanged: state.itemChanged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrent: (node) => dispatch({ type: "SET_CURRENT", value: node }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(TableView));
