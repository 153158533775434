import React from "react";
import styled from "styled-components";

import { BlockContainer, HomeButton, TitleContainer } from "../Components";

import { ReactComponent as TitleIcon } from "./materials.svg";

function MaterialsView() {
  return (
    <BlockContainer>
      <TitleContainer>
        <TitleLeftSideWrapper>
          <TitleIcon />
          <TitleTextContainer>
            <TitleText>Методические материалы</TitleText>
            <TitleHint>Раздел методические материалы содержит документы и разъяснения, для облегчения работы в системе</TitleHint>
          </TitleTextContainer>
        </TitleLeftSideWrapper>
        <HomeButton>Перейти в раздел</HomeButton>
      </TitleContainer>
    </BlockContainer>
  );
}

const TitleLeftSideWrapper = styled("div")`
  display: flex;
  gap: 12px;
  flex: 1;
`;

const TitleTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleText = styled("div")`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #2c3034;
`;

const TitleHint = styled("div")`
  font-size: 11px;
  line-height: 16px;
  color: #7b868c;
`;

export default React.memo(MaterialsView);
