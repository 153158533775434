import React from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { observer } from "mobx-react-lite";
import { Button, CircularProgress } from "@mui/material";

import { GlobalStorage } from "components/Survey/storages/GlobalStorage";

const UPLOAD_API = process.env.REACT_APP_SURVEY_UPLOAD_API || "https://ru-teacher.contourbi.ru/api/news/upload";

interface FileUploaderInterface {
  value: null | string;
  onChange: (value: null | string) => void;
}

const filenamesStorage = new Map();

function FileUploader({ value, onChange }: FileUploaderInterface) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const openFileInput = React.useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.value = "";
    inputRef.current.click();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    if (!file) return;
    onChange(null);

    const formData = new FormData();
    formData.append("data", file);
    if (!GlobalStorage.user) return;
    formData.append("token", GlobalStorage.user.token);

    setLoading(true);
    fetch(UPLOAD_API, { method: "POST", body: formData })
      .then((response) => response.json())
      .then((json) => {
        if (!json || typeof json !== "object" || json.error || !json.ok) throw new Error(json?.message);
        filenamesStorage.set(json.url, file.name);
        onChange(json.url);
      })
      .catch(error => enqueueSnackbar(error?.message || "Ошибка загрузки", { variant: "error" }))
      .finally(() => setLoading(false));
  };

  const handleFileRemove = () => {
    if (!inputRef.current) return;
    inputRef.current.value = "";
    onChange(null);
  };

  const fileSrc = value;
  const isFileExist = typeof fileSrc === "string";

  return (
    <ComponentContainer>
      <ButtonsContainer>
        <Button onClick={handleFileRemove} disabled={!isFileExist} color="primary" size="small" variant="contained">
          <span>Удалить</span>
        </Button>
        <Button onClick={openFileInput} disabled={loading} color="primary" size="small" variant="contained">
          <span>Загрузить</span>
        </Button>
      </ButtonsContainer>
      <Container>
        {isFileExist ? (
          filenamesStorage.get(fileSrc) || fileSrc
        ) : (
          <>
            <span>Файл не загружен</span>
            {loading && <CircularProgress style={{ marginLeft: 8 }} color="primary" size="32px" />}
          </>
        )}
      </Container>
      <input ref={(ref) => (inputRef.current = ref)} style={{ display: "none" }} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" onChange={handleInputChange} />
    </ComponentContainer>
  );
}

const ComponentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Container = styled("div")`
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  gap: 8px
  margin: 0 10px 10px 0;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  gap: 10px;
`;

export default observer(FileUploader);
