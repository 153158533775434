import React from "react";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

import { fSelect } from "shared/ui/Editors";
import { ActionDialog, ContourBackdrop } from "shared/ui";

const YEARS = new Array(110).fill(1).map((_, index) => ({ name: index + 1991, id: index + 1991 }));
const BASE_FIELD_PROPS = {
  fullWidth: true,
  style: "compact",
  size: "small",
};

function Calculation({ onOk, title, indicatorCode = null, calculationCode }) {
  const { enqueueSnackbar } = useSnackbar();
  const api = useSelector(state => state.API);

  const [value, setValue] = React.useState({ year: new Date().getFullYear(), indicator: null, periodicity: null, period: null });
  const [loading, setLoading] = React.useState(true);
  const [indicators, setIndicators] = React.useState([]);

  const periodicities = React.useMemo(() => {
    if (!value.indicator) return [];
    return value.indicator.periodicity;
  }, [value]);

  const periods = React.useMemo(() => {
    if (!value.periodicity) return [];
    return value.periodicity.period.map(value => ({ name: value, id: value }));
  }, [value]);
  
  const estimations = React.useMemo(() => {
    const estimation = value.periodicity?.estimation;
    if (!Array.isArray(estimation)) return [];
    return estimation.map(value => ({ name: value, id: value }));
  }, [value]);

  React.useLayoutEffect(() => {
    api.send("calculation/form")
      .then(res => {
        if (!res || !Array.isArray(res)) {
          onOk();
          return;
        }

        if (calculationCode || indicatorCode) {
          const foundIndicator = res.find(({ code }) => code === calculationCode || code === indicatorCode);
          if (foundIndicator) setValue(value => ({ ...value, indicator: foundIndicator }))
        }

        setIndicators(res);
        setLoading(false);
      });
  }, [api, onOk, calculationCode, indicatorCode]);

  const handleValueChange = React.useCallback((key, __, fieldValue) => {
    setValue(value => ({ ...value, [key]: fieldValue }));
  }, []);
  
  const handleIndicatorChange = React.useCallback((_, __, indicator) => {
    setValue(value => ({ ...value, period: null, estimation: null, periodicity: null, indicator }));
  }, []);

  const handlePeriodicityChange = React.useCallback((_, __, periodicity) => {
    setValue(value => ({ ...value, period: null, estimation: null, periodicity }));
  }, []);

  const handleSendClick = React.useCallback(async () => {
    const payload = { indicator: value.indicator.code, year: value.year, periodicity: value.periodicity.code };

    const periodIndex = periods.findIndex(period => value.period === period.id);
    if (periodIndex !== -1) payload.period = periodIndex;
    
    const estimationIndex = estimations.findIndex(estimation => value.estimation === estimation.id);
    if (estimationIndex !== -1) payload.estimation = estimationIndex;

    setLoading(true);
    api.send("calculation/run", payload)
      .then((result) => {
        if (result?.error) {
          enqueueSnackbar(result?.message || "Ошибка", { variant: "error" })
          return;
        }
        enqueueSnackbar("Расчет успешно запущен", { variant: "success" })
      })
      .finally(onOk);
  }, [onOk, enqueueSnackbar, estimations, api, periods, value]);

  const isSendButtonDisabled = React.useMemo(() => {
    if (!value.indicator) return true;
    if (!value.year) return true;
    if (!value.periodicity) return true;

    const period = value.periodicity.period;
    if (Array.isArray(period) && period.length !== 0 && !value.period) return true;

    const estimation = value.periodicity.estimation;
    if (Array.isArray(estimation) && estimation.length !== 0  && !value.estimation) return true;

    return false;
  }, [value]);

  if (loading) return <ContourBackdrop />;

  const isPeriodicityDisabled = !value.indicator || periodicities.length === 0;
  const isPeriodDisabled = !value.periodicity || periods.length === 0;
  const isEstimationDisabled = !value.periodicity || estimations.length === 0;

  return (
    <ActionDialog onClose={onOk} open title={title}>
      {fSelect({ ...BASE_FIELD_PROPS, value, disabled: !!indicatorCode, onChange: handleIndicatorChange }, "Показатель", "indicator", null, { select: indicators, optionNameField: "value", optionIdField: "code" } )}
      {fSelect({ ...BASE_FIELD_PROPS, value, onChange: handlePeriodicityChange, disabled: isPeriodicityDisabled }, "Периодичность", "periodicity", null, { select: periodicities, optionNameField: "value", optionIdField: "code" } )}
      {fSelect({ ...BASE_FIELD_PROPS, value, onChange: handleValueChange, disabled: isPeriodDisabled }, "Период", "period", null, { select: periods, onlyIdInValue: true } )}
      {!isEstimationDisabled && fSelect({ ...BASE_FIELD_PROPS, value, onChange: handleValueChange }, "Оценка", "estimation", null, { select: estimations, onlyIdInValue: true } )}
      {fSelect({ ...BASE_FIELD_PROPS, value, onChange: handleValueChange }, "Год", "year", null, { select: YEARS, onlyIdInValue: true } )}
      <div style={{ display: "flex", justifyContent: "right", marginTop: 5 }}>
        <Button disabled={isSendButtonDisabled} onClick={handleSendClick} variant="contained">Рассчитать</Button>
      </div>
    </ActionDialog>
  );
}

export default React.memo(Calculation);
