import React from "react";
import styled from "styled-components";
import { ReactComponent as FoxStatusDot } from "../icons/foxStatusDot.svg";

const NAMES = ["Федерельное казначейство", "Министерство финансов Российской федерации", "Министерство внутренних дел"];
const STATUSES = ["Утверждено", "На согласовании", "Черновик"];

function NameStatus() {
  function getRandom(strings) {
    const randomIndex = Math.floor(Math.random() * strings.length);
    return strings[randomIndex];
  }

  const fakeData = [
    {
      name: getRandom(NAMES),
      status: getRandom(STATUSES),
    },
    {
      name: getRandom(NAMES),
      status: getRandom(STATUSES),
    },
    {
      name: getRandom(NAMES),
      status: getRandom(STATUSES),
    },
  ];

  return (
    <DescriptionContainer>
      {fakeData.map((description, index) => (
        <DescriptionBlock key={index}>
          <Description>
            <Notation>Наименование</Notation>
            <div>{description.name}</div>
          </Description>
          <Description>
            <Notation>Статус</Notation>
            <div>
              <FoxStatusDot />
              {description.status}
            </div>
          </Description>
        </DescriptionBlock>
      ))}
    </DescriptionContainer>
  );
}

const DescriptionContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-left: 48px;
  margin-bottom: 16px;
  gap: 8px;
`;

const DescriptionBlock = styled("div")`
  display: flex;
  flex-direction: column;
  height: 112px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 8px;
  background: #f7f9fa;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 8px 10px 8px 10px;
  }
`;

const Description = styled("div")`
  display: flex;
  flex-direction: column;
  height: 40px;
  padding: 0px 8px 0px 0px;
  gap: 4px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 0px 4px 0px 0px;
  }
`;

const Notation = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7b868c;

  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 14px;
  }
`;

export default React.memo(NameStatus);