import React, { useState, useEffect, useContext, Component } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";

import { ReactComponent as DeleteIcon } from "@mdi/svg/svg/delete-outline.svg";
import { ReactComponent as WarningIcon } from "@mdi/svg/svg/information-outline.svg";
import { ReactComponent as AlertIcon } from "@mdi/svg/svg/alert-circle-outline.svg";

import { ActionDialog } from "./";
import { Icon } from "shared/ui/ToolBar";
import { useTheme } from "@mui/material/styles";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => ++value);
}

const ConfirmationDialog = ({ options, onConfirm, onDismiss }) => {
  const forceUpdate = useForceUpdate();
  const theme = useTheme();

  useEffect(() => {
    options.update && (options.update.current = forceUpdate);
    return () => options.update && (options.update.current = null);
  }, []);

  switch (options.type) {
    case "Delete":
      options.content = () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {Icon("action", DeleteIcon, "2em", theme.palette.error.main)}
          <span style={{ marginLeft: "1em" }}>{options.text}</span>
        </div>
      );
      options.buttons = [<span>Отменить</span>, <span>Удалить</span>];
      options.background = theme.palette.error.main;
      break;
    case "Warning":
      options.content = () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {Icon("action", WarningIcon, "2em", options.redIcon ? theme.palette.error.main : theme.palette.warning.main)}
          <span style={{ marginLeft: "1em" }}>{options.text}</span>
        </div>
      );
      options.buttons = [<span>Отменить</span>, <span>Принять</span>];
      options.background = theme.palette.warning.main;
      break;
    case "Error":
      options.content = () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {Icon("action", AlertIcon, "2em", theme.palette.error.main)}
          <span style={{ marginLeft: "1em" }}>{options.text}</span>
        </div>
      );
      options.buttons = [null, <span>Принять</span>];
      options.background = theme.palette.error.main;
      break;
    default:
      break;
  }

  return (
    <div>
      {options.type === "Dialog" ? (
        options.content(onConfirm, onDismiss)
      ) : (
        <ActionDialog
          heightAuto={options.heightAuto !== false}
          open={true}
          onOk={options.allowOk?.current === false ? null : onConfirm}
          onClose={onDismiss}
          onSelect={options.onSelect}
          onResult={options.onResult}
          paperStyle={options.paperStyle}
          background={options.background}
          withAction={options.buttons || []}
          maxWidth={options.width}
          title={options.title}
          titleIcon={options.titleIcon}
        >
          {options.content && options.content()}
        </ActionDialog>
      )}
    </div>
  );
};

const ConfirmationDialogContext = React.createContext();

class ConfirmationDialogProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configs: [],
      contextValue: {
        getConfirmation: this.getConfirmation.bind(this),
      },
    };
  }

  getConfirmation(options) {
    return new Promise((res) => {
      const d = [...this.state.configs];
      d.push({ actionCallback: res, options });
      this.setState({ configs: d });
    });
  }

  resetDialog(config) {
    const d = [...this.state.configs];
    d.splice(d.indexOf(config), 1);
    this.setState({ configs: d });
  }

  onConfirm(config, select, result) {
    this.resetDialog(config);
    config.options.type === "Dialog"
      ? config.actionCallback(select)
      : config.actionCallback(config.options?.onSelect ? select : config.options?.onResult ? result : true);
  }

  onDismiss(config) {
    this.resetDialog(config);
    config.options.type === "Dialog"
      ? config.actionCallback(null)
      : config.actionCallback(config.options?.onSelect || config.options?.onResult ? null : false);
  }

  render() {
    return (
      <ConfirmationDialogContext.Provider value={this.state.contextValue}>
        {this.state.configs.map((i, index) => (
          <ConfirmationDialog
            key={index}
            options={i.options || {}}
            onConfirm={this.onConfirm.bind(this, i)}
            onDismiss={this.onDismiss.bind(this, i)}
          />
        ))}
        {this.props.children}
      </ConfirmationDialogContext.Provider>
    );
  }
}

const useConfirmationDialog = () => useContext(ConfirmationDialogContext);

const withConfirmationDialog = (Component) => {
  const WrappedComponent = React.forwardRef((props, ref) => (
    <ConfirmationDialogContext.Consumer>
      {(context) => <Component {...props} ref={ref} getConfirmation={context.getConfirmation} />}
    </ConfirmationDialogContext.Consumer>
  ));

  hoistNonReactStatics(WrappedComponent, Component);

  return WrappedComponent;
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog, withConfirmationDialog };
