import React from "react";
import { INITIAL_HEIGHT, removePageOverflow } from "../utils";

export function useRemovePdfOverflow(pageContainer) {
  return React.useCallback(() => {
    let height = INITIAL_HEIGHT;
    for (let i = 0; i < pageContainer.current.children.length; i++) {
      const children = pageContainer.current.children.item(i);
      height = removePageOverflow(children, height);
    }
    document.dispatchEvent(new Event("pdf-ready"));
  }, [pageContainer]);
}
