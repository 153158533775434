import React from "react";

import { ActionDialog } from "shared/ui";

import Registry from "..";

function RegistryDialog({ onClose, filter, title, code }) {
  return (
    <ActionDialog open minHeight="50vh" title={title} onClose={onClose}>
      <Registry code={code} filters={filter} withoutParentCodeInNestedGrid />
    </ActionDialog>
  );
}

export default React.memo(RegistryDialog);
