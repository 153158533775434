/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { AutoSizer } from "react-virtualized";

import { withTheme } from "@mui/styles";
import { withStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";

import { connect } from "react-redux";

import Divider from "@mui/material/Divider";

import { ToolButton, StyledButtonGroup } from "shared/ui/ToolBar";
import { PopoverCustom } from "./DimensionCardHyper/index";

import TableView from "./TableView";

import { ReactComponent as IconAlignRight } from "@mdi/svg/svg/format-align-right.svg";
import { ReactComponent as IconAlignCenter } from "@mdi/svg/svg/format-align-center.svg";
import { ReactComponent as IconAlignLeft } from "@mdi/svg/svg/format-align-left.svg";
import { ReactComponent as IconAlignJustify } from "@mdi/svg/svg/format-align-justify.svg";
import { ReactComponent as IconZoom } from "@mdi/svg/svg/magnify-scan.svg";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = withStyles({
  root: {
    color: "#3880ff",
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    height: "1em",
    width: "1em",
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    marginTop: "-0.5em",
    marginLeft: "-0.5em",
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50%)",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 2,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

const ZoomSlider = (props) => {
  return (
    <div style={{ margin: "2em 2em 0 2em" }}>
      <IOSSlider
        ref={props.popoverRef}
        name={"zoom"}
        style={{ width: "200px" }}
        defaultValue={props.zoom.current}
        getAriaValueText={(value) => `${value}%`}
        valueLabelDisplay="on"
        valueLabelFormat={(value) => ` ${value}% `}
        step={10}
        marks
        min={10}
        max={200}
        onChange={(event, value) => props.onChange.current(value)}
      />
    </div>
  );
};

const ZoomPanel = (props) => {
  const [zoom, setZoom] = useState(100);
  const scale = zoom / 100;
  props.zoom.current = zoom;
  props.setZoom.current = setZoom;
  return (
    <div
      style={{
        display: "flex",
        padding: "1em",
        width: "100%",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <AutoSizer
        style={{
          display: "flex",
          flexGrow: 1,
          width: "100%",
          position: "relative",
        }}
      >
        {({ width, height }) => {
          // Autosize for handle resize (parentWidth, parentHeight)
          return (
            <div
              style={{
                transformOrigin: "0 0",
                transform: `scale(${scale})`,
                display: "flex",
                overflow: "hidden",
                left: 0,
                top: 0,
                position: "absolute",
                height: `calc(100% / ${scale})`,
                width: `calc(100% / ${scale})`,
              }}
            >
              <TableView
                hidden={props.hidden}
                parentWidth={width}
                parentHeight={height}
                zoom={zoom}
                item={props.item}
                id={props.item?.current?._id}
                sliceId={props.item?.current?.parent}
              />
            </div>
          );
        }}
      </AutoSizer>
    </div>
  );
};

const HyperGrid = (props) => {
  const zoom = useRef();
  const setZoom = useRef();

  useEffect(() => {
    if (!props.current) return;
  }, [props.current]);

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: props.theme.palette.olap.gridBackground,
      }}
    >
      {!(props.toolbar === false) && (
        <StyledButtonGroup variant="outlined" size="small">
          {ToolButton(IconAlignRight, () => {}, true, "Align right")}
          <Divider key="-1" orientation={"vertical"} flexItem={true} />
          {ToolButton(IconAlignCenter, () => {}, true, "Align center")}
          {ToolButton(IconAlignLeft, () => {}, true, "Align left")}
          {ToolButton(IconAlignJustify, () => {}, true, "Justify")}
          <PopoverCustom
            content={ZoomSlider}
            text={"Zoom"}
            icon={IconZoom}
            onChange={setZoom}
            zoom={zoom}
          />
        </StyledButtonGroup>
      )}
      <ZoomPanel zoom={zoom} setZoom={setZoom} {...props} />
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    api: state.API, //projectAPI,
    current: state.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrent: (node) => dispatch({ type: "SET_CURRENT", value: node }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(HyperGrid));
