export const cellsRenderer = (
  width,
  forceUpdate,
  classes,
  data,
  topLeft,
  visible,
  cellRenderer
) => {
  if (!data) return [];

  const m = [];

  const cellStyle = {
    position: "absolute",
    top: 0,
    width: `${width}px`,
    minHeight: "24px",
    height: "auto",
  };

  const top = topLeft.top;
  const left = topLeft.left;

  const xm = data?.colCount;
  const ym = data?.rowCount;

  const render = (y1, y2, x1, x2) => {
    let spanx = 1;
    let spany = 1;
    x2 = Math.min(x2, xm);
    y2 = Math.min(y2, ym);
    for (let k = y1; k < y2; k++) {
      const rowIndex = k;
      for (let i = x1; i < x2; i++) {
        const columnIndex = i;
        const key = `${data.uniqueId}:${columnIndex}-${rowIndex}`;
        let coord = null;
        const cell = cellRenderer(
          coord,
          classes,
          data,
          {
            bindData: { ndx: key, spanx, spany },
            columnIndex,
            key,
            rowIndex,
            style: cellStyle,
          },
          forceUpdate
        );
        m.push(cell);
      }
    }
  };

  const maxColNum = 1;
  const maxRowNum = Math.min(data.rowCount - 1, top + visible.current.row) + 1;

  render(top, maxRowNum, left, maxColNum); // Data
  return m;
};
