import { ReactComponent as IconFolder } from "@mdi/svg/svg/folder-outline.svg";
// import { ReactComponent as IconData } from "@mdi/svg/svg/text-box-outline.svg";
// import { ReactComponent as IconChart } from "@mdi/svg/svg/chart-box-outline.svg";
// import { ReactComponent as IconDB } from "@mdi/svg/svg/database-check-outline.svg";
// import { ReactComponent as IconHead } from "@mdi/svg/svg/head-lightbulb-outline.svg";
// import { ReactComponent as IconAdmin } from "@mdi/svg/svg/cog-outline.svg";
// import { ReactComponent as IconJournals } from "@mdi/svg/svg/book-open-outline.svg";
// import { ReactComponent as IconQuestion } from "@mdi/svg/svg/comment-question-outline.svg";

import { BaseApiClass } from "./baseApi";

let id = 1;

// const tree = [
//   {
//     id: "test-1",
//     parent: "dap",
//     properties: { title: "Статистические публикации", icon: IconChart },
//     size: 8,
//   },
//   {
//     id: "test-1-2",
//     parent: "test-1",
//     type: "test",
//     properties: { title: "Ключевые социально-экономические показатели" },
//     size: 0,
//   },
//   {
//     id: "test-1-3",
//     parent: "test-1",
//     type: "test",
//     properties: { title: "Национальные проекты РФ" },
//     size: 0,
//   },
//   {
//     id: "test-1-4",
//     parent: "test-1",
//     type: "test",
//     properties: { title: "Офицальная статистическая информация" },
//     size: 0,
//   },
//   {
//     id: "test-1-5",
//     parent: "test-1",
//     type: "test",
//     properties: { title: "Показатели рынка труда по данным ПФР" },
//     size: 0,
//   },
//   {
//     id: "test-1-6",
//     parent: "test-1",
//     type: "test",
//     properties: { title: "Интернет-обследование удовлетворенности" },
//     size: 0,
//   },
//   {
//     id: "test-1-7",
//     parent: "test-1",
//     type: "test",
//     properties: { title: "Реестр показателей" },
//     size: 0,
//   },
//   {
//     id: "test-1-8",
//     parent: "test-1",
//     type: "test",
//     properties: { title: "Реестр паспортов национальных и федеральных" },
//     size: 0,
//   },
//   {
//     id: "test-1-9",
//     parent: "test-1",
//     type: "test",
//     properties: { title: "Реестр ФПСР" },
//     size: 0,
//   },

//   {
//     id: "test-2-1",
//     parent: "dap",
//     properties: { title: "Аналитическое хранилище данных", icon: IconDB },
//     size: 2,
//   },
//   {
//     id: "test-2-2",
//     parent: "test-2-1",
//     type: "test",
//     properties: { title: "Национальные проекты России и статистические" },
//     size: 0,
//   },
//   {
//     id: "test-2-3",
//     parent: "test-2-1",
//     type: "test",
//     properties: { title: "Пенсионный фонд России и Оперативная служба" },
//     size: 0,
//   },

//   {
//     id: "test-3-1",
//     parent: "dap",
//     properties: { title: "Моделирование", icon: IconHead },
//     size: 0,
//   },

//   {
//     id: "data-bundles",
//     parent: "dap",
//     properties: { title: "Наборы данных", icon: IconData },
//     size: 2,
//   },
//   {
//     id: "data-bundles-upload",
//     parent: "data-bundles",
//     type: "bundles-upload",
//     properties: { title: "Реестр входящих документов" },
//     size: 0,
//   },
//   {
//     id: "data-bundles-download",
//     parent: "data-bundles",
//     type: "bundles-download",
//     properties: { title: "Реестр исходящих документов" },
//     size: 0,
//   },

//   {
//     id: "test-4",
//     parent: "dap",
//     properties: { title: "Администрирование", icon: IconAdmin },
//     size: 0,
//   },

//   {
//     id: "test-5",
//     parent: "dap",
//     properties: { title: "Журналы", icon: IconJournals },
//     size: 0,
//   },

//   {
//     id: "test-6",
//     parent: "dap",
//     properties: { title: "Справка", icon: IconQuestion },
//     size: 3,
//   },
//   {
//     id: "test-6-1",
//     parent: "test-6",
//     type: "test",
//     properties: { title: "Контакты" },
//     size: 0,
//   },
//   {
//     id: "test-6-2",
//     parent: "test-6",
//     type: "test",
//     properties: { title: "О системе" },
//     size: 0,
//   },
//   {
//     id: "test-6-3",
//     parent: "test-6",
//     type: "test",
//     properties: { title: "Справочная информация" },
//     size: 0,
//   },
//   {
//     id: "test-7",
//     parent: "dap",
//     type: "registries",
//     properties: { title: "Шаблон реестра" },
//     size: 0,
//   },
// ];

class DapAPI extends BaseApiClass {
  constructor(props) {
    super(props, "security");
    this.dispatch = props.dispatch;
    this.tree = null;
    this.id = id++;
  }

  processResponce = (res, parent) => {
    if (!res?.result) return [];

    const childrens = res.result.map((i) => ({ current: i }));
    return childrens;
  };

  async loadTree() {
    if (!this.token) {
      this.tree = [];
      return this.tree;
    }
    const menu = await this.send("menu");
    if (!Array.isArray(menu)) {
      this.tree = []
      return this.tree;
    }
    const visibleMenu = menu.filter((item) => !!item.visible);
    this.tree = visibleMenu.map((item) => {
      const foundChildItemsLength = visibleMenu.filter((menuItem) => menuItem.parent_code === item.code).length;
      return {
        id: item.code,
        parent: item.parent_code || "dap",
        type: item.type === "survey" ? "folder" : item.type,
        registry: item.target,
        properties: { title: item.value, target: item.target, newTab: item.new_tab, hint: item.hint, icon: item.icon || (foundChildItemsLength ? IconFolder : null) },
        size: foundChildItemsLength,
      };
    });
    this.dispatch({ type: "SET_TREE", value: this.tree });
    return this.tree;
  }

  async getTree() {
    if (this.tree) return this.tree;
    return await this.loadTree();
  }

  get = (parent) =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = {
            result: (await this.getTree()).filter((i) => i.parent === parent),
          };
          resolve(this.processResponce(res, parent));
        } catch (e) {
          resolve({ error: true, e });
        }
      })();
    });

  findItem = (query) =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = {
            result: (await this.getTree()).filter((i) => i.parent === query.parent && i.type === query.type),
          };
          resolve(this.processResponce(res));
        } catch (e) {
          resolve({ error: true, e });
        }
      })();
    });

  path = (id) =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = []; //await this.send('path', { id })
          while (id) {
            const i = (await this.getTree()).find((i) => i.id === id);
            if (i) {
              res.push(i);
              id = i.parent;
            } else id = null;
          }
          res.reverse();
          resolve(this.processResponce({ result: res }, null));
        } catch (e) {
          resolve({ error: true });
        }
      })();
    });

  callCustom = async (path, body, isFile) => await this.call(path, body, null, isFile);
}

export default DapAPI;
