import React from "react";
// import styled from "styled-components";
// import { Checkbox } from "@mui/material";

import ItemDialog from "components/itemDialog";

// import { FILTERS_ARRAY } from "..";

function ViewRowCard({ title, withIndicatorFilters, prop, schema: propsSchema, updateSchema, ...props }) {
  const initialData = React.useRef(prop?.current?.properties || {});
  const [filter/* , setFilter */] = React.useState(null);
  const [schema, setSchema] = React.useState(propsSchema);
  const [throughoutFields, setThroughoutFields] = React.useState([]);
  const [visibleConditionFields, setVisibleConditionFields] = React.useState([]);
  const [editableConditionFields, setEditableConditionFields] = React.useState([]);

  // const handleFilterChange = React.useCallback((ev, filterIndex) => {
  //   ev.stopPropagation();
  //   const newValue = FILTERS_ARRAY[filterIndex === 0 ? 1 : 0].code;
  //   if (filter === newValue) {
  //     setFilter(null);
  //     return;
  //   }
  //   setFilter(filter === null ? newValue : null)
  // }, [filter]);

  const handleDataChange = React.useCallback((newData) => {
    const isThroughoutFieldChanged = !!throughoutFields.find((code) => newData[code] !== initialData.current[code]);
    const isVisibleConditionFieldsChanged = !!visibleConditionFields.find((code) => newData[code] !== initialData.current[code]);
    const isEditableConditionFieldChanged = !!editableConditionFields.find((code) => newData[code] !== initialData.current[code]);
    initialData.current = { ...newData };
    if (!isThroughoutFieldChanged && !isEditableConditionFieldChanged && !isVisibleConditionFieldsChanged) return;
    setSchema(updateSchema(filter, initialData.current).schema);
  }, [throughoutFields, visibleConditionFields, editableConditionFields, updateSchema, filter]);

  React.useEffect(() => {
    const { schema, visibleConditionFields, editableConditionFields, throughoutFields } = updateSchema(filter, initialData.current);
    setSchema(schema);
    setThroughoutFields(throughoutFields);
    setVisibleConditionFields(visibleConditionFields);
    setEditableConditionFields(editableConditionFields);
  }, [filter]);

  return (
    <ItemDialog
      title={title}
      prop={React.useMemo(() => ({ current: { properties: initialData.current }}), [schema])}
      schema={schema}
      onChanged={handleDataChange}
      {...props}
    />
  )
}

// const StyledCheckbox = styled(Checkbox)`
//   path {
//     fill: white;
//   }
// `;

export default React.memo(ViewRowCard);
