import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import QuizList from "components/Survey/Views/QuizList";
import Quiz from "components/Survey/Views/Quiz";

import { QuizStorage } from "components/Survey/storages/QuizStorage";
import { GlobalStorage } from "components/Survey/storages/GlobalStorage";
import { AvailableLocales, UserInterface } from "components/Survey/storages/GlobalStorage/types";

import { getUuidv4 } from "components/Survey/utils/uuid";
import { localStorageManager } from "components/Survey/utils/localStorageManager";

export interface SurveyInterface {
  withHeader?: boolean;
  customHeader?: React.ReactNode;
  locale?: AvailableLocales;
  user?: UserInterface;
  code?: string;
  registry: string;
  onClose?: () => void;
}

function Survey({ code, registry, user, onClose }: SurveyInterface) {
  const [initialized, setInitialized] = React.useState(false);

  React.useLayoutEffect(() => {
    const uuid = localStorageManager.uuid || getUuidv4();
    GlobalStorage.uuid = uuid;
    QuizStorage.registry = registry;
    // localStorageManager.setUuid(uuid);

    if (user) GlobalStorage.setUser(user);
    setInitialized(true);
    return () => QuizStorage.clearQuiz();
  }, [user, registry]);

  if (!initialized) return null;

  return (
    <Container>
      {QuizStorage.quiz ? <Quiz onClose={onClose} /> : <QuizList code={code} onClose={onClose} canLoadQuiz={GlobalStorage.canLoadQuiz} />}
    </Container>
  );
}

const Container = styled("div")`
  padding: 4px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default observer(Survey);
