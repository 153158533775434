import React from "react";
import styled from "styled-components";

import { Button, CircularProgress } from "@mui/material";

import { ReactComponent as CheckIcon } from "@mdi/svg/svg/check.svg";
import { ReactComponent as CloseIcon } from "@mdi/svg/svg/close.svg";
import { ReactComponent as InformationIcon } from "@mdi/svg/svg/information-outline.svg";

import { FILE_STATUSES } from ".";

function iconByStatus(status) {
  switch (status) {
    case FILE_STATUSES.success: return <CheckIcon style={{ width: 20, height: 20 }} fill="#65d98b" />;
    case FILE_STATUSES.error: return <CloseIcon style={{ width: 20, height: 20 }} fill="#e74949" />;
    default: return <CircularProgress style={{ minWidth: 16, width: 16, height: 16, marginRight: 2 }} />;;
  }
}

function SecondStep({ filenames, uploadResponses, onReset, onRepeat, loading }) {
  return (
    <div style={{ borderTop: "1px solid #ededed" }}>
      {filenames.map((filename, index) => {
        const uploadResponse = uploadResponses[index];
        return (
          <FileContainer key={index}>
            <div>
              <div>{filename}</div>
              {!!uploadResponse && (uploadResponse.detail || !uploadResponse.found) && (
                <div style={{ display: "flex", alignItems: "center", gap: 4, marginTop: 2, color: "#b5b5b5", fontSize: 12 }}>
                  <InformationIcon style={{ width: 16, height: 16 }} fill="#b5b5b5" />
                  {uploadResponse.detail || "Файл не найден"}
                </div>
              )}
            </div>
            {iconByStatus(uploadResponse && !uploadResponse.found ? FILE_STATUSES.error : uploadResponse?.status)}
          </FileContainer>
        )
      })}
    </div>
  )
}

const FileContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 0.75em 0.25em;
  gap: 1em;
  border-bottom: 1px solid #ededed;
`;

export default React.memo(SecondStep);
