import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";

import { ReactComponent as ChevronDownSvg } from "../icons/chevron-down.svg";
import { ReactComponent as CheckGreenSvg } from "../icons/checkGreen.svg";
import { ReactComponent as ParalelSvg } from "../icons/paralel.svg";
import { ReactComponent as ClockSvg } from "../icons/clock.svg";

function StagesBlock({ state, title, subtitle, expandable, children }) {
  const [showChildContent, setShowChildContent] = React.useState(false);
  const Frame = () => {
    if (state === "ready") return <CheckGreenSvg />;
    if (state === "pending") return <ParalelSvg />;
    if (state === "feature") return <ClockSvg />;
    else return null;
  };

  const Chevron = () => (
    <ClickButton disabled={!expandable} onClick={() => setShowChildContent(!showChildContent)}>
      <ChevronDownSvg
        style={{
          transform: showChildContent ? "rotate(180deg)" : "none",
          transition: "transform 0.3s ease-in-out",
        }}
      />
    </ClickButton>
  );

  return (
    <>
      <StageBlock>
        <StatusFrame>
          <Frame />
        </StatusFrame>
        <StageName>
          <StyledName>{title}</StyledName>
          <StyledDescription>{subtitle}</StyledDescription>
        </StageName>
        <Chevron />
      </StageBlock>
      {/* {showStatusDate && (descriptions === "statusDate" ? <StatusDate /> : <NameStatus />)} */}
      {showChildContent && children}
    </>
  );
}

const StageBlock = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ClickButton = styled(Button)`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  border: none;
`;

const StatusFrame = styled("div")`
  width: 32px;
  height: 32px;
  padding: 4px;
  gap: 8px;
  margin-right: 8px;
`;

const StageName = styled("div")`
  width: 437px;
  flex: 1;
`;

const StyledName = styled("div")`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const StyledDescription = styled("div")`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7b868c;
`;

export default React.memo(StagesBlock);