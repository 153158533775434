import { Permutation, Axis } from "./OlapEngine";

export const setHyperData = (data, setData, isChart, element) => {
  console.log("HyperData: ", data);
  if (!data || !data.axis || !data.dims) return;

  const model = {
    _factsItem: data.factsItem,
    _facts: data.facts,
    //    _factNames: data.factNames
  };

  model._factMap = data.facts_num.reduce((result, num, ndx) => {
    if (num >= 0) result[num] = data.facts[ndx];
    return result;
  }, []);

  const factAxis = data.factsItem.properties.axis || "v";
  const oneFactName = data.factsItem.properties.oneFactName || false;

  const permV = new Permutation(data.axis.v);
  const permH = new Permutation(data.axis.h);

  const facts = data.data.length;

  model._vAxisHasFactName =
    factAxis === "v" && (facts > 1 || (oneFactName && facts !== 0));
  model._hAxisHasFactName =
    factAxis === "h" && (facts > 1 || (oneFactName && facts !== 0));

  permV.dims_val = data.dims.data;
  permV._values = data.data;

  permH.dims_val = data.dims.data;
  permH._values = data.data;

  const axisV = new Axis(permV, data.axis.size_v);
  axisV.isTree = isChart ? false : element.properties.axisV?.isTree === true;

  axisV.isShowTotal = element.properties.axisV?.isShowTotal !== false;
  axisV.isShowGrandTotal = element.properties.axisV?.isShowGrandTotal !== false;
  axisV.isTotalFirst = element.properties.axisV?.isTotalFirst === true;
  axisV.isInverseSort = element.properties.axisV?.isInverseSort === true;
  axisV.isAllowCollapse = element.properties.axisV?.isAllowCollapse !== false;

  axisV.facts = factAxis === "v" ? Math.max(1, facts) : 1;

  const axisH = new Axis(permH, data.axis.size_h);
  axisH.isTree = isChart ? false : element.properties.axisH?.isTree === true;

  axisH.isShowTotal = element.properties.axisH?.isShowTotal !== false;
  axisH.isShowGrandTotal = element.properties.axisH?.isShowGrandTotal !== false;
  axisH.isTotalFirst = element.properties.axisH?.isTotalFirst === true;
  axisH.isInverseSort = element.properties.axisH?.isInverseSort === true;
  axisH.isAllowCollapse = element.properties.axisH?.isAllowCollapse !== false;

  axisH.facts = factAxis === "h" ? Math.max(1, facts) : 1;

  model.dimensions = data.dimensions;

  model._axisV = axisV;
  model._axisH = axisH;
  model._values = data.data;
  model._dims = data.dims;

  axisH.setCollapsedLevel(-1, false);
  axisV.setCollapsedLevel(-1, false);

  //    axisH.setCollapsedLevel(0, false)
  //    axisH.setCollapsedLevel(1, false)
  //    axisV.setCollapsedLevel(0, false)

  axisH.validate();
  axisV.validate();

  setData(model);
};
