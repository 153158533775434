import React from "react";
import styled from "styled-components";

import TableEditor from "shared/ui/TableEditor";

import { ReactComponent as DownloadIcon } from "@mdi/svg/svg/download.svg";

import Filters from "./Filters";

const trDims = [
  ["time", "Статус документооборота", "Memo"],
  ["code", "Дата расчета", "Memo"],
  ["document_type", "Тип документа", "Memo"],
  ["upload_user", "Наименование документа", "Memo"],
  ["sign_user", "Статус публикации", "Memo"],
  ["download_date", "Дата выгрузки", "Memo"],
  ["start_date", "Начало отчетного периода", "Memo"],
  ["end_date", "Конец отчетного периода", "Memo"],
  ["download_errors", "Ошибки выгрузки", "Memo"],
].map(([id, title, type]) => ({
  id,
  properties: {
    title,
    name: id,
    axis: "data",
    position: 0,
    sortOrder: "none",
    filter: [],
    textFilter: {},
    type: type,
    isEditable: false,
  },
}));

function Download() {
  const schema = React.useMemo(
    () => ({
      value: "roles",
      type: "Table",
      data: {
        id: "bundles-download-table",
        trDims,
        toolbar: [
          [DownloadIcon, () => {}, true, null, null, null, "Выгрузить"],
        ],
      },
    }),
    []
  );

  return (
    <Container>
      <Filters />
      <TableEditor schema={schema} />
    </Container>
  );
}

const Container = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default React.memo(Download);
