const geometry = (xm, ym, data, heights, widths, y1, y2, x1, x2, pos) => {
  //  console.log('----------------------')
  x2 = Math.min(x2, xm);
  y2 = Math.min(y2, ym);
  for (let k = y1; k < y2; k++) {
    const rowIndex = k;
    const height = heights[rowIndex];
    pos.x = pos.startX;
    for (let i = x1; i < x2; i++) {
      const columnIndex = i;
      const width = widths[columnIndex];

      const key = `${data.uniqueId}:${columnIndex}-${rowIndex}`;
      const node = window[key]?.length ? window[key][0] : window[key];
      if (node) {
        node.style.top = pos.y + "px";
        node.style.left = pos.x + "px";
        node.style.height = height + "px";
      }

      pos.x += width - 1;
    }
    pos.y += height - 1;
  }
};

export { geometry };
