import styled from "styled-components";

export const Container = styled("div")<{ landscape?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ landscape }) => (landscape ? "1680px" : "1080px")};
  padding: 80px 100px;
  background: white;

  * {
    font-size 18px;
    color: black;
    font-family: "Times New Roman", sans-serif;
  }
`;

export const CenteredText = styled("div")<{ small?: boolean }>`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: ${({ small }) => (small ? "18px" : "22px")};
  white-space: break-spaces;
  max-width: 100%;
`;

export const RightContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const RightContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

export const Divider = styled("div")<{ small?: boolean; medium?: boolean }>`
  display: flex;
  height: ${({ small, medium }) => (small ? 10 : medium ? 20 : 40)}px;
`;

export const Table = styled("div")`
  border: 1px solid black;
  border-bottom: 0;
  width: 100%;
`;

export const TableRow = styled("div")`
  display: flex;
  border-bottom: 1px solid black;
`;

export const TableTitle = styled("div")`
  width: 40%;
  padding: 2px 4px;
  border-right: 1px solid black;
`;

export const TableContent = styled("div")`
  padding: 2px 4px;
  width: 60%;
`;

export const TableColumnName = styled("th")`
  border: 1px solid #000;
  textalign: center;
`;

export const TableCell = styled("td")`
  border: 1px solid #000;
`;

export const DocTable = styled.table.attrs({
  id: "table",
})`
  border-collapse: collapse;
  border-spacing: 0;
`;

export const DocTableRow = styled.tr.attrs({
  id: "row",
})``;
