import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { FixedSizeList } from "react-window";
import Tree from "components/tree";
import treeData from "shared/ui/tree/render";
import styled from "styled-components";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const itemCount = itemData.length;
  const itemSize = 36;
  const getHeight = () => itemSize * Math.min(8, itemCount);

  return (
    <OuterElementContext.Provider value={other}>
      <FixedSizeList
        style={theme.typography.text}
        itemData={itemData}
        height={getHeight() + 2 * LISTBOX_PADDING}
        width="100%"
        outerElementType={OuterElementType}
        itemSize={itemSize}
        itemCount={itemCount}
      >
        {renderRow}
      </FixedSizeList>
    </OuterElementContext.Provider>
  );
});

const TreeComponent = React.forwardRef(function TreeComponent(props, ref) {
  const {
    children,
    selected,
    onChange,
    setOpen,
    multiple,
    api,
    apiFilter,
    rootId,
    filter,
    stopNodes,
    hideRootNode,
    ...other
  } = props;
  const theme = useTheme();
  const itemSize = treeData.rowHeight;
  const getHeight = () => itemSize * Math.min(16);
  return (
    <OuterElementContext.Provider value={other}>
      <OuterElementType
        ref={ref}
        style={{
          ...theme.typography.text,
          width: "100%",
          height: getHeight(),
          display: "flex",
        }}
      >
        <Tree
          hideRootNode={hideRootNode !== false}
          api={api}
          apiFilter={apiFilter}
          rootId={rootId}
          stopNodes={stopNodes}
          current={selected}
          filter={filter}
          onChange={(value) => {
            setOpen(false);
            onChange({ target: { value } }, value);
          }}
        />
      </OuterElementType>
    </OuterElementContext.Provider>
  );
});

const useStyles = makeStyles((theme) => ({
  paper: { border: `1px solid ${theme.palette.divider}` },
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
}));

export default function VirtualAutocomplete(props) {
  const {
    isTree,
    treeData,
    api,
    apiFilter,
    rootId,
    stopNodes,
    hideRootNode,
    selected,
    optionIdField,
    optionNameField,
    onChange,
    multiple,
    ...other
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState();

  return isTree ? (
    <Autocomplete
      {...other}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={[{}]}
      disableListWrap
      classes={classes}
      ListboxComponent={TreeComponent}
      isOptionEqualToValue={(option, value) => true}
      ListboxProps={{
        selected: props.value,
        filter,
        api,
        apiFilter,
        rootId,
        stopNodes,
        onChange,
        multiple,
        hideRootNode,
        setOpen: (isOpen) => {
          setOpen(isOpen);
          setFilter();
        },
      }}
      onInputChange={(event, value, reason) => {
        if (reason === "input") setFilter(value);
      }}
      filterOptions={(options) => options}
      // для autoComplete { id: 0, name: 'name' }
      getOptionLabel={(option) =>
        option?.current?.properties.title || option?.name || ""
      }
    />
  ) : (
    <Autocomplete
      {...props}
      openText="Открыть"
      closeText="Закрыть"
      renderOption={(props, option, { index }) => (
        <li {...props} key={index} style={{ minHeight: 32, paddingLeft: option.isChild ? 40 : 16 }}>
          {option[optionNameField]}
        </li>
      )}
      classes={classes}
    />
  );
}
