import React, { useRef } from "react";

import { useDrag, useDrop } from "react-dnd";

import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";

import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";

import SvgIcon from "@mui/material/SvgIcon";
import {
  projectItemImageComponent,
  projectItemIconComponent,
} from "shared/utils";

import { ReactComponent as CheckIcon } from "@mdi/svg/svg/check.svg";

import { ReactComponent as PlayIcon } from "@mdi/svg/svg/tune.svg";

import CollapseIcon from "@mui/icons-material/ChevronRight";
import { TooltipR } from "shared/ui/ToolBar";

const Icon = (
  color,
  icon,
  size,
  htmlColor,
  additionalStyle,
  props,
  viewBox
) => {
  const style = {
    width: size || 24,
    height: size || 24,
    fontSize: "1em",
    ...(additionalStyle || {}),
  };
  const prop = props || [];
  return htmlColor ? (
    <SvgIcon
      {...prop}
      htmlColor={htmlColor}
      component={icon}
      style={style}
      viewBox={viewBox}
    />
  ) : (
    <SvgIcon
      {...prop}
      color={color}
      component={icon}
      style={style}
      viewBox={viewBox}
    />
  );
};

const RowWrapper = withTheme(styled("div")`
  display: flex;
  cursor: pointer;
  height: 40px; 
  min-height: 40px;
  padding-right: 24px;
  gap: 8px;
  align-items: center;
  border-left:  2px solid transparent;
  
  svg * {
    transition: fill 0.1s ease-in-out, stroke 0.1s ease-in-out, color 0.1s ease-in-out;
  }

  .expand-button svg path {
    color: #7B868C;
  }

  &:hover {
    .expand-button svg path {
      color: #42474D;
    }

    svg *[fill="#DCE3E5"] {
      fill: #B9D3FA !important;
    }

    svg *[fill="#42474D"] {
      fill: #0F479B !important;
    }
    
    svg *[stroke="#DCE3E5"] {
      stroke: #B9D3FA !important;
    }

    svg *[stroke="#42474D"] {
      stroke: #0F479B !important;
    }
  }

  ${({ selected }) => !selected ? "" : `
    svg *[fill="#DCE3E5"] {
      fill: #B9D3FA !important;
    }

    svg *[fill="#42474D"] {
      fill: #0F479B !important;
    }
    
    svg *[stroke="#DCE3E5"] {
      stroke: #B9D3FA !important;
    }

    svg *[stroke="#42474D"] {
      stroke: #0F479B !important;
    }
  `}
`);

const SelectedBorder = styled("div")`
  width: 2px;
  margin-right: 14px; 
  height: 24px; 
  background: ${({ selected }) => selected ? "#176DEF" : "transparent"};
  transition: background 0.1s ease-in-out;
`;

const FileThemeNodeContentRenderer = (props) => {
  const ref = useRef();
  const {
    nodeKey,
    hoverIndex,
    selected,
    // scaffoldBlockPxWidth,
    toggleChildrenVisibility,
    //      connectDragPreview,
    //      connectDragSource,
    // treeInDragging,
    //      canDrop,
    //      canDrag,
    node,
    // title,
    //      draggedNode,
    path,
    treeIndex,
    rowHeight = 24,
    iconSize = 16,
    /*
    isSearchMatch,
    isSearchFocus,
    buttons,
    className,
    style,
    //      didDrop,
//    lowerSiblingCounts,
    listIndex,
    swapFrom,
    swapLength,
    swapDepth,
    treeId, // Not needed, but preserved for other renderers
    isOver, // Not needed, but preserved for other renderers
    parentNode, // Needed for dndManager
    rowDirection,
*/
    onProps,
    onImageLoad,
  } = props;

  const portalMode = true;

  const id = nodeKey;
  const hover = id === hoverIndex.id;
  const timer = useRef();

  const [, /*{ handlerId }*/ drop] = useDrop({
    accept: "treeItem",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    canDrop(item /*, monitor*/) {
      const sourcePath = item.path;
      const destPath = path;

      const inSelf = () => {
        if (sourcePath.length > destPath.length) return false;
        return sourcePath.reduce(
          (result, key, index) => result && destPath[index] === key,
          true
        );
      };

      // запрет на перемещение элемента внутрь себя (sourcePath не должке являтся частью destPath)
      if (!sourcePath || !destPath || inSelf()) return false;

      return true;
    },

    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.id;
      const hoverIndex = id;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      // const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      // const hoverClientX = clientOffset.x - hoverBoundingRect.left
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      //      const hoverPos = hoverClientX < hoverMiddleX
      const hoverPos = hoverClientY < hoverMiddleY;

      if (
        node.expanded === true &&
        typeof node.children === "object" &&
        node.children.length === 0
      ) {
        // empty open node
      }

      if (item.hoverIndex === hoverIndex && item.hoverPos === hoverPos) {
        return;
      }

      props.onHover && props.onHover(hoverIndex, hoverPos);

      console.log("HOVER: ", hoverIndex);
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        timer.current = null;
        if (monitor.isOver()) {
          if (node.noChildren === true) return;

          if (node.expanded !== true)
            // закрытая, открываем и добавляем placeholder при необходимиости
            toggleChildrenVisibility({ node, path, treeIndex });
          else if (node.children.length === 0) {
            // открытая и без placeholder
          }
        } else {
          console.log("CANCEL on: ", hoverIndex);
        }
      }, 2000);

      item.hoverIndex = hoverIndex;
      item.hoverPos = hoverPos;
    },
    drop(self, monitor) {
      if (monitor.didDrop()) return;

      props.onHover && props.onHover(null);
      props.onEndDrag &&
        props.onEndDrag(self.id, self.hoverIndex, self.hoverPos);
      return { moved: true };
    },
  });

  const [, /*{ isDragging }*/ drag] = useDrag({
    type: "treeItem",
    item: () => {
      //      props.onStartDrag && props.onStartDrag({ path })
      return { id, path, data: node.data };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      props.onHover && props.onHover(null);
      props.onEndDrag && props.onEndDrag();
    },
  });

  drag(drop(ref));

  // Construct the scaffold representing the structure of the tree
  const scaffold = new Array(node.level - 1).fill().map((i, ndx) => {
    return (
      <div
        key={`pre_${1 + ndx}`}
        style={{ width: "1em", display: "inline-block", flex: "0 0 auto" }}
      />
    );
  });
  /*
  lowerSiblingCounts.forEach((lowerSiblingCount, i) => {
    if (i === 0)
      return;
    scaffold.push(
      <div
        key={`pre_${1 + i}`}
        style={{ width: '1em', display: 'inline-block', flex: '0 0 auto' }}
      />
    );

  });
*/
  // minWidth and minHeight need for disable change size by layout
  const loading = () => <CircularProgress size="1em" color="primary" />;
  const busy = () => (
    <CircularProgress
      size="1.55em"
      color="primary"
      style={{ marginRight: "0.5em" }}
    />
  );

  const isLoading = typeof node.children === "function" && node.expanded;

  const onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    node.size && toggleChildrenVisibility({ node, path, treeIndex });
  };

  const onPropsClick = (event) => {
    event.stopPropagation();
    onProps({ node, path, treeIndex });
  };

  const expandButton = () => !node.size ? null : (
    <IconButton
      size="small"
      aria-label={node.expanded ? "Collapse" : "Expand"}
      style={{ padding: 0, marginRight: "0.25em", transform: node.expanded ? "rotate(90deg)" : "rotate(0deg)", transition: "transform 0.1s ease-in-out" }}
      onClick={onClick}
      onContextMenu={onClick}
    >
      {Icon(
        "action",
        CollapseIcon,
        "1em",
        null,
        !node.size && { color: "transparent" }
      )}
    </IconButton>
  );

  const propsButton = () => (
    <IconButton
      size="small"
      aria-label={node.expanded ? "Collapse" : "Expand"}
      style={{ padding: 0 }}
      onClick={onPropsClick}
    >
      {Icon("action", PlayIcon, "1em")}
    </IconButton>
  );

  const selectedStyle = selected
    ? { color: props.theme.palette.text.current }
    : { color: props.theme.palette.text.primary };
  const not_actual = node.actual()
    ? {}
    : { color: props.theme.palette.text.disabled };

  const selectedIcon = () =>
    Icon("action", CheckIcon, 16, props.theme.palette.info.main);

  const nodeIcon = () => {
    const size = 24;
    const imageStyle = {
      display: "block",
      objectFit: "contain",
      cursor: "pointer",
      maxWidth: size,
      maxHeight: size,
      minWidth: size,
      minHeight: size,
      width: size,
      height: size,
    };
    // node.data = null используется для компоненты фильтрации
    return node.data && portalMode === true
      ? projectItemImageComponent(
        node.data.current,
        imageStyle,
        "0",
        "#42474D",
        true,
        true,
        onImageLoad,
        "64px",
        false
      )
      : projectItemIconComponent(
        node.icon(),
        imageStyle,
        node.iconType,
        false,
        size
      );
  };

  const handleDragStart = (event) => {
    const css = event.target.style.cssText;
    event.target.style.borderRadius = 0;
    event.target.style.border = "1px solid #007bff";
    event.target.style.paddingRight = "0.5em";
    event.target.style.backgroundColor = "#fff";
    requestAnimationFrame(() => {
      event.target.style.cssText = css;
    });
  };

  const nodeContent = (
    <TooltipR text={node?.data?.current?.properties?.hint}>
      <div style={{ paddingBottom: 8, height: 48, minHeight: 48 }}>
        <RowWrapper
          ref={ref}
          selected={selected}
          onClick={(event) => {
            if (node?.data?.current?.type === "folder") return onClick(event);
            props.onClick?.(event);
          }}
        >
          <SelectedBorder selected={selected} />
          {scaffold}
          {node.data?.current?.busy === true ? busy() : nodeIcon()}
          <div style={{ flex: 1 }}>
            <span
              title={node.name()}
              style={
                {
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  maxHeight: "80%",
                  fontSize: 14,
                  lineHeight: "17px",
                  fontWeight: 400,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "#42474D"
                }
              }
            >
              {node.name()}
            </span>
          </div>
          {props.isShowExpandIcon !== false && (props.singleLine ? !!node.size : true) && (
            <div
              className="expand-button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "0.5em",
                maxHeight: "0.5em",
                minWidth: "0.5em",
                minHeight: "0.5em",
                margin: "0 0.5em",
                cursor: "pointer",
              }}
            >
              {isLoading ? loading() : expandButton()}
            </div>
          )}
        </RowWrapper>
      </div>
    </TooltipR>
  );

  return nodeContent;
};

FileThemeNodeContentRenderer.defaultProps = {
  buttons: [],
  canDrag: false,
  canDrop: false,
  className: "",
  draggedNode: null,
  isSearchFocus: false,
  isSearchMatch: false,
  parentNode: null,
  style: {},
  swapDepth: null,
  swapFrom: null,
  swapLength: null,
  title: null,
  toggleChildrenVisibility: null,
};

export default withTheme(FileThemeNodeContentRenderer);
