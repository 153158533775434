const EMPTY_COLUMN_FILTERS = { textFilter: {}, filter: [] };

export function parseSettingsFilters(filters, code, fieldType) {
  if (!code || !Array.isArray(filters)) return EMPTY_COLUMN_FILTERS;
  const foundFilters = filters.filter((filter) => filter[1] === code);

  if (foundFilters.length === 0) return EMPTY_COLUMN_FILTERS;

  const firstFilter = foundFilters[0];
  const operator = firstFilter[2];

  if (operator === "IN") {
    const filter = [];
    (firstFilter[3] || []).forEach((value, index) => {
      if (!value || value === null) return;
      filter[index] = value;
    });
    return { textFilter: {}, filter };
  }

  const value = firstFilter[3]?.[0];
  if (value === null || value === undefined) return { textFilter: {}, filter: [] };

  if (fieldType === "Boolean") {
    return { textFilter: { value: { id: value, name: value ? "Да" : "Нет" } }, filter: [] };
  }

  if (fieldType === "Date" || fieldType === "DateTime") {
    const secondFilter = foundFilters[1];
    if (secondFilter) return { textFilter: { operator, start: value, end: secondFilter[3]?.[0] }, filter: [] };
    return { textFilter: { operator, start: value }, filter: [] };
  }

  if (typeof value === "string" && value.startsWith("%") && value.endsWith("%")) {
    const splittedValue = value.split("");
    splittedValue.splice(0, 1);
    splittedValue.splice(splittedValue.length - 1, 1);
    return { textFilter: { operator, text: splittedValue.join("") }, filter: [] };
  }

  return { textFilter: { operator, text: value }, filter: [] };
}
