import React from "react";
import styled from "styled-components";

import { Button, TextField } from "@mui/material";

interface PaginationInterface {
  onPageChange: (page: number) => void;
  loading?: boolean;
  page: number;
  code: string;
  maxPage: number;
}

function Pagination({ onPageChange, code, loading, page, maxPage }: PaginationInterface) {
  const [pageInput, setPageInput] = React.useState(page.toString());
  const [questionCode, setQuestionCode] = React.useState("");

  const prevPageButtonIsDisabled = React.useMemo(() => loading || page === 1, [loading, page]);
  const nextPageButtonIsDisabled = React.useMemo(() => loading || page === maxPage, [loading, page, maxPage]);

  React.useEffect(() => {
    if (questionCode === code) return;
    setQuestionCode(code);
    setPageInput(page.toString());
  }, [onPageChange, page, questionCode, code]);

  const handleNextPage = React.useCallback(() => {
    setPageInput((page + 1).toString());
    onPageChange(page + 1);
  }, [onPageChange, page]);

  const handlePrevPage = React.useCallback(() => {
    setPageInput((page - 1).toString());
    onPageChange(page - 1);
  }, [onPageChange, page]);

  const handleInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Math.min(Math.max(parseInt(event.target.value), 1), maxPage);

      if (isNaN(value)) {
        setPageInput("");
        return;
      }

      setPageInput(value.toString());
      onPageChange(value);
    },
    [onPageChange, maxPage]
  );

  if (maxPage < 2) return null;

  return (
    <Container>
      <ButtonsContainer>
        <Button variant="contained" color="primary" size="small" onClick={handlePrevPage} disabled={prevPageButtonIsDisabled}>
          {"<-"}
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleNextPage} disabled={nextPageButtonIsDisabled}>
          {"->"}
        </Button>
      </ButtonsContainer>
      <TextField variant="standard" onChange={handleInputChange} value={pageInput} />
    </Container>
  );
}

const Container = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  gap: 10px;
`;

export default React.memo(Pagination);
