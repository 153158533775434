import React from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";

import { ActionDialog } from "shared/ui";

import DocRegPage from "components/PDF/DocRegPage";

function PdfDocRegLoader({ onOk, registry }) {
  const { enqueueSnackbar } = useSnackbar();
  const api = useSelector((state) => state.API);

  const [loading, setLoading] = React.useState(false);
  const [isPdfLoaded, setPdfLoaded] = React.useState(false);
  const [elementsCount, setElementsCount] = React.useState(0);

  const handlePdfLoaded = React.useCallback(() => setPdfLoaded(true), []);
  const handleDownloadClick = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_API + "/pdf-web", {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          token: api.token,
          pages: [
            `${process.env.REACT_APP_PDF_URL}/documentationReg?&code=${registry.object_code}&token=${api.token}`,
          ],
        }),
      });

      if (!response.ok) {
        enqueueSnackbar("Ошибка", { variant: "error" });
        return;
      }

      const blob = await response.blob();
      const a = document.createElement("a");
      a.download = "Document.pdf";
      a.href = URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } finally {
      setLoading(false);
    }
  }, [api, enqueueSnackbar, registry]);

  return (
    <ActionDialog maxWidth="1160px" onClose={onOk} open title="Документ">
      <Container>
        <PdfContainer>
          <DocRegPage onLoadSuccess={handlePdfLoaded} api={api} code={registry.object_code} type={registry.object_class} elementsCallback={setElementsCount} preview />
        </PdfContainer>
        <DownloadButtonContainer>
          <CountValueInfo>
            <span>Количество элементов:</span>
            <span>
              {!isPdfLoaded ? (
                <CircularProgress color="primary" size="16px" />
              ) : (
                elementsCount
              )}
            </span>
          </CountValueInfo>
          <Button disabled={loading || !isPdfLoaded} onClick={handleDownloadClick} variant="contained">
            Скачать
            {loading && <CircularProgress style={{ marginLeft: 8 }} size={16} />}
          </Button>
        </DownloadButtonContainer>

      </Container>
    </ActionDialog>
  );
}

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  gap: 16px;
`;

const PdfContainer = styled("div")`
  flex: 1;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 2px;
`;

const DownloadButtonContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const CountValueInfo = styled("div")`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
`

export default React.memo(PdfDocRegLoader);
