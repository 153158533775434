import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import {
  Paper,
  Radio,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Checkbox,
  TextField,
  PaperTypeMap,
  Button,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import Pagination from "components/Survey/newComponents/Pagination";

import { MAX_QUESTIONS_PER_PAGE, QuizStorage } from "components/Survey/storages/QuizStorage";
import {
  isReferenceSelected,
  getTextFieldValue,
  validateTextFieldValue,
  getTextFieldFormulaValue,
  validateQuestionRule,
  validateQuestionCondition,
} from "components/Survey/storages/QuizStorage/utils";

import QuestionTitle from "./QuestionTitle";

import GroupFilterModal from "../GroupFilterModal";

function QustionGroupTable() {
  const [opened, setOpened] = React.useState(false);

  const handleModalOpen = React.useCallback(() => setOpened(true), []);
  const handleModalClose = React.useCallback(() => setOpened(false), []);

  const currentQuestion = QuizStorage.currentQuestion;

  React.useEffect(() => {
    if (!currentQuestion.isQuestionGroup) return;
    const isFilter = currentQuestion.allQuestions[0]?.config?.group === "select";
    currentQuestion.questions.forEach((question) => {
      const hasValue = QuizStorage.answers[question.code].answers?.code;
      const parsedDefaultValue = typeof question.default_value === "string" ? parseInt(question.default_value) : question.default_value;
      if (isFilter && hasValue) QuizStorage.onToggleOnGroupQuestionFilter(question);
      if (question.type || !parsedDefaultValue || question.multiple_values || hasValue) return;
      QuizStorage.selectAnswer(parsedDefaultValue, question);
    });
  }, [currentQuestion]);

  if (!currentQuestion.isQuestionGroup) return null;

  const isFilter = currentQuestion.allQuestions[0]?.config?.group === "select";

  return (
    <>
      {isFilter && (
        <div>
          <Button onClick={handleModalOpen}>Фильтр</Button>
          <Button disabled={currentQuestion.filterQuestions.length === 0} onClick={QuizStorage.resetGroupFilter}>
            Сбросить фильтр
          </Button>
        </div>
      )}
      {opened && <GroupFilterModal onClose={handleModalClose} />}
      <StyledTableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <span>Наименование</span>
              </TableCell>
              {currentQuestion.answer.Reference.map((reference) => (
                <CellContainer key={reference.code}>{reference.value}</CellContainer>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(isFilter
              ? currentQuestion.filterQuestions.slice(
                  (currentQuestion.page - 1) * MAX_QUESTIONS_PER_PAGE,
                  currentQuestion.page * MAX_QUESTIONS_PER_PAGE
                )
              : currentQuestion.questions
            ).map((question) => {
              if (question.hidden) return null;
              const error = !validateQuestionRule(question);
              const condition = validateQuestionCondition(question);

              const FormControl = question.multiple_values ? Checkbox : Radio;

              if (!condition) return null;

              if (question.config?.datatype === "text") {
                const reference = { code: 1, value: "" };
                return (
                  <TableRow key={question.code}>
                    <QuestionTitle error={error} question={question} />
                    <CellContainer withPadding>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 8 }}>
                        <TextField
                          value={getTextFieldValue(reference, question)}
                          style={{ minWidth: "200px", width: "100%", maxWidth: "300px" }}
                          variant="standard"
                          onChange={(event) => {
                            const value = validateTextFieldValue(question, event.target.value);
                            QuizStorage.changeTextAnswer({ code: reference.code, value }, question);
                          }}
                        />
                      </div>
                    </CellContainer>
                  </TableRow>
                );
              }

              return (
                <TableRow key={question.code}>
                  <QuestionTitle error={error} question={question} />
                  {currentQuestion.answer.Reference.map((reference) => {
                    const isSelected = isReferenceSelected(reference.code, question);

                    if (reference.input_type) {
                      return (
                        <CellContainer withPadding key={reference.code}>
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 8 }}>
                            <TextField
                              value={getTextFieldValue(reference, question)}
                              style={{ minWidth: "200px", width: "100%", maxWidth: "300px" }}
                              variant="standard"
                              onChange={(event) => {
                                const value = validateTextFieldValue(question, event.target.value);
                                QuizStorage.changeTextAnswer({ code: reference.code, value }, question);
                              }}
                            />
                          </div>
                        </CellContainer>
                      );
                    }

                    return (
                      <CellContainer withPadding key={reference.code}>
                        <FormControl
                          color="primary"
                          size="small"
                          checked={isSelected}
                          onChange={() => QuizStorage.selectAnswer(reference.code, question)}
                        />
                      </CellContainer>
                    );
                  })}
                </TableRow>
              );
            })}
            {currentQuestion.formulaQuestions.map((question) => {
              if (question.hidden) return null;
              const error = !validateQuestionRule(question);
              return (
                <TableRow key={question.code}>
                  <QuestionTitle error={error} question={question} />
                  <CellContainer withPadding>
                    <TextField
                      value={getTextFieldFormulaValue(question)}
                      style={{ minWidth: "200px", width: "100%", maxWidth: "300px" }}
                      variant="standard"
                    />
                  </CellContainer>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Pagination
        code={currentQuestion.code}
        page={currentQuestion.page}
        maxPage={currentQuestion.maxPages}
        onPageChange={QuizStorage.onGroupQuestionPageChange}
      />
    </>
  );
}

const StyledTableContainer = styled(TableContainer)<{ component: OverridableComponent<PaperTypeMap<{}, "div">> }>`
  background: white;

  th {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;

const CellContainer = styled(TableCell)<{ withPadding?: boolean }>`
  text-align: center !important;
  padding: 2px 5px;
`;

export default observer(QustionGroupTable);
