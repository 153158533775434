import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

function Dashboard({ link }) {
  const guestData = useSelector(state => state.guestData);

  const iframeSrc = React.useMemo(() => {
    if (typeof link !== "string" || !link.includes("biportal")) return link;
    const code = localStorage.getItem("code") || guestData?.biportal?.code;
    if (!code) return link;
    const haveQuery = link.includes("?");
    return haveQuery ? link + "&code=" + code : link + "?code=" + code;
  }, [guestData, link]);

  return (
    <Container>
      <Frame src={iframeSrc} />
    </Container>
  );
}

const Container = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
`;

const Frame = styled("iframe")`
  width: 100%;
  height: 100%;
`;

export default React.memo(Dashboard);
