import React, { Component, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  EditorBlock,
  convertFromRaw,
  convertToRaw,
  Editor as DraftJsEditor,
  EditorState,
  RichUtils,
  Modifier,
  SelectionState,
} from "draft-js";
import { getDefaultKeyBinding, KeyBindingUtil } from "draft-js";

import { withTheme } from "@mui/styles";
import { withSnackbar } from "notistack";

import debounce from "lodash/debounce";

import Prism from "prismjs";
import PrismDecorator from "draft-js-prism";
import "./prism.css";
import "./editor.css";
import "prismjs/components/prism-sql.js";

import Paper from "@mui/material/Paper";

import SvgIcon from "@mui/material/SvgIcon";
import Divider from "@mui/material/Divider";
import {
  ToolButton,
  ToolButtonCustom,
  Button,
  Button2,
  ButtonSeparator,
  StyledButtonGroup,
  ButtonEmpty,
} from "shared/ui/ToolBar";
import { AutoSizer } from "react-virtualized";

import { ReactComponent as IconAlignRight } from "@mdi/svg/svg/format-align-right.svg";
import { ReactComponent as IconAlignCenter } from "@mdi/svg/svg/format-align-center.svg";
import { ReactComponent as IconAlignLeft } from "@mdi/svg/svg/format-align-left.svg";
import { ReactComponent as IconAlignJustify } from "@mdi/svg/svg/format-align-justify.svg";
import { ReactComponent as UploadIcon } from "@mdi/svg/svg/image-outline.svg";
import { ReactComponent as IconPlay } from "@mdi/svg/svg/play.svg";
import { ReactComponent as IconClose } from "@mdi/svg/svg/close.svg";

import Splitter from "components/Splitters";
import TableView from "components/itemView/TableView";

const { hasCommandModifier } = KeyBindingUtil;
const tabCharacter = "    ";

class Line extends React.Component {
  render() {
    const { block, contentState } = this.props;
    const lineNumber =
      contentState
        .getBlockMap()
        .toList()
        .findIndex((item) => item.key === block.key) + 1;
    return (
      <div className="line" data-line-number={lineNumber}>
        <div className="line-text">
          <EditorBlock {...this.props} />
        </div>
      </div>
    );
  }
}

const blockRendererFn = () => ({
  component: Line,
});

class _Editor extends React.Component {
  constructor(props) {
    super(props);

    this.keyBindingFn = this.keyBindingFn.bind(this);
    this.setEditor = (editor) => {
      this.editor = editor;
    };
  }

  focusEditor = () => {
    if (this.editor) {
      this.editor.focus();
    }
  };

  componentDidMount() {
    this.focusEditor();
  }

  styles = {
    editor: {
      minHeight: "1em",
      overflow: "auto",
      ...this.props.theme.typography.code,
    },
  };

  keyBindingFn = (e) => {
    if (e.keyCode === 9) {
      return this.onTab(e);
    }
    return getDefaultKeyBinding(e);
  };

  onTab = (event) => {
    event.preventDefault();

    let currentState = this.props.editorState;
    let newContentState = Modifier.replaceText(
      currentState.getCurrentContent(),
      currentState.getSelection(),
      tabCharacter
    );

    this.props.setEditorState(
      EditorState.push(currentState, newContentState, "insert-characters")
    );
    return "handled";
  };

  render() {
    return (
      <div
        style={{ ...this.props.style, ...this.styles.editor }}
        onClick={this.focusEditor}
      >
        <DraftJsEditor
          ref={this.setEditor}
          editorState={this.props.editorState}
          onChange={this.props.onChange}
          blockRendererFn={blockRendererFn}
          keyBindingFn={this.keyBindingFn}
        />
      </div>
    );
  }
}

const ThemeEditor = withTheme(_Editor);

const decorator = new PrismDecorator({
  prism: Prism,
  defaultSyntax: "sql",
  filter: () => true,
});

const contentState = (text) =>
  convertFromRaw({
    entityMap: {},
    blocks: (text || "").split("\n").map((i) => ({ text: i })),
  });

const Editor = (props) => {
  const { api, item } = props;
  const [showResult, setShowResult] = useState(false);
  const editor = useRef();
  const savedText = useRef("");
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      contentState(item?.current?.properties?.sql),
      decorator
    )
  );
  const [tableItem, setTableItem] = useState();
  /*
    useEffect(() => {
      const getTable = async () => {
        item?.current?.properties?.table?.id && api.item(item.current.properties.table.id).then(i => {
          setTableItem(i)
        })
      }
  
      getTable()
    }, [])
  */
  /* работает крайне плохо
     'OUR TEXT BACK' - не работает если мы послали несколько изменений а только затем начали получать их обратно
     
    useEffect(() => {
      const now = item?.current?.properties?.sql
      // это вернулся ответ от нашего сохранения, мы уже могли изменить этот текст в редакторе, восстановим текст из редактора
      if (savedText.current == now) {
        console.log('OUR TEXT BACK')
        if (item?.current?.properties)
          item.current.properties.sql = editorState.getCurrentContent().getPlainText('\n')
        return
      }
  
      const oldSelectionState = editorState.getSelection()
      let newEditorState = EditorState.createWithContent(contentState(item?.current?.properties?.sql), decorator)
  
      let anchorKey = null
      let focusKey = null
  
      // пытаемся найти старые блоки в новом контексте
      const blockMap = newEditorState.getCurrentContent().getBlockMap()
      editorState.getCurrentContent().getBlockMap().forEach(i => {
        if (i.getKey() == oldSelectionState.getAnchorKey()) {
          blockMap.forEach(k => {
            if (i.text === k.text)
              anchorKey = k.getKey()
          })
        }
        if (i.getKey() == oldSelectionState.getFocusKey()) {
          blockMap.forEach(k => {
            if (i.text === k.text)
              focusKey = k.getKey()
          })
        }
      })
  
      // This sets a new SelectionState with the old selection's offsets
      // and the new editor state block keys
      const updateSelection = new SelectionState({
        anchorKey: anchorKey,
        anchorOffset: oldSelectionState.getAnchorOffset(),
        focusKey: focusKey,
        focusOffset: oldSelectionState.getFocusOffset(),
        isBackward: false,
      })
  
      newEditorState = EditorState.acceptSelection(
        newEditorState,
        updateSelection
      )
      newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection())
      setEditorState(newEditorState)
  
    }, [props.changed])
  */
  const saveContent = debounce(() => {
    if (api && item?.current) {
      api.update(item.current.id, item.current.properties);
      savedText.current = item.current.properties.sql;
    }
  }, 1000);

  const onChange = (editorState) => {
    const text = editorState.getCurrentContent().getPlainText("\n");
    if (
      item?.current?.properties &&
      (item.current.properties.sql || "") != text
    ) {
      item.current.properties.sql = text;
      saveContent();
    }
    setEditorState(editorState);
  };

  const focus = () => {
    editor.current && window.requestAnimationFrame(editor.current.focusEditor);
  };

  const onStyleClick = (style) => {
    onChange(RichUtils.toggleInlineStyle(editorState, style));
    focus();
  };

  const onColumnsDef = (columns) => {
    if (!columns) return;
    api.syncQueryColumn(item?.current?.id, columns).then((res) => {
      console.log("syncQueryColumn: ", res);
      props.enqueueSnackbar(`Query fields updated`, { variant: "success" });
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Splitter
        position="horizontal"
        primaryPaneMaxHeight="auto"
        primaryPaneMinHeight="auto"
        primaryPaneHeight="50%"
        postPoned={false}
      >
        <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <StyledButtonGroup variant="outlined" size="small">
            {ToolButton(
              IconPlay,
              () => {
                setTableItem({ ...item });
                if (!showResult) setShowResult(true);
              },
              item,
              "Run query"
            )}
            <Divider key="-1" orientation={"vertical"} flexItem={true} />
            {ToolButtonCustom(
              <span style={{ fontWeight: 600 }}>{"Bold"}</span>,
              onStyleClick.bind(null, "BOLD"),
              true,
              "Bold"
            )}
            {ToolButtonCustom(
              <span style={{ fontStyle: "Italic" }}>{"Italic"}</span>,
              onStyleClick.bind(null, "ITALIC"),
              true,
              "Italic"
            )}
            {ToolButtonCustom(
              <span style={{ textDecoration: "underline" }}>
                {"Underline"}
              </span>,
              onStyleClick.bind(null, "UNDERLINE"),
              true,
              "Underline"
            )}
            {ButtonEmpty(-3)}
            {ToolButton(IconAlignRight, () => {}, true, "Align right")}
            {ToolButton(IconAlignCenter, () => {}, true, "Align center")}
            {ToolButton(IconAlignLeft, () => {}, true, "Align left")}
            {ToolButton(IconAlignJustify, () => {}, true, "Justify")}
          </StyledButtonGroup>
          <div style={{ width: "100%", flexGrow: 1 }}>
            <AutoSizer>
              {({ width, height }) => (
                <ThemeEditor
                  ref={editor}
                  setEditorState={(state) => setEditorState(state)}
                  editorState={editorState}
                  onChange={onChange}
                  style={{ width, height }}
                />
              )}
            </AutoSizer>
          </div>
        </div>

        {showResult && (
          <div
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <StyledButtonGroup variant="outlined" size="small">
              {ToolButton(IconClose, () => setShowResult(false), true, "Close")}
            </StyledButtonGroup>
            <TableView item={tableItem} onColumnsDef={onColumnsDef} />
          </div>
        )}
      </Splitter>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    api: state.projectAPI,
    db: state.databaseAPI,
    changed: state.changed,
    current: state.current,
    path: state.path,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Editor)
);
