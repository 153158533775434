import React from "react";
import { debounce } from "lodash";
import styled from "styled-components";

import { fDate, fSelect, fText } from "shared/ui/Editors";
import { DUPLICATE_INDENTIFICATOR } from "shared/ui/TableEditor";

function CustomHeader({ column, reload, references }) {
  const [filterValue, setFilterValue] = React.useState(
    column?.properties?.textFilter?.text || column?.properties?.textFilter?.start || ""
  );

  const debouncedReload = React.useMemo(() => debounce(() => reload(), 500), [reload]);

  const onChange_v2 = (_, __, event) => {
    const value = event.target.value || "";
    column.properties.textFilter = value ? { operator: "LIKE", text: value } : {};
    setFilterValue(value);
    debouncedReload();
  };

  const onChangeSelect = (_, __, ___, code) => {
    column.properties.textFilter = code ? { operator: "=", text: code } : {};
    setFilterValue(code);
    debouncedReload();
  };

  const onChangeDate = (_, __, ___, date) => {
    column.properties.textFilter = date ? { operator: "=", start: date } : {};
    setFilterValue(date);
    debouncedReload();
  };

  const filterComponent = () => {
    if (column.properties.field_type === "reference") {
      const source = column.properties?.name?.split(DUPLICATE_INDENTIFICATOR)[0];
      const columnReferences = references[source];
      const sourceField = column.properties?.source_field;

      const select = columnReferences
        ? columnReferences.map((reference) => ({
            id: reference.code,
            name: sourceField ? reference[sourceField] || reference.value : reference.value,
          }))
        : [];

      return fSelect(
        { onChange_v2: onChangeSelect, onlyIdInValue: true, withNullableOption: true, select, value: { value: filterValue } },
        null,
        "value"
      );
    }

    switch (column.properties.type) {
      case "Memo":
        return fText({ onChange_v2 }, null, () => filterValue || "");
      case "DateTime":
      case "Date":
        return fDate(
          { onChange_v2: onChangeDate, autoSelectDate: false, value: { value: filterValue } },
          null,
          "value"
        );
      default:
        return null;
    }
  };

  return (
    <StyledHeaderContainer>
      <span style={{ flex: 1 }}>{column.properties.title}</span>
      {filterComponent()}
    </StyledHeaderContainer>
  );
}

const StyledHeaderContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 0.5em;
  min-height: 36px;
  overflow: hidden;
`;

export default React.memo(CustomHeader);
