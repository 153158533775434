import styled from "styled-components";

export const BlockContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TitleContainer = styled("div")`
  display: flex;
  gap: 12px;
  align-items: center;
  height: 32px;
`;

export const TitleText = styled("div")`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #2c3034;
  flex: 1;
`;

export const ScrollButtonsContainer = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ScrollButton = styled("div")`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  ${({ disabled }) => (disabled ? "border: 1px solid #dce3e5;" : "cursor: pointer; box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);")}

  svg {
    width: 20px;
    height: 20px;
    fill: ${({ disabled }) => (disabled ? "#B0BABF" : "#42474D")};
  }
`;

export const CardsContainer = styled("div")`
  display: grid;
  grid-template-columns: calc(25% - 16px * 3 / 4) calc(25% - 16px * 3 / 4) calc(25% - 16px * 3 / 4) calc(25% - 16px * 3 / 4);
  column-gap: 16px;
`;

export const CardContainer = styled("div")`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 12px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
`;

export const CardTitle = styled("div")`
  font-weight: 600;
  line-height: 20px;
  font-size: 14px;
  color: #2c3034;
  flex: 1;
`;

export const CardBody = styled("div")`
  font-size: 11px;
  line-height: 16px;
  color: #7b868c;
`;

export const HomeButton = styled("div")`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #edf1f2;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #2c3034;
  cursor: pointer;
`;
