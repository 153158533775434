type ApiResponseInterface<SuccessResponse> = SuccessResponse | { error: true };

const SURVEY_API = process.env.REACT_APP_SURVEY_BACK;
const APP_API = process.env.REACT_APP_API || "https://av.contourbi.ru/api";

class ApiClass {
  private async baseCall(path: string, config: Record<any, any>, appApi?: boolean) {
    try {
      return await fetch((appApi ? APP_API : SURVEY_API) + path, {
        headers: {
          "Content-Type": "application/json",
        },
        ...config,
      }).then((d) => d.json());
    } catch (error) {
      return { error: true };
    }
  }

  private async authRequest<SuccessResponse>(path: string, payload: Record<any, any>): Promise<ApiResponseInterface<SuccessResponse>> {
    try {
      const loginPath = process.env.REACT_APP_SURVEY_AUTH_API;
      if (!loginPath) throw new Error();
      return await fetch(loginPath + path, {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        method: "POST",
      }).then((d) => d.json());
    } catch (error) {
      return { error: true };
    }
  }

  async userInfo<SuccessResponse>(token: string): Promise<ApiResponseInterface<SuccessResponse>> {
    return await this.authRequest("/user", { token });
  }

  async login<SuccessResponse>(login: string, password: string): Promise<ApiResponseInterface<SuccessResponse>> {
    return await this.authRequest("/login", { login, password });
  }

  async get<SuccessResponse>(path: string, appApi?: boolean): Promise<ApiResponseInterface<SuccessResponse>> {
    return await this.baseCall(path, { method: "GET" }, appApi);
  }

  async post<SuccessResponse>(path: string, body: Record<any, any>, appApi?: boolean): Promise<ApiResponseInterface<SuccessResponse>> {
    return await this.baseCall(
      path,
      {
        body: JSON.stringify(body),
        method: "POST",
      },
      appApi
    );
  }
}

export const api = new ApiClass();
