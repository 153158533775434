import React from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";

interface DatePickerComponentInterface {
  value: string | null;
  onChange: (value: string | null) => void;
}

function DatePickerComponent({ value, onChange }: DatePickerComponentInterface) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value}
        onChange={onChange}
        // @ts-ignore :)
        renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, onChange: () => {} }} />}
      />
    </LocalizationProvider>
  );
}

export default React.memo(DatePickerComponent);
