import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Pdf from "components/PDF/RegistryPDF";

function PdfPage() {
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);
  const api = useSelector((state) => state.API);

  React.useEffect(() => {
    const token = searchParams.get("token");
    if (!api || !token) return;
    api.setToken(token);
  }, [api, searchParams]);

  if (!api) return null;

  const registryCode = searchParams.get("registry");
  const landscape = searchParams.get("landscape");
  const showdata = searchParams.get("showdata");
  const preview = searchParams.get("preview");
  const version = searchParams.get("version");
  const rowCode = searchParams.get("code");

  return (
    <Pdf
      api={api}
      preview={preview}
      registryCode={registryCode}
      showdata={showdata}
      landscape={landscape}
      rowCode={rowCode}
      version={version}
    />
  );
}

export default React.memo(PdfPage);
