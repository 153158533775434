import React from 'react'
import { StyledTab } from 'shared/ui';
import { Icon, StyledButtonGroup, ToolButton, TooltipR } from 'shared/ui/ToolBar';
import styled from 'styled-components';
import { ReactComponent as IconClose } from "@mdi/svg/svg/close.svg";
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as IconFilterRemove } from "shared/icon/filter_remove.svg";
import { ReactComponent as IconVisible } from "shared/icon/eye_open.svg";
import { ReactComponent as IconHidden } from "shared/icon/eye_closed.svg";

function HiddenColumns() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.hiddenColumns);

  const [update, setUpdate] = React.useState(false);

  const handleColumnVisibilityToggle = React.useCallback((index) => {
    data.trDims[index].properties.hidden = !data.trDims[index].properties.hidden;
    data.onChange();
    setUpdate(!update);
  }, [data, update]);
  
  const handleAllColumnsShow = React.useCallback(() => {
    data.trDims.forEach(trDim => trDim.properties.hidden = false);
    data.onChange();
    setUpdate(!update);
  }, [data, update]);
  
  const handleColumnFiltersClear = React.useCallback((index) => {
    if (data.trDims[index].properties.clearableFilter === false) return;
    data.trDims[index].properties.filter && (data.trDims[index].properties.filter = []);
    data.trDims[index].properties.textFilter && (data.trDims[index].properties.textFilter = {});
    data.onChange();
    setUpdate(!update);
  }, [data, update]);

  if (!Array.isArray(data?.trDims)) return null;

  return (
    <Container>
      <StyledTab
        selected={true}
        label={
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                height: 30,
                flex: 1
              }}
            >
              Колонки
            </span>
            <TooltipR text={<span>Закрыть</span>}>
              {ToolButton(
                IconVisible,
                handleAllColumnsShow,
                true,
                "Показать все",
                "2",
                null
              )}
            </TooltipR>
            <TooltipR text={<span>Закрыть</span>}>
              {ToolButton(
                IconClose,
                () => dispatch({ type: "SET_HIDDEN_COLUMNS", value: null }),
                true,
                <span>Закрыть</span>,
                "2",
                null
              )}
            </TooltipR>
          </div>
        }
      />
      <ItemsContainer>
        {data.trDims.map((column, index) => {
          const isHiddenColumn = !!column.properties.hidden;
          const hasFilter = (
            Array.isArray(column.properties.filter) && 
            column.properties.filter.length > 0 &&
            !column.properties.filter.includes(-1)  
          ) || (
            column.properties.textFilter?.operator &&
            column.properties.textFilter.operator !== "ANY"
          );

          if (column.properties.visibility === "0") return null;

          return (
          <ColumnItem key={index}>
            <div>
              {column.properties.title}
            </div>
            <ButtonGroup>
              {ToolButton(
                () => Icon("action", IconFilterRemove, "16px"),
                () => handleColumnFiltersClear(index),
                hasFilter,
                "Сбросить фильтры",
                "1",
                null
              )}
              {ToolButton(
                isHiddenColumn ? IconHidden : IconVisible,
                () => handleColumnVisibilityToggle(index),
                true,
                isHiddenColumn ? "Показать" : "Скрыть",
                "2",
                null
              )}
            </ButtonGroup>
          </ColumnItem>
        )})}
      </ItemsContainer>
    </Container>
  )
}

const Container = styled("div")`
  position: relative; 
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
`;

const ItemsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 118px);
`;

const ColumnItem = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DCDEE8;
`;

const ButtonGroup = styled(StyledButtonGroup)`
  border: 0 !important;
  background: white !important;
  min-height: 42px !important;
  height: 42px !important;
  min-width: max-content;
  border-top: ${({ removeTopBorder }) => removeTopBorder ? "0 !important" : "inhirit"};

  & .toolbutton {
    gap: 0.4em;
    font-size: 13px;
    font-weight: 500;
    color: #1C458A;
    min-width: 37px !important;
  }
  
  & .toolbutton svg {
    transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);
    margin: 0;
    fill: #1C458A !important;

    &[fill] {
      fill: #1C458A !important;
    } 
    
    path[fill] {
      fill: #1C458A !important;
    }
    
    path[fill="white"] {
      fill: white !important;
    }
  
    path[stroke] {
      stroke: #1C458A !important;
    }
    
    rect {
      fill: #1C458A !important;
    }
  }

  & .toolbutton:disabled {
    color: #DCDEE8;
    svg {
      fill: #DCDEE8 !important;

      &[fill] {
        fill: #DCDEE8 !important;
      } 

      path[fill] {
        fill: #DCDEE8 !important;
      }

      path[fill="white"] {
        fill: white !important;
      }
    
      path[stroke] {
        stroke: #DCDEE8 !important;
      }
      
      rect {
        fill: #DCDEE8 !important;
      }
    }
  }
`;

export default React.memo(HiddenColumns);
