import { withStyles } from "@mui/styles";

import UiTabs from "@mui/material/Tabs";
import UiTab from "@mui/material/Tab";

export const Tabs = withStyles((theme) => ({
  root: {
    minHeight: "unset",
    overflow: "unset",
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    display: "none",
  },
}))(UiTabs);

export const Tab = withStyles((theme) => ({
  root: {
    padding: "0.5em 1em",
    borderBottom: `2px solid transparent`,
    textTransform: "none",
    minWidth: "1em",
    minHeight: 0,
    color: theme.palette.text.primary,
    "&:focus": {},
    "& > span": {
      display: "flex",
      flexDirection: "row",
      whiteSpace: "nowrap",
    },
  },
  selected: {
    color: theme.palette.text.primary + " !important",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    textTransform: "none",
    minWidth: "1em",
    minHeight: 0,
  },
}))((props) => <UiTab disableRipple {...props} />);
