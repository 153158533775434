import { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import { withTheme } from "@mui/styles";
import styled from "styled-components";

import { projectItemImageComponent } from "shared/utils";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { AutoSizer } from "react-virtualized";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
    cursor: "pointer",
    maxWidth: "17em",
    minWidth: "17em",
    margin: "0.5em",
    "&:hover": {
      boxShadow: "0 5px 30px rgb(0 0 0 / 10%)",
    },
    "&:active": {
      transform: "translate(0,2px) scale(.99)",
      boxShadow: "0 0 15px rgb(0 0 0 / 10%)",
    },
    "& .MuiCardHeader-root": {
      "& .MuiCardHeader-content": {
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .MuiCardContent-root": {
      paddingTop: 0,
    },
  },
}));

const CustomCardHeader = styled(CardHeader)`
  gap: 16px;

  .MuiCardHeader-avatar {
    margin: 0;
  }
`;

const CardView = (props) => {
  const [children, setChildren] = useState([]);
  const Parent = useRef(null);
  const classes = useStyles();

  const setCurrent = (current) => {
    console.log("Update current: ", current);
    Parent.current = current;
    const api = props.api;
    if (current?.current?.id) {
      api.get(current?.current.id).then((res) => {
        setChildren(res.error ? [] : res);
      });
    } else setChildren([]);
  };

  useEffect(() => {}, [props.current]);

  useEffect(() => {
    if (!props.itemChanged || Parent?.current?.current?.id !== props.itemChanged.parent) return;

    switch (props.itemChanged?.type) {
      case "item_create":
        props.api.item(props.itemChanged.id).then((res) => {
          setChildren([...children, res]);
        });
        break;
      case "item_delete":
        const ndx = children.findIndex((i) => i.current.id === props.itemChanged.id);
        if (ndx !== -1) {
          children.splice(ndx, 1);
          setChildren([...children]);
        }
        break;
      case "item_update":
        break;
      default:
        break;
    }
  }, [props.itemChanged]);

  useEffect(() => {
    console.log("CardView: props.item", props.item);
    setCurrent(props.item);
  }, [props.item]);

  const showFirstComponent = async (report) => {
    const cubeId = report.properties?.cube?.id;
    const cube = cubeId && (await props.api.item(cubeId));
    const slices = cube?.current?.properties?.slices && (await props.api.get(cube.current.properties.slices));
    const components = slices?.[0]?.current?.properties?.components && (await props.api.get(slices[0].current.properties.components));
    components?.[0] && props.setCurrent(components[0]);
  };

  const handleClick = (i) => {
    if (i?.current.type === "Report") {
      showFirstComponent(i.current);
      return;
    }
    props.setCurrent(i);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
        width: "100%",
        flexGrow: 1,
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <Paper
            square
            elevation={0}
            style={{
              width,
              height,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignContent: "start",
            }}
          >
            {children.map((i, ndx) => {
              return (
                i.current && (
                  <Card className={classes.root} key={ndx} variant="outlined" onClick={handleClick.bind(null, i)}>
                    <CustomCardHeader
                      style={{ alignItems: "start" }}
                      avatar={projectItemImageComponent(i.current, "5em", "0 0.5em 0 0", props.theme.palette.menu.icon)}
                      titleTypographyProps={{ display: "inline" }}
                      title={i.current?.properties.title}
                      subheader={i.current?.properties.data?.Released}
                    />
                    {i.current?.properties.description && (
                      <CardContent style={{ display: "flex", backgroundColor: "white" }}>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          component="p"
                          display="inline"
                          style={{ maxHeight: "10em", overflow: "hidden" }}
                        >
                          {i.current?.properties.description}
                        </Typography>
                      </CardContent>
                    )}
                  </Card>
                )
              );
            })}
            <div style={{ height: "1px", width: "100%" }} /> {/* без этого не учитывается нижний { margin: '0.5em' } у карточки */}
          </Paper>
        )}
      </AutoSizer>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    api: state.API,
    current: state.current,
    itemChanged: state.itemChanged,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrent: (node) => dispatch({ type: "SET_CURRENT", value: node }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(CardView));
