import React, { Component } from "react";
import { EditorState } from "draft-js";
import classNames from "classnames";

import { Icon } from "shared/ui/ToolBar";

import { ReactComponent as AlignLeftIcon } from "@mdi/svg/svg/format-align-left.svg";
import { ReactComponent as AlignCenterIcon } from "@mdi/svg/svg/format-align-center.svg";
import { ReactComponent as AlignRightIcon } from "@mdi/svg/svg/format-align-right.svg";

import Option from "../../Option";
import "./styles.css";

const getImageComponent = (config) =>
  class Image extends Component {
    state = {
      hovered: false,
    };

    setEntityAlignmentLeft = () => {
      this.setEntityAlignment("left");
    };

    setEntityAlignmentRight = () => {
      this.setEntityAlignment("right");
    };

    setEntityAlignmentCenter = () => {
      this.setEntityAlignment("center");
    };

    setEntityAlignment = (alignment) => {
      const { block, contentState } = this.props;
      const entityKey = block.getEntityAt(0);
      contentState.mergeEntityData(entityKey, { alignment });
      config.onChange(
        EditorState.push(
          config.getEditorState(),
          contentState,
          "change-block-data"
        )
      );
      this.setState({
        dummy: true,
      });
    };

    toggleHovered = () => {
      const hovered = !this.state.hovered;
      this.setState({
        hovered,
      });
    };

    renderAlignmentOptions(alignment) {
      return (
        <div
          className={classNames("rdw-image-alignment-options-popup", {
            "rdw-image-alignment-options-popup-center": alignment === "center",
            "rdw-image-alignment-options-popup-right": alignment === "right",
          })}
        >
          <Option onClick={this.setEntityAlignmentLeft}>
            {Icon("action", AlignLeftIcon)}
          </Option>
          <Option onClick={this.setEntityAlignmentCenter}>
            {Icon("action", AlignCenterIcon)}
          </Option>
          <Option onClick={this.setEntityAlignmentRight}>
            {Icon("action", AlignRightIcon)}
          </Option>
        </div>
      );
    }

    render() {
      const { block, contentState } = this.props;
      const { hovered } = this.state;
      const { isReadOnly, isImageAlignmentEnabled } = config;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src, alignment, height, width, alt } = entity.getData();

      return (
        <span
          onMouseEnter={this.toggleHovered}
          onMouseLeave={this.toggleHovered}
          className={classNames("rdw-image-alignment", {
            "rdw-image-left": !alignment || alignment === "left",
            "rdw-image-right": alignment === "right",
            "rdw-image-center": alignment === "center",
          })}
        >
          <span className="rdw-image-imagewrapper">
            <img
              src={src}
              alt={alt}
              style={{
                height,
                width,
              }}
            />
            {!isReadOnly() && hovered && isImageAlignmentEnabled()
              ? this.renderAlignmentOptions(alignment)
              : undefined}
          </span>
        </span>
      );
    }
  };

export default getImageComponent;
