import React from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { useSelector } from "react-redux";

import "dayjs/locale/ru";

function getCurrentTime() {
  return dayjs(new Date()).format("HH:mm");
}

function getCurrentDate() {
  return dayjs(new Date()).locale("ru").format("DD MMMM, dddd");
}

function getWelcomeText() {
  const hours = dayjs(new Date()).get("hours");
  if (hours < 6) return "Доброй ночи";
  if (hours < 12) return "Доброе утро";
  if (hours < 18) return "Добрый день";
  if (hours < 21) return "Добрый вечер";
  return "Доброй ночи";
}

function getUserName(user) {
  if (!user?.userProfile) return null;
  const name = [];
  if (user.userProfile.nameFirst) name.push(user.userProfile.nameFirst);
  if (user.userProfile.nameMiddle) name.push(user.userProfile.nameMiddle);
  return name.length === 0 ? null : name.join(" ");
}

function WelcomeBanner() {
  const userData = useSelector((state) => state.userData);

  const [time, setTime] = React.useState(getCurrentTime());
  const [date, setDate] = React.useState(getCurrentDate());

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(getCurrentTime());
      setDate(getCurrentDate());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const userName = getUserName(userData);
  const welcomeText = getWelcomeText();

  return (
    <BannerContainer>
      {userName ? (
        <div>
          <BannerTitle>{welcomeText},</BannerTitle>
          <BannerTitle>{userName}!</BannerTitle>
        </div>
      ) : (
        <div>
          <BannerTitle>{welcomeText}!</BannerTitle>
        </div>
      )}
      <div>
        <BannerTime>{time}</BannerTime>
        <BannerDate>{date}</BannerDate>
      </div>
      <BannerShadow />
    </BannerContainer>
  );
}

const BannerContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  width: 100%;
  padding: 0 70px;
  background: url("/images/home-banner.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
`;

const BannerTitle = styled("div")`
  color: #2c3034;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
`;

const BannerTime = styled("div")`
  font-size: 32px;
  line-height: 40px;
  text-align: right;
  color: #2c3034;
`;

const BannerDate = styled("div")`
  font-size: 13px;
  line-height: 20px;
  color: #2c3034;
`;

const BannerShadow = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(150, 178, 220, 0.56) 0%, rgba(176, 194, 220, 0) 91.67%);
`;

export default React.memo(WelcomeBanner);
