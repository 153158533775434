import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import FilterList from "./FilterList";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";

import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";

import { Icon, ToolButton } from "shared/ui/ToolBar";

import { projectItemImageComponent } from "shared/utils";

import { ReactComponent as FilterIcon } from "@mdi/svg/svg/menu-down.svg";

const StyledPaper = withStyles(
  (theme) => ({
    root: {
      display: "flex",
      border: `1px solid
    ${
      theme.palette.type === "light"
        ? "rgba(0, 0, 0, 0.25)"
        : "rgba(255, 255, 255, 0.75)"
    }`,
      alignItems: "center",
      backgroundColor: theme.palette.olap.dimCaptionBackground,
    },
  }),
  { withTheme: true }
)(Paper);

const styles = {
  container: {
    display: "flex",
    cursor: "grabbing",
    paddingRight: ".25em",
    paddingBottom: ".25em",
  },
  details: {
    display: "flex",
    userSelect: "none",
    WebkitUserSelect: "none",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
    padding: 0,
  },
  cover: {
    pointerEvents: "none",
    marginRight: "0.25em",
    fontSize: "1em",
  },
};

export const PopoverCustom = (props) => {
  const Component = props.content;
  return (
    <PopupState variant="popover" popupId={props.id + "popup"}>
      {(popupState) => (
        <React.Fragment>
          <span {...bindTrigger(popupState)}>
            {ToolButton(props.icon, null, true, props.text, 0)}
          </span>
          <Popover
            {...bindPopover(popupState)}
            PaperProps={{ style: { overflowY: "hidden" } }}
            getContentAnchorEl={null}
            autoFocus={true}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            <Component popupState={popupState} {...props} />
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export const FactButton = (props) => {
  const style = props.style || {};
  const id = props.id;
  const theme = useTheme();

  return (
    (props.factsItem || null) && (
      <div style={styles.container}>
        <StyledPaper
          elevation={0}
          square={props.square === true}
          key={id}
          id={id}
          style={{ ...style }}
        >
          <div
            style={{
              width: "3px",
              height: "100%",
              backgroundColor: "transparent",
            }}
          />
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              margin: "0.2em 0.2em",
              alignItems: "center",
            }}
          >
            {projectItemImageComponent(
              props.factsItem,
              "24px",
              "0 0.5em 0 0",
              theme.palette.menu.icon
            )}
            <div style={styles.details}>
              <span style={{ width: "min-content" /*, whiteSpace: 'nowrap'*/ }}>
                {props.factsItem?.properties?.title}
              </span>
            </div>
            <PopoverCustom
              {...props}
              content={FilterList}
              text={"Visible Facts"}
              icon={FilterIcon}
            />
          </div>
          <div
            style={{
              width: "3px",
              height: "100%",
              backgroundColor: "transparent",
            }}
          />
        </StyledPaper>
      </div>
    )
  );
};

export const DimButton = (props) => {
  const ref = useRef();
  const style = { ...props.style } || {};
  const id = props.id;
  const theme = useTheme();
  const [hoverPos, setHoverPos] = useState(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "dim",
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.id;
      const hoverIndex = props.id;
      const hoverDim = props.dim;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        // сбрасываем если был
        if (item.hoverDim) {
          item.hoverIndex = null;
          item.hoverDim = null;
          item.hoverPos = null;
          props.onHover && props.onHover(null, null);
        }
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      const hoverPos = hoverClientX < hoverMiddleX;

      if (item.hoverIndex === hoverIndex && item.hoverPos === hoverPos) {
        return;
      }

      // Time to actually perform the action
      //     console.log('HOVER: ', dragIndex, hoverIndex, hoverPos)
      setHoverPos(hoverPos);
      //      props.onHover && props.onHover(hoverIndex, hoverPos)
      //moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.hoverIndex = hoverIndex;
      item.hoverDim = hoverDim;
      item.hoverPos = hoverPos;
    },
    canDrop(item, monitor) {
      return true;
    },
    drop(self, monitor) {
      if (monitor.didDrop()) return;

      props.onDrop && props.onDrop(self.dim, self.hoverDim, self.hoverPos);
      return { moved: true };
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "dim",
    item: () => {
      console.log("START DRAG:", id);
      props.onStartDrag && props.onStartDrag(id);
      return { id, dim: props.dim };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      props.onDrop && props.onDrop();
    },
  });

  drag(drop(ref));

  const isDropState = canDrop && isOver;
  //console.log(props.dim.properties.title, 'isDropState', isDropState, 'HoverPos:', hoverPos, 'IsDragging', isDragging)
  const lc = isDragging
    ? "#007bff"
    : isDropState && hoverPos === true
    ? "green"
    : "transparent";
  const rc = isDragging
    ? "#007bff"
    : isDropState && hoverPos === false
    ? "green"
    : "transparent";

  const handleDragStart = (event) => {
    const borderRadius = event.target.style.borderRadius;
    const borderColor = event.target.style.borderColor;
    event.target.style.borderRadius = 0;
    event.target.style.borderColor = "#007bff";
    requestAnimationFrame(() => {
      event.target.style.borderRadius = borderRadius;
      event.target.style.borderColor = borderColor;
    });
  };

  if (props.isDragState) style.borderColor = "#fd7e14";

  return (
    <div style={styles.container}>
      <div
        id={id}
        style={{ ...style, zIndex: isDragging ? 2 : 1, display: "flex" }}
      >
        <StyledPaper
          ref={ref}
          onDragStart={handleDragStart}
          elevation={0}
          square={props.square === true}
          key={id}
          style={{
            ...props.paperStyle,
            flexGrow: 1 /*, width: '100%', height: '100%'*/,
          }}
        >
          <div style={{ width: "3px", height: "100%", backgroundColor: lc }} />
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              margin: "0.2em 0.2em",
              alignItems: "center",
            }}
          >
            {props.icon
              ? Icon("action", props.icon, "24px", null, styles.cover)
              : projectItemImageComponent(
                  props.dim,
                  "24px",
                  "0 0.5em 0 0",
                  theme.palette.menu.icon
                )}
            <div style={styles.details}>
              <span style={{ width: "min-content", whiteSpace: "nowrap" }}>
                {props.dim.properties.title}
              </span>
            </div>
            {props.dim._id && (
              <PopoverCustom
                content={FilterList}
                {...props}
                text={"Filter"}
                icon={FilterIcon}
              />
            )}
          </div>
          <div style={{ width: "3px", height: "100%", backgroundColor: rc }} />
        </StyledPaper>
      </div>
    </div>
  );
};
