import React from "react";
import styled from "styled-components";

function ResizeableWidth({ width, onWidthChange, children }) {
  const lastPosition = React.useRef(null);
  const currentWidth = React.useRef(width);

  const [dragging, setDragging] = React.useState(false);
  const [rightDrag, setRightDrag] = React.useState(false);

  const handleDrag = React.useCallback((event) => {
    const lastPositionDifference = (lastPosition.current - event.clientX) * 2;
    const newWidth = Math.max(300, rightDrag ? currentWidth.current - lastPositionDifference : currentWidth.current + lastPositionDifference);
    lastPosition.current = event.clientX;
    currentWidth.current = newWidth;
    onWidthChange(newWidth);
  }, [onWidthChange, rightDrag]);

  const handleDragEnd = React.useCallback(() => setDragging(false), []);

  React.useEffect(() => {
    if (!dragging) return;
    window.addEventListener("mousemove", handleDrag);
    window.addEventListener("mouseup", handleDragEnd);
    
    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mouseup", handleDragEnd);
    }
  }, [dragging, handleDrag, handleDragEnd]);

  const handleDragStart = React.useCallback((event, isRight) => {
    lastPosition.current = event.clientX;
    setDragging(true);
    setRightDrag(isRight);
  }, []);
  
  return (
    <Container>
      <Scrollable onMouseDown={(event) => handleDragStart(event, false)} left />
      {children}
      <Scrollable onMouseDown={(event) => handleDragStart(event, true)} right />
    </Container>
  );
}

const Container = styled("div")`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: inhirit;
  overflow: hidden;
`;

const Scrollable = styled("div")`
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ left }) => left ? "left: 0;" : ""}
  ${({ right }) => right ? "right: 0;" : ""}
  width: 10px;
  cursor: ew-resize;
`;

export default React.memo(ResizeableWidth);
