import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";

import { withConfirmationDialog } from "shared/ui/ConfirmationDialog";
import { ActionDialogProperty } from "shared/ui";

import Divider from "@mui/material/Divider";

import { withTheme } from "@mui/styles";

import Paper from "@mui/material/Paper";

import {
  Icon,
  ToolButton,
  StyledButtonGroup,
} from "shared/ui/ToolBar";

import { ReactComponent as NewFolderIcon } from "@mdi/svg/svg/folder-outline.svg";
import { ReactComponent as NewIcon } from "@mdi/svg/svg/shape-square-rounded-plus.svg";
import { ReactComponent as EditIcon } from "@mdi/svg/svg/pencil.svg";
import { ReactComponent as DelIcon } from "@mdi/svg/svg/delete-outline.svg";
import { ReactComponent as PlayIcon } from "@mdi/svg/svg/television-play.svg";
import { ReactComponent as CloseIcon } from "@mdi/svg/svg/close.svg";
import { ReactComponent as OpenIcon } from "@mdi/svg/svg/open-in-new.svg";
import { ReactComponent as CopyIcon } from "@mdi/svg/svg/content-copy.svg";
import { ReactComponent as IconGrid } from "@mdi/svg/svg/file-table-box-outline.svg";
import { ReactComponent as IconChart } from "@mdi/svg/svg/chart-box-outline.svg";
import { ReactComponent as IconMap } from "@mdi/svg/svg/earth.svg";

import { ReactComponent as CAPIcon } from "shared/icon/logo_nofill.svg";
import { ReactComponent as UpdateCubeIcon } from "@mdi/svg/svg/database-sync.svg";

import ItemDialog from "components/itemDialog";
import { DoubleListDialog } from "shared/multiList/DoubleListDialog";

import Tree from "components/tree";

const ProjectTree = (props) => {
  const [selected, setSelected] = useState(null);
  const [rootId, setRootId] = useState(null);
  const [pendingSelectedID, setPendingSelectedID] = useState(null);
  const [pendingCurrent, setPendingCurrent] = useState(null);

  const roots = useRef([]);

  const api = props.api;
  const projectID = props.projectID;

  useEffect(() => {
    if (!props.current && !selected) return;
    if (props.current?.current.id === selected?.id) return;
    setPendingSelectedID(props.current?.current.id);
  }, [props.current, selected]);

  useEffect(() => {}, [rootId]);

  useEffect(() => {
    if (projectID) {
      setRootId(projectID);

      if (props.app === "localizer") {
        // auto-select item/type
        // api.findItem({ type: 'tr', parent: projectID }).then(res => {
        //   const id = res?.[0]?.current?.id
        //   id && api.findItem({ type: 'i18n', parent: id }).then(res => {
        //     props.setCurrent(res?.[0])
        //   })
        // })
      } else if (props.app === "designer") {
        api.findItems({ type: "root" }).then((res) => {
          let id = res?.[0]?.current?.id;
          id &&
            api.findItems({ nodeType: "Project", parent: id }).then((res) => {
              id = res?.[0]?.current?.id;
              id &&
                api
                  .findItems({ nodeType: "Reports", parent: id })
                  .then((res) => {
                    const queryParams = new URLSearchParams(
                      window.location.search
                    );
                    const report = queryParams.get("report");
                    if (report) {
                      id = res?.[0]?.current?.id;
                      id &&
                        api.item(report).then((res) => {
                          const report = res?.current;
                          const cubeId = report?.properties?.cube?.id;
                          cubeId &&
                            api.item(cubeId).then((res) => {
                              const cube = res;
                              cube?.current?.properties?.slices &&
                                api
                                  .get(cube.current.properties.slices)
                                  .then((res) => {
                                    const slices = res;
                                    slices?.[0]?.current?.properties
                                      ?.components &&
                                      api
                                        .get(
                                          slices[0].current.properties
                                            .components
                                        )
                                        .then((res) => {
                                          const components = res;
                                          components?.[0] &&
                                            props.setCurrent(components[0]);
                                        });
                                  });
                            });
                        });
                    } else {
                      props.setCurrent(res?.[0]);
                    }
                  });
            });
        });
      } else if (props.app === "model") {
      } else if (props.app === "forms") {
        props.setCurrent({ current: { type: "forms", properties: {} } });
      }
    } else setRootId(null);
  }, [projectID]);

  const nodeClicked = (event, rowInfo) => {
    console.log("CLICKED:", rowInfo?.node?.data);
    rowInfo?.node?.data && props.setCurrent(rowInfo.node.data);
  };

  const onProps = (event, rowInfo) => {
    if (!rowInfo?.node?.data) return;
    props.setShowProperties(rowInfo.node.data);
    props.setCurrentProps(rowInfo.node.data);
  };
  const rowHeight = (row) => {
    const fs = [22];
    return fs[Math.min(row.path.length - 1, fs.length - 1)];
  };

  const addDelColumn = async (table) => {
    const add = async (db) => {
      const id = table.id;
      const result = await props.getConfirmation({
        content: (onOk, onClose) => (
          <DoubleListDialog
            onOk={onOk}
            onClose={onClose}
            apiSource={props.db.columns.bind(null, db.current)}
            apiDestination={props.api.get}
            sourceData={table.data.current}
            destinationData={id}
            stopType={null}
            fromLabel={"Database Table Columns"}
            toLabel={"Project Table Columns"}
            title={"Select Columns"}
          />
        ),
        type: "Dialog",
      });
      if (!result) return;
      // по аналогии с inserted
      for (let column of result.removed) {
        if (!column.current) continue;
        const name = column.current.properties.name;
        await api.delete(column.current.id);
        props.enqueueSnackbar(`Поле '${name}' удалено`, { variant: "success" });
      }
      // важен именно этот порядок
      for (let column of result.inserted) {
        if (!column.current) continue;
        const name = column.current.properties.name;
        await api.create(id, "Column", { title: name, name });
        props.enqueueSnackbar(`Поле '${name}' создано`, { variant: "success" });
      }
    };

    const api = props.api;
    api.item(table.data.current.parent).then((db) => {
      db && add(db);
    });
  };

  const addDelTable = async (db) => {
    const id = db.id;
    const result = await props.getConfirmation({
      content: (onOk, onClose) => (
        <DoubleListDialog
          onOk={onOk}
          onClose={onClose}
          apiSource={props.db.tables}
          apiDestination={props.api.get}
          sourceData={db.data.current}
          destinationData={id}
          stopType={null}
          fromLabel={"Database Tables"}
          toLabel={"Project Tables"}
          title={"Select Tables"}
        />
      ),
      type: "Dialog",
    });
    if (!result) return;

    const api = props.api;
    // по аналогии с inserted
    for (let table of result.removed) {
      if (!table.current) continue;
      const name = table.current.properties.name;
      await api.delete(table.current.id);
      props.enqueueSnackbar(`Таблица '${name}' удалена`, {
        variant: "success",
      });
    }

    let lastInsertedId = null;
    // важен именно этот порядок
    for (let table of result.inserted) {
      if (!table.current) continue;
      const name = table.current.properties.name;
      lastInsertedId = await api.create(id, "Table", { title: name, name });
      props.enqueueSnackbar(`Таблица '${name}' создана`, {
        variant: "success",
      });
    }
    setPendingSelectedID(lastInsertedId);
  };

  const showComponent = () => {};
  //!!!  edit and create move to ItemDialog

  const create = async (parent, type, title) => {
    const result = await props.getConfirmation({
      content: (onOk, onClose) => (
        <ItemDialog
          title={title}
          api={props.api}
          isCreate={true}
          parent={parent?.data}
          type={type}
          onOk={onOk}
          onClose={onClose}
          prop={null}
          selfOrParentId={parent ? parent.id : rootId}
        />
      ),
      type: "Dialog",
    });
    result?.id && setPendingSelectedID(result.id);
  };

  const edit = async (node) => {
    await props.getConfirmation({
      content: (onOk, onClose) => (
        <ItemDialog
          api={props.api}
          isEdit={true}
          type={node.data.current.type}
          onOk={onOk}
          onClose={onClose}
          prop={node.data.current.properties}
          selfOrParentId={node.id}
          self={node.data}
        />
      ),
      type: "Dialog",
    });
  };

  const updateCube = async (node) => {
    const api = props.api;
    const id = node.id;

    const res = await api.cubeData(id);
    console.log(res);
  };

  const updateCubeTEST = async (node) => {
    const api = props.api;
    const id = node.id;

    const res = await api.cubeDataTEST(id);
    console.log("updateCubeTEST:", res);
  };

  const remove = async (node) => {
    const title = node.data.current.properties.title;
    const confirmed = await props.getConfirmation({
      title: "Удаление",
      text: `Вы действительно хотите удалить '${title}'`,
      width: "sm",
      type: "Delete",
    });
    if (!confirmed) return;

    const api = props.api;
    api.delete(node.id);
    props.enqueueSnackbar(`Элемент '${title}' удален`, { variant: "success" });
  };

  const copy = async (node) => {
    const title = node.data.current.properties.title;
    const confirmed = await props.getConfirmation({
      title: "Копирование",
      text: `Вы действительно хотите сделать копию '${title}'`,
      width: "sm",
      type: "Warning",
    });
    if (!confirmed) return;

    const api = props.api;
    api.copy(node.id);
    props.enqueueSnackbar(`Коптя '${title}' создана`, { variant: "success" });
  };

  const toolbarNodeEdit = (node, key = { key: 0 }) => {
    return [
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
    ];
  };

  const _toolbarFolder = (node, key = { key: 0 }) => {
    return [
      <Divider key={key.key++} orientation={"vertical"} flexItem={true} />,
      ToolButton(
        NewFolderIcon,
        () => {
          create(node, "Folder");
        },
        true,
        "Создать Папку",
        key.key++
      ),
    ];
  };

  const toolbarProject = (node) => {
    let key = { key: 0 };
    return [
      ...toolbarNodeEdit(node, key),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
      ..._toolbarFolder(node, key),
    ];
  };
  const toolbarQuery = (node) => {
    let key = { key: 0 };
    return [
      ...toolbarNodeEdit(node, key),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };
  const toolbarReport = (node) => {
    let key = { key: 0 };
    return [
      ...toolbarNodeEdit(node, key),
      ToolButton(
        UpdateCubeIcon,
        () => {
          updateCube(node);
        },
        node != null,
        "Обновить данные",
        key.key++
      ),
      ToolButton(
        CAPIcon,
        () => {
          updateCubeTEST(node);
        },
        node != null,
        "TEST Обновить данные",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
      <Divider key={key.key++} orientation={"vertical"} flexItem={true} />,
      ToolButton(
        CopyIcon,
        () => {
          copy(node);
        },
        true,
        "Сделать копию",
        key.key++
      ),
      ..._toolbarFolder(node, key),
    ];
  };
  const toolbarDatabases = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "Database");
        },
        true,
        "Создать подключение к базе",
        key.key++
      ),
      ...toolbarNodeEdit(node, key),
      ..._toolbarFolder(node, key),
    ];
  };
  const toolbarQueries = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "Query");
        },
        true,
        "Создать запрос",
        key.key++
      ),
      ...toolbarNodeEdit(node, key),
      ..._toolbarFolder(node, key),
    ];
  };
  const toolbarReports = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "Report");
        },
        true,
        "Создать отчет",
        key.key++
      ),
      ...toolbarNodeEdit(node, key),
      ..._toolbarFolder(node, key),
    ];
  };
  const toolbarMappings = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "Mapping");
        },
        true,
        "Создать сопоставление",
        key.key++
      ),
      ...toolbarNodeEdit(node, key),
      ..._toolbarFolder(node, key),
    ];
  };
  const toolbarClassifiers = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "Classifier");
        },
        true,
        "Создать классификатор",
        key.key++
      ),
      ...toolbarNodeEdit(node, key),
      ..._toolbarFolder(node, key),
    ];
  };
  const toolbarDatabase = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          addDelTable(node);
        },
        node != null,
        "Добавить / Удалить таблицу",
        key.key++
      ),
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
      ..._toolbarFolder(node, key),
    ];
  };
  const toolbarTable = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          addDelColumn(node);
        },
        node != null,
        "Добавить / Удалить поле",
        key.key++
      ),
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
      ..._toolbarFolder(node, key),
    ];
  };

  const toolbarSlices = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "Slice");
        },
        node != null,
        "Создать слайс",
        key.key++
      ),
    ];
  };
  const toolbarSlice = (node) => {
    let key = { key: 0 };
    return [
      ...toolbarNodeEdit(node, key),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };
  const toolbarComponents = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        IconGrid,
        () => {
          create(node, "Component");
        },
        node != null,
        "Создать компоненту",
        key.key++
      ),
      ToolButton(
        IconChart,
        () => {
          create(node, "Chart");
        },
        node != null,
        "Создать график",
        key.key++
      ),
      ToolButton(
        IconMap,
        () => {
          create(node, "Map");
        },
        node != null,
        "Создать карту",
        key.key++
      ),
    ];
  };
  const toolbarComponent = (node) => {
    let key = { key: 0 };
    return [
      ...toolbarNodeEdit(node, key),
      ToolButton(
        PlayIcon,
        () => {
          showComponent(node);
        },
        node != null,
        "Показать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };
  const toolbarFacts = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ..._toolbarFolder(node, key),
    ];
  };
  const toolbarFact = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };

  const toolbarDim = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };

  const toolbarMapping = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };

  const openAsRoot = (node) => {
    roots.current.push(rootId);
    setRootId(node.id);
  };

  const closeRoot = (node) => {
    const id = roots.current.pop();
    setRootId(id);
  };

  const tb_software_product = (node) => {
    let key = { key: 0 };
    const isRoot = node?.id === rootId;
    const tb = [];

    tb.push(
      ToolButton(
        NewIcon,
        () => {
          create(node, "application", "Создать приложение");
        },
        node != null,
        "Создать приложение",
        key.key++
      )
    );
    tb.push(
      <Divider key={key.key++} orientation={"vertical"} flexItem={true} />
    );

    if (isRoot) {
      tb.push(
        ToolButton(
          EditIcon,
          () => {
            edit(node);
          },
          node != null,
          "Редактировать",
          key.key++
        )
      );
    } else {
      tb.push(
        ToolButton(
          OpenIcon,
          () => {
            openAsRoot(node);
          },
          node != null,
          "Открыть для редактирования",
          key.key++
        )
      );
    }
    tb.push(
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      )
    );
    if (isRoot) {
      tb.push(
        <Divider key={key.key++} orientation={"vertical"} flexItem={true} />
      );
      tb.push(
        ToolButton(
          CloseIcon,
          () => {
            closeRoot();
          },
          node != null,
          "Закрыть",
          key.key++
        )
      );
    }
    return tb;
  };

  const tb_software_products = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "software_product", "Создать продукт");
        },
        node != null,
        "Создать продукт",
        key.key++
      ),
    ];
  };

  const tb_application = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "application", "Создать приложение");
        },
        node != null,
        "Создать приложение",
        key.key++
      ),
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };

  const tb_module = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "module", "Создать модуль");
        },
        node != null,
        "Создать модуль",
        key.key++
      ),
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };

  const tb_class = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };

  const tb_properties = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "property", "Создать свойство");
        },
        node != null,
        "Создать свойство",
        key.key++
      ),
    ];
  };

  const tb_operations = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        NewIcon,
        () => {
          create(node, "operation", "Создать операцию");
        },
        node != null,
        "Создать операцию",
        key.key++
      ),
    ];
  };

  const tb_property = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };

  const tb_operation = (node) => {
    let key = { key: 0 };
    return [
      ToolButton(
        EditIcon,
        () => {
          edit(node);
        },
        node != null,
        "Редактировать",
        key.key++
      ),
      ToolButton(
        DelIcon,
        () => {
          remove(node);
        },
        node != null,
        "Удалить",
        key.key++
      ),
    ];
  };

  const toolbar = () => {
    const node = selected;
    if (!node) return [ToolButton(NewIcon, () => {}, false, null, 0)];

    const type = node?.data?.current?.type;
    switch (type) {
      //      case 'Folder': return ???(node)

      case "Project":
        return toolbarProject(node);

      case "Databases":
        return toolbarDatabases(node);
      case "Database":
        return toolbarDatabase(node);
      case "Table":
        return toolbarTable(node);

      case "Query":
        return toolbarQuery(node);
      case "Queries":
        return toolbarQueries(node);

      case "Reports":
        return toolbarReports(node);
      case "Report":
        return toolbarReport(node);

      case "Slices":
        return toolbarSlices(node);
      case "Slice":
        return toolbarSlice(node);
      case "Components":
        return toolbarComponents(node);
      case "Component":
        return toolbarComponent(node);

      case "Facts":
        return toolbarFacts(node);
      case "Dim":
        return toolbarDim(node);
      case "Fact":
        return toolbarFact(node);

      case "Mappings":
        return toolbarMappings(node);
      case "Mapping":
        return toolbarMapping(node);
      case "Classifiers":
        return toolbarClassifiers(node);

      case "software_products":
        return tb_software_products(node);
      case "software_product":
        return tb_software_product(node);
      case "application":
        return tb_application(node);
      case "module":
        return tb_module(node);
      case "class":
        return tb_class(node);
      case "properties":
        return tb_properties(node);
      case "operations":
        return tb_operations(node);
      case "property":
        return tb_property(node);
      case "operation":
        return tb_operation(node);

      default:
        console.log("Toolabr for type ", type, "missing");
        return [ToolButton(NewIcon, () => {}, false, null, 0)];
    }
  };

  const pinned = false;
  const tb = []; //toolbar()
  // const node = selected
  const theme = props.theme;
  const size = "1.85em";
  const tabIconStyle = {
    fontSize: "1em",
    display: "block",
    objectFit: "contain",
    margin: "0.25em 0 0.25em 0.25em",
    cursor: "pointer",
    maxWidth: size,
    maxHeight: size,
    minWidth: size,
    minHeight: size,
    width: "auto",
    height: "auto",
    fill: theme.palette.menu.icon,
  };
  const properties = {
    contentStyle: { padding: "0", display: "flex", flexDirection: "column" },
    open: true,
    onClose: props.closeTree,
    closable: false,
  };

  return (
    rootId && (
      <ActionDialogProperty {...properties}>
        <Paper
          square
          elevation={0}
          style={{
            width: "100%",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            background: "white"
          }}
        >
          {pinned && (
            <div style={{ padding: "0.5em", display: "flex" }}>
              {Icon("action", CAPIcon, 32, null, {}, {}, "0 0 110 122")}
              <span
                style={{
                  color: props.theme.palette.primary.main,
                  flexGrow: 1,
                  textAlign: "center",
                  fontSize: "1.2em",
                  alignSelf: "center",
                }}
              >
                Цифровая аналитическая платформа
              </span>
            </div>
          )}
          {pinned && <Divider />}

          <div
            style={{ flexGrow: 1, display: "flex", flexDirection: "column", padding: "16px 0" }}
          >
            {tb.length > 0 && <StyledButtonGroup>{tb}</StyledButtonGroup>}
            <Tree
              api={props.api}
              itemChanged={props.itemChanged}
              rootId={rootId}
              rowHeight={40}
              iconSize={28}
              onClicked={nodeClicked}
              onProps={onProps}
              onSelected={(node) => {
                setSelected(node);
                props.setCurrent(node?.data);
              }}
              current={props.current}
              path={props.path}
              pendingSelectedID={pendingSelectedID}
              pendingCurrent={pendingCurrent}
            />
          </div>
        </Paper>
      </ActionDialogProperty>
    )
  );
};

const mapStateToProps = function (state) {
  return {
    app: state.APP,
    api: state.API,
    projectID: state.projectID,

    itemChanged: state.itemChanged,
    current: state.current,
    currentProps: state.currentProps,
    path: state.path,

    db: state.databaseAPI,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setShowProperties: (data) =>
      dispatch({ type: "SET_PROPERTIES_DATA", value: data }),
    setCurrent: (node) => dispatch({ type: "SET_CURRENT", value: node }),
    setCurrentProps: (node) =>
      dispatch({ type: "SET_CURRENT_PROPS", value: node }),
  };
};

export default withSnackbar(
  withTheme(
    withConfirmationDialog(
      connect(mapStateToProps, mapDispatchToProps)(ProjectTree)
    )
  )
);
