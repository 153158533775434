import React from "react";
import styled from "styled-components";
import { ReactComponent as GreenStatusDot } from "../icons/greenStatusDot.svg";

function StatusDate() {
  return (
    <DescriptionContainer>
      <DescriptionBlock>
        <Description>
          <Notation>Статус</Notation>
          <Status>
            <GreenStatusDot />
            Утверждено
          </Status>
        </Description>
        <Description>
          <Notation>Дата и время подписания</Notation>
          <div>12.01.2023 10:34</div>
        </Description>
      </DescriptionBlock>
    </DescriptionContainer>
  );
}

const DescriptionContainer = styled("div")`
  display: flex;
  flex-direction: column;
  height: 56px;
  padding-left: 48px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DescriptionBlock = styled("div")`
  display: flex;
  height: 56px;
  padding: 8px 16px;
  gap: 60px;
  background: #f7f9fa;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  @media (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    gap: 0;
    height: auto;
  }
`;

const Description = styled("div")`
  display: flex;
  flex-direction: column;
  height: 40px;
  gap: 4px;
`;

const Status = styled("div")`
  display: flex;
  gap: 4px;
`;

const Notation = styled("div")`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7b868c;
`;

export default React.memo(StatusDate);