import React, { Component, Children, cloneElement } from "react";

class FileThemeTreeNodeRenderer extends Component {
  render() {
    const {
      onHover,
      hoverIndex,
      nodeKey,
      onStartDrag,
      onEndDrag,
      treeInDragging,

      children,
      listIndex,
      swapFrom,
      swapLength,
      swapDepth,
      scaffoldBlockPxWidth,
      lowerSiblingCounts,
      connectDropTarget,
      isOver,
      draggedNode,
      canDrop,
      treeIndex,
      treeId, // Delete from otherProps
      getPrevRow, // Delete from otherProps
      nextRow, // Delete from otherProps
      node, // Delete from otherProps
      path, // Delete from otherProps
      rowDirection,
      ...otherProps
    } = this.props;

    return (
      /*connectDropTarget*/ <div {...otherProps}>
        {Children.map(children, (child) =>
          cloneElement(child, {
            onHover,
            hoverIndex,
            nodeKey,
            onStartDrag,
            onEndDrag,
            treeInDragging,

            isOver,
            canDrop,
            draggedNode,
            lowerSiblingCounts,
            listIndex,
            swapFrom,
            swapLength,
            swapDepth,
          })
        )}
      </div>
    );
  }
}

FileThemeTreeNodeRenderer.defaultProps = {
  swapFrom: null,
  swapDepth: null,
  swapLength: null,
  canDrop: false,
  draggedNode: null,
};

export default FileThemeTreeNodeRenderer;
