import React from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import cookies from "react-cookies";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

import LoginForm from "components/LoginForm";
import SessionErrorModal from "components/SessionErrorModal";
import { useConfirmationDialog } from "shared/ui/ConfirmationDialog";
import { TooltipR } from "shared/ui/ToolBar";

import { ReactComponent as AccountIcon } from "./IconsSvg/AccountIcon.svg";
import { ReactComponent as LogoutIcon } from "./IconsSvg/LogoutIcon.svg"

import { ReactComponent as BurgerIcon } from "./IconsSvg/Burger.svg"
import { ReactComponent as MailIcon } from "./IconsSvg/Mail.svg"
import { ReactComponent as WebIcon } from "./IconsSvg/Web.svg"
import { ReactComponent as DepartmentIcon } from "./IconsSvg/department.svg"
import { ReactComponent as UserFrameIcon } from "./IconsSvg/UserFrame.svg"

import UserDetail from "./UserDetail";

const INTERNAL_LOGIN = '' !== ('' + process.env.REACT_APP_LOGIN_FORM).trim().replace(/FALSE|NO|OFF|0+/i, '');

function getFullName(user) {
  return `${user.nameLast} ${user.nameFirst ? user.nameFirst[0] + "." : ""}${user.nameMiddle ? user.nameMiddle[0] + "." : ""}`;
}

function getDepartmentName(user) {
  if (!user) return;
  const foundShortDepartmentName = user.userProfileAttributes.find(({ key }) => key === "DEPARTMENT_SHORT_NAME");
  if (foundShortDepartmentName) return foundShortDepartmentName?.value;
  return user.userProfileAttributes.find(({ key }) => key === "DEPARTMENT_NAME")?.value || "";
}

function Header({ onTreeToggle }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { registry } = useParams();
  const confirmationDialog = useConfirmationDialog();

  const api = useSelector(state => state.API);
  const userData = useSelector(state => state.userData);
  const guestData = useSelector(state => state.guestData);

  const [loading, setLoading] = React.useState(false);
  const [loginFormVisible, setLoginFormVisible] = React.useState(false);
  const [userDetailVisibility, setDetailUserVisibility] = React.useState(false);
  const [sessionErrorModalVisibility, setSessionErrorModalVisibility] = React.useState(false);
  
  const handlePageNotFound = React.useCallback(() => {
    confirmationDialog.getConfirmation({
      title: "Страница не найдена",
      text: "Недостаточно прав или страница не существует",
      width: "sm",
      type: "Error",
    });
  }, [confirmationDialog]);

  const openRegistryFromUrl = React.useCallback((tree) => {
    if (!registry || !Array.isArray(tree)) return;

    const foundRegistry = tree.find((item) => item.id === registry);
    if (!foundRegistry) {
      handlePageNotFound();
      return;
    }

    dispatch({ type: "SET_CURRENT", value: { current: foundRegistry } });
  }, [dispatch]);

  const loadUserData = React.useCallback((token, enterRequest, onFail) => {
    setLoading(true);
    api.setToken(token);
    api.send(`user/${enterRequest ? "enter" : "profile"}`)
      .then(async res => {
        if (!res || !res?.userProfile) {
          cookies.remove("login", { path: process.env.PUBLIC_URL + "/" });
          cookies.remove("password", { path: process.env.PUBLIC_URL + "/" });
          cookies.remove("sessionToken", { path: process.env.PUBLIC_URL + "/" });
          localStorage.removeItem("session_token");
          if (onFail) return onFail();
          openRegistryFromUrl(api.tree);
          return;
        }

        if (enterRequest && res.biportal.code) localStorage.setItem("code", res.biportal.code);
        if (enterRequest && res.biportal.session) localStorage.setItem("session", res.biportal.session);

        await api.loadTree();
        window.dispatchEvent(new Event("TREE_RESET"));
        dispatch({ type: "SET_USER_DATA", value: res });
        setTimeout(() => openRegistryFromUrl(api.tree), 50);
      })
      .finally(() => setLoading(false));
  }, [api, dispatch]);

  const resetMenu = React.useCallback(async () => {
    api.setToken(null);
    await api.loadTree();
    window.dispatchEvent(new Event("TREE_RESET"));
  }, [api]);

  const loginAsGuest = React.useCallback(async (skip) => {
    const loginRes = await api.send("user/login", { login: process.env.REACT_APP_EMIAP_GUEST_LOGIN, password: process.env.REACT_APP_EMIAP_GUEST_PASSWORD })
    if (!loginRes.sessionToken) return resetMenu();
    api.setToken(loginRes.sessionToken);
    const enterRes = await api.send("user/enter")
    if (!enterRes || !enterRes?.userProfile) return resetMenu();
    dispatch({ type: "SET_GUEST_DATA", value: enterRes });
    await api.loadTree();
    window.dispatchEvent(new Event("TREE_RESET"));
    if (skip) setTimeout(() => openRegistryFromUrl(api.tree), 50);
  }, [api, dispatch, resetMenu, openRegistryFromUrl]);

  React.useEffect(() => {
    if (!api) return;

    const token = cookies.load("sessionToken");
    if (token) {
      loadUserData(token, false, loginAsGuest);
      return;
    }

    const login = cookies.load("login");
    const password = cookies.load("password");
    if (!INTERNAL_LOGIN || !login || !password) {
      loginAsGuest();
      if (INTERNAL_LOGIN) setLoginFormVisible(true);
      return;
    }

    setLoading(true);
    api.send("user/login", { login, password })
      .then(res => {
        if (!res || !res?.sessionToken) {
          cookies.remove("login", { path: process.env.PUBLIC_URL + "/" });
          cookies.remove("password", { path: process.env.PUBLIC_URL + "/" });
          loginAsGuest();
          setLoginFormVisible(true);
          return;
        }
        localStorage.setItem("session_token", `"${res.sessionToken}"`);
        cookies.save("sessionToken", res.sessionToken, { path: process.env.PUBLIC_URL + "/" });
        loadUserData(res.sessionToken, true);
      })
      .finally(() => setLoading(false));
  }, [api, dispatch]);

  const handleMailClick = React.useCallback(() => window.location.href = "mailto:techsupport@q-pol.ru", []);
  const handleWebClick = React.useCallback(() => window.open("https://q-pol.itsm365.com", "_blank"), []);

  const handleLoginClick = React.useCallback(() => {
    if (INTERNAL_LOGIN) {
      setLoginFormVisible(true);
      return;
    }
    window.open(process.env.REACT_APP_EMIAP_LOGIN_URL, "_self")
  }, []);

  const handleLogoutClick = React.useCallback(async () => {
    if (!api) return;
    setLoading(true);
    dispatch({ type: "SET_CURRENT", value: null });
    dispatch({ type: "SET_PAGES", value: [] });
    navigate(process.env.PUBLIC_URL + "/");
    await api.send("user/logout")
    api.setToken(null);
    dispatch({ type: "SET_USER_DATA", value: null });
    cookies.remove("sessionToken", { path: process.env.PUBLIC_URL + "/" });
    cookies.remove("login", { path: process.env.PUBLIC_URL + "/" });
    cookies.remove("password", { path: process.env.PUBLIC_URL + "/" });
    localStorage.removeItem("code");
    localStorage.removeItem("session");
    localStorage.removeItem("session_token");
    await loginAsGuest(false);
    setLoading(false);
  }, [api, dispatch, navigate, loginAsGuest]);
  
  React.useEffect(() => {
    const interval = parseInt(process.env.REACT_APP_CHECK_USER_SESSION_INTERVAL);
    if (!api || isNaN(interval) || interval === 0 || !userData) return;
    const clear = setInterval(async () => {
      const res = await api.send("user/check");
      if (res?.ok) return;
      handleLogoutClick();
      setSessionErrorModalVisibility(true);
    }, interval * 1000);
    return () => clearInterval(clear);
  }, [api, guestData, handleLogoutClick, userData]);

  const handleDetailOpen = React.useCallback(() => setDetailUserVisibility(true), [])
  
  const department = getDepartmentName(userData?.userProfile);

  return (
    <StyledPaper
      square
      elevation={0}
      style={{
        zIndex: 10,
        alignItems: "center",
        ...theme.typography.caption,
        color: theme.palette.header.text,
        display: "flex",
        minHeight: 72,
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <HeaderFrameLeft style={{ display: "flex", alignItems: "center" }}>
        <MenuButton onClick={onTreeToggle}>
          <BurgerIcon />
        </MenuButton>
        <TitleContainer>
          <LogoName>ГИС ЦАП</LogoName>
          {!!department && (
            <>
              <div style={{ width: 1, height: 24, background: "#DCE3E5" }} />
              <DepartmentContainer>
                <DepartmentIcon />
                <DepartmentLogoText>{department}</DepartmentLogoText>
              </DepartmentContainer>
            </>
          )}
        </TitleContainer>
      </HeaderFrameLeft>
      <HeaderFrameRight> 
        <InfoBlock>
          <div style={{ fontSize: "12px", fontWeight: 400, lineHeight: "16px", color: "rgba(123, 134, 140, 1)"}}>Техническая поддержка</div>
          <div style={{ fontSize: "16px", fontWeight: 400, lineHeight: "20px", color: "rgba(66, 71, 77, 1)"}}>8 800 350-10-77</div>
        </InfoBlock>
        <ButtonsBlock>
          <TooltipR text="techsupport@q-pol.ru">
            <ButtonContact onClick={handleMailClick}>
              <MailIcon /> 
            </ButtonContact>
          </TooltipR>
          <TooltipR text="https://q-pol.itsm365.com">
            <ButtonContact onClick={handleWebClick}>
              <WebIcon />
            </ButtonContact>
          </TooltipR>
        </ButtonsBlock>
        {
          loading ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 60 }}>
              <CircularProgress size={16} color="primary" />
            </div>
          ) : userData ? (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => {
                return (
                  <>
                    <UserMenu>
                      <div style={{ display: "flex", flexDirection: "column", marginRight:"16px"}}>
                        <div style={{ fontSize: "13px", color: "rgba(44, 48, 52, 1)", fontWeight: 600, lineHeight: "20px" }}>{getFullName(userData.userProfile)}</div>
                        {!!userData.userProfile.snils && (
                          <div style={{ fontSize: 12, lineHeight: "16px", color: "rgba(123, 134, 140, 1)", whiteSpace: "nowrap", textAlign: "right", maxWidth: 250 }}>
                            {userData.userProfile.snils}
                          </div>
                        )}
                      </div>
                      <UserFrame {...bindTrigger(popupState)} style={popupState.isOpen ? { background: 'rgba(204, 214, 217, 1)' } : {}} >
                        <UserFrameIcon></UserFrameIcon>
                      </UserFrame>
                    </UserMenu>
                    <Menu
                      {...bindMenu(popupState)}
                      autoFocus={false}
                      style={theme.typography.text}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <StyledMenuItem
                        value={0}
                        onClick={() => {popupState.close(); handleDetailOpen();}}
                      >
                        <AccountIcon />
                        <div>Профиль пользователя</div>
                      </StyledMenuItem>
                      <StyledMenuItem
                        value={0}
                        onClick={() => {popupState.close(); handleLogoutClick();}}
                      >
                        <LogoutIcon />
                        <div>Выйти из системы</div>
                      </StyledMenuItem>
                    </Menu>
                  </>
                )
              }}
            </PopupState>
          ) : (
            <UserFrameLogin onClick={handleLoginClick} large>
              Войти
            </UserFrameLogin>
          )
        }
      </HeaderFrameRight>
      {sessionErrorModalVisibility && <SessionErrorModal onClose={() => setSessionErrorModalVisibility(false)} openAuth={handleLoginClick} />}
      {userDetailVisibility && <UserDetail onClose={() => setDetailUserVisibility(false)} />}
      {loginFormVisible && <LoginForm isLoading={loading} loadUserData={loadUserData} setLoading={setLoading} onClose={() => setLoginFormVisible(false)} />}
    </StyledPaper>
  );
};

const HeaderFrameLeft = styled("div")`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const LogoName = styled("div")`
  color: rgba(23, 109, 239, 1);
  line-height: 24px;
  font-size: 18px;
  font-weight: 900;
  font-family: 'Safia Sans', 'Golos', sans-serif;
`;

const DepartmentContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DepartmentLogoText = styled("div")`
  color: #42474D;
  line-height: 20px;
  font-size: 14px;
`;

const InfoBlock = styled("div")`
  display: flex;
  flex-direction: column;
`;

const ButtonsBlock = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const ButtonContact = styled.button`
  width: 40px;
  height: 40px;
  padding: 12px;
  background: transparent;
  border-radius: 12px;
  border: 1px solid #EDF1F2;
  display: inline-flex;
  cursor: pointer;
  transition: background 0.15s ease-in-out;

  svg {
    width: 16px
    height: 16px;
  }

  &:hover {
    background: #EDF1F2;
  }
`;

const StyledPaper = styled(Paper)`
  height: 73px;
  background-size: cover;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 20px 0px rgba(185, 207, 223, 0.24);

  @media (max-width: 768px) {
    height: 48px;
  }
`;

const MenuButton = styled("button")`
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: rgba(255, 255, 255, 1);
  cursor: pointer;
  transition: background 0.15s ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

const UserFrame = styled("button")`
  width: 40px ;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: background 0.15s ease-in-out;
  cursor: pointer;
  border: 0;
  background: "rgba(237, 241, 242, 1)"; 
  
  &:hover {
    background: rgba(204, 214, 217, 1);
  }

  &:active {
    background: rgba(204, 214, 217, 1);
  }
`;

const UserFrameLogin = styled("button")`
  width: 60px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: background 0.15s ease-in-out;
  cursor: pointer;
  color: rgba(66, 71, 77, 1);
  background: transparent;
  border: 1px solid rgba(237, 241, 242, 1);
  
  &:hover {
    background: rgba(237, 241, 242, 1);
  }
`;

const StyledMenuItem = styled(MenuItem)`
  background: transparent;
  transition: background 0.15s ease-in-out;
  font-size: 14px;
  color: #42474D;
  display: flex;
  gap: 8px;

  svg {
    fill: #7B868C;
    transition: fill 0.15s ease-in-out;
  }

  &:hover {
    background: #EDF1F2;
    svg {
      fill: #42474D;
    }
  }
`;

const HeaderFrameRight = styled("div")`
  margin-right: 24px;
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const IconButtonBox = styled("button")`
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.15s ease-in-out;
  background: transparent;
  border: 0;

  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
  }

  &:hover {
    background: rgba(130, 186, 255, 0.04);
  }

  &:active {
    background: rgba(130, 186, 255, 0.08);
  }
`;

const UserMenu = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default React.memo(Header);
