import React from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

import { ActionDialog, ContourBackdrop } from "shared/ui";

function ShowDataDownload({ onOk, code, registry }) {
  const api = useSelector((state) => state.API);

  const [loading, setLoading] = React.useState(true);
  const [protocolData, setProtocolData] = React.useState({});

  React.useEffect(() => {
    api
      .send("registries/protocol/json", { code, registry })
      .then((res) => {
        if (!res?.header || !res?.data) {
          onOk();
          return;
        }

        setProtocolData(res);
      })
      .finally(() => setLoading(false));
  }, [api, code, registry, onOk]);

  const handleSave = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_API + "/registries/protocol", {
        method: "POST",
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({ code, registry, token: api.token }),
      });

      if (!response.ok) throw new Error("Problem with response");

      const blob = await response.blob();
      const a = document.createElement("a");
      a.download = "protocol.csv";
      a.href = URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }, [api, code, registry]);

  if (loading) return <ContourBackdrop zIndex={100000} />;

  return (
    <ActionDialog
      minHeight="60vh"
      onClose={onOk}
      open
      title={protocolData.data.length !== 0 ? `Просмотр протокола записи (${protocolData.data[0].ext_code})` : "Просмотр протокола записи"}
    >
      <div style={{ display: "flex", flex: 1, flexDirection: "column", overflow: "hidden", maxHeight: "100%" }}>
        <div style={{ display: "flex", flex: 1, overflow: "auto", border: '1px solid #ccc', borderRadius: '2px' }}>
          <table style={{ borderCollapse: "collapse", marginBottom: "30px", marginTop: "15px", borderSpacing: "0" }}>
            <thead>
              <tr>
                {Object.values(protocolData.header).map((value, index) => (
                  <th key={index} style={{ border: "1px solid #000", textAlign: "center" }}>
                    {value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {protocolData.data.map((rowData, index) => (
                <tr key={index}>
                  {Object.values(rowData).map((value, subIndex) => (
                    <td key={subIndex} style={{ border: "1px solid #000", wordBreak: "break-word", minWidth: 40 }}>
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ display: "flex", justifyContent: "right", marginTop: 16 }}>
          <Button disabled={protocolData.data.length === 0} onClick={handleSave} variant="contained">
            Скачать
          </Button>
        </div>
      </div>
    </ActionDialog>
  );
}

export default React.memo(ShowDataDownload);
