export const staticSelect = (name, label, list, defaults) => ({
  value: (data, getSet, object, value) => {
    if (getSet === "get") {
      return data[list].find((i) => i.current.id === object[name]);
    }
    object[name] = value?.id;
  },
  type: "select",
  rootId: null,
  label,
  default: { id: defaults },
  api: (value, data) => ({
    get: (parent) =>
      new Promise((resolve, reject) => {
        resolve(data[list].filter((i) => i.current.parent == parent));
      }),
    item: (id) =>
      new Promise((resolve, reject) => {
        resolve(data[list].find((i) => i.current.id === id));
      }),
    path: (id) =>
      new Promise((resolve, reject) => {
        const result = [];
        while (id) {
          const element = data[list].find((i) => i.current.id === id);
          if (element) {
            result.push(element);
          }
          id = element?.current.parent;
        }
        result.reverse();
        resolve(result);
      }),
  }),
});
