import React from "react";
import styled from "styled-components";

import { HomeButton } from "../Blocks/Components";

import { ReactComponent as InfoIcon } from "./info.svg";
import { ReactComponent as WarningIcon } from "./warning.svg";

const LAYOUT_DATA = [
  {
    type: "warning",
    date: "12.08.2023 14:24",
    title: "Технические работы",
    active: true,
    body: "С 15.06.23 12:00 по 15.06.23 16:00 будут проводиться технические работы в инструменте Формирования заявок на регистрацию. Приложение будет недоступно. Просим учесть это в своей работе.",
  },
  {
    type: "info",
    date: "12.08.2023 12:21",
    title: "Уведомление",
    body: "В методических материалах обновлено руководство пользователя для Формирования заявок на регистрацию",
  },
  {
    type: "info",
    date: "2.07.2023 9:16",
    title: "Уведомление",
    active: true,
    body: "Выполнено обновление инструмента Формирования заявок на регистрацию. Со списком изменений можно ознакомиться по ссылке.",
  },
  {
    type: "info",
    date: "21.08.2023 10:21",
    title: "Обновление",
    body: "С 14.06.23 02:00 по 14.06.23 06:00 будет проводиться обновление инструмента Формирования заявок на регистрацию. Приложение будет недоступно. Просим учесть это в своей работе.",
  },
];

function NotificationsView() {
  return (
    <Container>
      <HeaderContainer>
        <HeaderTitle>Уведомления</HeaderTitle>
        <HomeButton>Посмотреть все</HomeButton>
      </HeaderContainer>
      {LAYOUT_DATA.map((notification, index) => (
        <Notification active={notification.active} key={index}>
          <NotificationHeader>
            {notification.type === "info" ? <InfoIcon /> : <WarningIcon />}
            <NotificationTitle>{notification.title}</NotificationTitle>
            {notification.active && <ActiveIndicator />}
          </NotificationHeader>
          <NotificationBody>{notification.body}</NotificationBody>
          <NotificationDate>{notification.date}</NotificationDate>
        </Notification>
      ))}
    </Container>
  );
}

const Container = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #dce3e5;
`;

const HeaderContainer = styled("div")`
  display: flex;
  height: 56px;
  padding: 0 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dce3e5;
`;

const HeaderTitle = styled("div")`
  color: #2c3034;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

const Notification = styled("div")`
  padding: 12px 24px;
  border-bottom: 1px solid #dce3e5;
  background: ${({ active }) => (active ? "#F7F9FA" : "white")};
`;

const NotificationTitle = styled("div")`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #2c3034;
  flex: 1;
`;

const ActiveIndicator = styled("div")`
  width: 12px;
  height: 12px;
  background: #176def;
  border-radius: 50%;
`;

const NotificationHeader = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const NotificationBody = styled("div")`
  margin: 4px 0 8px 0;
  color: #42474d;
  font-size: 13px;
  line-height: 20px;
`;

const NotificationDate = styled("div")`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7b868c;
`;

export default React.memo(NotificationsView);
