import React from "react";
import styled from "styled-components";

import { fDate, fSelect } from "shared/ui/Editors";

function Filters() {
  const [filterValue, setFilterValue] = React.useState({});

  const handleFiltersChange = React.useCallback((info, propertyName, event, value) => {
    setFilterValue((filters) => ({
      ...filters,
      [propertyName]: value,
    }));
  }, []);

  const filterInputsProps = React.useMemo(
    () => ({
      disabled: false,
      onChange_v2: handleFiltersChange,
      value: filterValue,
      style: "compact",
    }),
    [handleFiltersChange, filterValue]
  );

  return (
    <FiltersContainer>
      {fDate(filterInputsProps, "Дата загрузки", "from-upload", null, { autoSetValue: false })}
      -
      {fDate(filterInputsProps, "Дата загрузки", "to-upload", null, { autoSetValue: false })}
      {fSelect(filterInputsProps, "Статус загрузки", "status", null, {
        select: [
          { id: "st-1", name: "Статус 1" },
          { id: "st-2", name: "Статус 2" },
        ],
        onlyIdInValue: true,
        disableClearable: false
      })}
    </FiltersContainer>
  );
}

const FiltersContainer = styled("div")`
  display: flex;
  gap: 10px;
  padding: 0.75em;
  align-items: center;

  & > div {
    width: auto !important;
    min-width: 200px;
  }
`;

export default React.memo(Filters);
