import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import { Checkbox, FormControlLabel, Radio, TextField } from "@mui/material";

import ImageUploader from "components/Survey/newComponents/ImageUploader";
import DatePicker from "components/Survey/newComponents/DatePicker";
import FileUploader from "components/Survey/newComponents/FileUploader";

import { QuizStorage } from "components/Survey/storages/QuizStorage";
import {
  isReferenceSelected,
  getTextFieldValue,
  validateTextFieldValue,
  validateQuestionRule,
} from "components/Survey/storages/QuizStorage/utils";
import { TooltipR } from "shared/ui/ToolBar";

import { ReactComponent as ErrorIcon } from "@mdi/svg/svg/information-outline.svg";

function SingleQuestion() {
  const currentQuestion = QuizStorage.currentQuestion;

  React.useEffect(() => {
    if (currentQuestion.isQuestionGroup) return;
    if (
      currentQuestion.answer.Reference.length > 1 ||
      !currentQuestion.question.other_allowed ||
      currentQuestion.question.type ||
      isReferenceSelected(currentQuestion.answer.Reference[0].code, currentQuestion.question)
    )
      return;
    QuizStorage.selectAnswer(1, currentQuestion.question);
  }, [currentQuestion]);

  const handleOtherTypeChange = React.useCallback((value: string | null) => QuizStorage.selectOtherTypeAnswer(value), []);

  if (currentQuestion.isQuestionGroup) return null;

  const currentAnswer = QuizStorage.answers[currentQuestion.question.code];
  const isOtherVariantOnly = currentQuestion.answer.Reference.length === 1;
  const FormControl = currentQuestion.question.multiple_values ? Checkbox : Radio;

  if (currentQuestion.question.type === "File")
    return (
      <QuestionContainer>
        <FileUploader value={currentAnswer.otherTypeAnswers} onChange={handleOtherTypeChange} />
      </QuestionContainer>
    );

  if (currentQuestion.question.type === "Image")
    return (
      <QuestionContainer>
        <ImageUploader value={currentAnswer.otherTypeAnswers} onChange={handleOtherTypeChange} />
      </QuestionContainer>
    );

  if (currentQuestion.question.type === "Date")
    return (
      <QuestionContainer>
        <DatePicker value={currentAnswer.otherTypeAnswers} onChange={handleOtherTypeChange} />
      </QuestionContainer>
    );

  return (
    <QuestionContainer>
      {currentQuestion.answer.Reference.map((reference) => {
        const isSelected = isReferenceSelected(reference.code, currentQuestion.question);
        const error = !validateQuestionRule(currentQuestion.question);

        if (reference.input_type) {
          return (
            <div key={reference.code} style={{ display: "flex", alignItems: "center" }}>
              {!isOtherVariantOnly && (
                <FormControlLabel
                  label={<span dangerouslySetInnerHTML={{ __html: currentQuestion.question.other_text! }} />}
                  control={
                    <FormControl
                      color="primary"
                      size="small"
                      checked={isSelected}
                      onChange={() => QuizStorage.selectAnswer(reference.code, currentQuestion.question)}
                    />
                  }
                />
              )}
              <TextField
                value={getTextFieldValue(reference, currentQuestion.question)}
                placeholder={isOtherVariantOnly ? currentQuestion.question.other_text : ""}
                disabled={!isSelected}
                variant="standard"
                onChange={(event) => {
                  const value = validateTextFieldValue(currentQuestion.question, event.target.value);
                  QuizStorage.changeTextAnswer({ code: reference.code, value }, currentQuestion.question);
                }}
                style={{
                  minWidth: 200,
                  maxWidth: isOtherVariantOnly ? "unset" : 500,
                  marginLeft: isOtherVariantOnly ? 0 : 10,
                  flex: "1",
                }}
              />
              {error && (
                <TooltipR text={currentQuestion.question.error_message}>
                  <ErrorIcon fill="#d32f2f" />
                </TooltipR>
              )}
            </div>
          );
        }

        return (
          <div>
            <FormControlLabel
              key={reference.code}
              label={<span dangerouslySetInnerHTML={{ __html: reference.value }} />}
              control={
                <FormControl
                  color="primary"
                  size="small"
                  checked={isSelected}
                  onChange={() => QuizStorage.selectAnswer(reference.code, currentQuestion.question)}
                />
              }
            />
            {error && (
              <TooltipR text={currentQuestion.question.error_message}>
                <ErrorIcon fill="#d32f2f" />
              </TooltipR>
            )}
          </div>
        );
      })}
    </QuestionContainer>
  );
}

const QuestionContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 10px;
  word-break: break-word;
`;

export default observer(SingleQuestion);
