export const cellsRenderer = (
  forceUpdate,
  classes,
  data,
  topLeft,
  visible,
  cellRenderer
) => {
  if (!data) return [];

  const m = [];

  const cellStyle = {
    position: "absolute",
    left: 0,
    top: 0,
    maxWidth: 180,
    minHeight: 40,
    width: "max-content",
    height: "auto"
  };

  const top = topLeft.top;
  const left = topLeft.left;

  const hW = data?.fixedRowCount || 0; //data._axisH.currentWidth()
  const vW = data?.fixedColCount || 0; //data._axisV.currentWidth()

  const xm = vW + data?.colCount; //data._axisH.size()
  const ym = hW + data?.rowCount; //data._axisV.size()

  const render = (y1, y2, x1, x2) => {
    let spanx = 1;
    let spany = 1;
    x2 = Math.min(x2, xm);
    y2 = Math.min(y2, ym);
    for (let k = y1; k < y2; k++) {
      const rowIndex = k;
      for (let i = x1; i < x2; i++) {
        const columnIndex = i;
        const key = `${data.id}:${columnIndex}-${rowIndex}`;
        let coord = null;
        const cell = cellRenderer(
          coord,
          classes,
          data,
          {
            bindData: { ndx: key, spanx, spany },
            columnIndex,
            key,
            rowIndex,
            style: cellStyle,
          },
          forceUpdate
        );
        m.push(cell);
      }
    }
  };
  //    console.log('    Render: ', visible.current)
  render(
    hW + top,
    hW + top + visible.current.row,
    vW + left,
    vW + left + visible.current.column
  );
  render(0, hW, vW + left, vW + left + visible.current.column); // Horizontal axis
  render(hW + top, hW + top + visible.current.row, 0, vW); // Vertical axis
  true && render(0, hW, 0, vW); // Fixed Horizontal axis

  render(-1, 0, vW + left, vW + left + visible.current.column); // Resize Handlers
  true && render(-1, 0, 0, vW); // Fixed Resize Handlers

  return m;
};
