import React from 'react'
import { useDrag, useDrop } from 'react-dnd';

function DropableArea({ onDropHiddenColumn, onStartDrag, onDrop, onHover, updatabled, id }) {
  const ref = React.useRef();

  const [hoverId, setHoverId] = React.useState({ id: null });

  const [, drop] = useDrop({
    accept: "dim",
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.id;
      const hoverIndex = id;
      // const hoverDim = dim;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        // сбрасываем если был
        if (item.hoverDim) {
          item.hoverIndex = null;
          item.hoverDim = null;
          item.hoverPos = null;
          onHover?.(null, null);
        }
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      const hoverPos = hoverClientX < hoverMiddleX;

      if (item.hoverIndex === hoverIndex && item.hoverPos === hoverPos) {
        return;
      }

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.hoverIndex = hoverIndex;
      // item.hoverDim = hoverDim;
      item.hoverPos = hoverPos;

      if (!monitor.canDrop()) {
        return;
      }
      onHover?.(hoverIndex, hoverPos);
    },
    canDrop(item, monitor) {
      return true;
    },
    drop(self, monitor) {
      if (monitor.didDrop()) return;

      if (self.hiddenColumn) {
        self.hiddenColumn.properties.axis = "filter";
        onDropHiddenColumn?.({ dim: self.hiddenColumn });
        return;
      }

      onDrop?.(self.dim, { dropableArea: true }, self.hoverPos);
      return { moved: true };
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "dim",
    item: () => {
      onStartDrag?.(id);
      return { id };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => false,
    end: (item, monitor) => onDrop?.(),
  });
  
  drag(drop(ref));
  
  updatabled && (updatabled[id] = { id, hoverId, setHoverId });

  const backgroundColor = isDragging
    ? "#007bff"
    : hoverId.id === id && hoverId.pos === true
    ? "green"
    : "transparent";

  return (
    <div ref={ref} style={{ flex: 1 }}>
      <div
        style={{
          width: "3px",
          height: 25,
          backgroundColor,
          flexShrink: 0,
        }}
      />
    </div>
  )
}

export default DropableArea