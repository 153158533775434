import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import SurveyPDF from "components/PDF/SurveyPDF";

function SurveyPdfPage() {
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);

  const code = searchParams.get("code");
  const token = searchParams.get("token");

  return <SurveyPDF token={token} code={code} />;
}

export default React.memo(SurveyPdfPage);
