import React from "react";

import { CircularProgress } from "@mui/material";

import { useRemovePdfOverflow } from "../hooks/useRemovePdfOverflow";

import { CenteredText, Container, Divider, TableColumnName, TableCell, DocTable, DocTableRow } from "../components";

function indicatorTableTitle(indicator) {
  switch (indicator.type) {
    case "Series":
      return `Временной ряд «${indicator.label}» (${indicator.table})`;
    case "Reference":
      return `Справочник «${indicator.label}» (${indicator.table})`;
    case "ParentChildClassifier":
      return `Классификатор типа родитель-дитя «${indicator.label}» (${indicator.table})`;
    default:
      return null;
  }
}

function DocGenerator({ onLoadSuccess, api, indicators, preview }) {
  const pageContainer = React.useRef(null);

  const [loading, setLoading] = React.useState(true);
  const [registryError, setRegistryError] = React.useState(false);

  const [indicatorsData, setIndicatorsData] = React.useState([]);

  React.useEffect(() => {
    api
      .send("dwh/indicators", { indicators })
      .then((indicatorsResponse) => {
        if (!Array.isArray(indicatorsResponse)) {
          setRegistryError(indicatorsResponse?.message || true);
          return;
        }
        setIndicatorsData(indicatorsResponse);
        onLoadSuccess?.();
      })
      .finally(() => setLoading(false));
  }, [api, indicators, onLoadSuccess]);

  const removePdfOverflow = useRemovePdfOverflow(pageContainer);

  React.useEffect(() => {
    if (loading || registryError || preview) return;
    removePdfOverflow();
  }, [loading, registryError, preview, removePdfOverflow]);

  if (registryError)
    return (
      <Container>
        <CenteredText>Ошибка в запросе {typeof registryError === "string" && `(${registryError})`}</CenteredText>
      </Container>
    );

  if (loading)
    return (
      <Container>
        <CenteredText>
          <CircularProgress size={32} />
        </CenteredText>
      </Container>
    );

  return (
    <Container ref={pageContainer}>
      {indicatorsData.map((indicator, index) => {
        return (
          <div key={index}>
            <div style={{ fontWeight: 600 }}>{indicatorTableTitle(indicator)}</div>
            <Divider id="divider" medium />
            <DocTable>
              <DocTableRow>
                <TableColumnName>№</TableColumnName>
                <TableColumnName>Поле</TableColumnName>
                <TableColumnName>Название</TableColumnName>
                <TableColumnName>Тип данных</TableColumnName>
                <TableColumnName>Примечание</TableColumnName>
                <TableColumnName>Ссылается на</TableColumnName>
              </DocTableRow>
              {indicator.attr
                .filter((attribute) => attribute.is_public === true)
                .map((attribute, index) => (
                  <DocTableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{attribute.code}</TableCell>
                    <TableCell>{attribute.label}</TableCell>
                    <TableCell>{attribute.datatype}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {attribute.ref_label && attribute.ref_code && `Справочник «${attribute.ref_label}» (${attribute.ref_code})`}
                    </TableCell>
                  </DocTableRow>
                ))}
            </DocTable>
            {indicatorsData.length - 1 !== index && <Divider id="divider" />}
          </div>
        );
      })}
    </Container>
  );
}

export default React.memo(DocGenerator);
