import React from "react";
import Snowfall from "react-snowfall";

function CustomSnowfall() {
  const snowflake1 = document.createElement("img");
  const snowflake2 = document.createElement("img");
  const snowflake3 = document.createElement("img");
  snowflake1.src = process.env.PUBLIC_URL + "/snowflakes/snowflake-1.png";
  snowflake2.src = process.env.PUBLIC_URL + "/snowflakes/snowflake-2.png";
  snowflake3.src = process.env.PUBLIC_URL + "/snowflakes/snowflake-3.png";

  const images = [snowflake1, snowflake2, snowflake3];

  return (
    <Snowfall speed={[0.25, 1]} radius={[8, 16]} wind={[-0.25, 0.25]} snowflakeCount={150} rotationSpeed={[-0.25, 0.25]} images={images} />
  );
}

export default React.memo(CustomSnowfall);
