import React from "react";
import { CircularProgress } from "@mui/material";

import { useRemovePdfOverflow } from "../hooks/useRemovePdfOverflow";

import { CenteredText, Container, Divider, TableColumnName, TableCell, DocTableRow, DocTable } from "../components";

function tableTitle(table) {
  switch (table.type) {
    case "Series":
      return `Временной ряд «${table.label}» (${table.table})`;
    case "Reference":
      return `Справочник «${table.label}» (${table.table})`;
    case "ParentChildClassifier":
      return `Классификатор типа родитель-дитя «${table.label}» (${table.table})`;
    default:
      return null;
  }
}

function DocRegGenerator({ onLoadSuccess, api, code, preview, elementsCallback }) {
  const pageContainer = React.useRef(null);

  const [loading, setLoading] = React.useState(true);
  const [registryError, setRegistryError] = React.useState(false);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    api
      .send("dwh/description", {
        objects: [{ code }],
      })
      .then((res) => {
        if (!Array.isArray(res)) {
          setRegistryError(res?.message || true);
          return;
        }
        setData(res);
        onLoadSuccess?.();
        elementsCallback?.(res.length);
      })
      .finally(() => setLoading(false));
  }, [api, onLoadSuccess, code]);

  const removePdfOverflow = useRemovePdfOverflow(pageContainer);

  React.useEffect(() => {
    if (loading || registryError || preview) return;
    removePdfOverflow();
  }, [loading, registryError, preview, removePdfOverflow]);

  if (registryError)
    return (
      <Container>
        <CenteredText>Ошибка в запросе {typeof registryError === "string" && `(${registryError})`}</CenteredText>
      </Container>
    );

  if (loading)
    return (
      <Container>
        <CenteredText>
          <CircularProgress size={32} />
        </CenteredText>
      </Container>
    );

  return (
    <Container ref={pageContainer}>
      {data.map((table, index) => {
        return (
          <div key={index}>
            <div style={{ fontWeight: 600 }}>{tableTitle(table)}</div>
            <Divider id="divider" medium />
            <DocTable>
              <DocTableRow>
                <TableColumnName>№</TableColumnName>
                <TableColumnName>Поле</TableColumnName>
                <TableColumnName>Название</TableColumnName>
                <TableColumnName>Тип данных</TableColumnName>
                <TableColumnName>Примечание</TableColumnName>
                <TableColumnName>Ссылается на</TableColumnName>
              </DocTableRow>
              {table.attr
                .filter((attribute) => attribute.is_public)
                .map((attribute, index) => (
                  <DocTableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{attribute.code}</TableCell>
                    <TableCell>{attribute.label}</TableCell>
                    <TableCell>{attribute.datatype}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {attribute.ref_label && attribute.ref_code && `Справочник «${attribute.ref_label}» (${attribute.ref_code})`}
                    </TableCell>
                  </DocTableRow>
                ))}
            </DocTable>
            {data.length - 1 !== index && <Divider id="divider" />}
          </div>
        );
      })}
    </Container>
  );
}

export default React.memo(DocRegGenerator);
