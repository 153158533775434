const FILENAME_STORAGE_KEY = "filenames";

class LocalStorageManager {
  get filenames() {
    return JSON.parse(localStorage.getItem(FILENAME_STORAGE_KEY));
  }

  setFilenames(filenames) {
    localStorage.setItem(FILENAME_STORAGE_KEY, JSON.stringify(filenames));
  }

  clearData() {
    localStorage.removeItem(FILENAME_STORAGE_KEY);
  }
}

export const localStorageManager = new LocalStorageManager();
