import React from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import { ContourBackdrop } from "shared/ui";

function ExportIndicator({ onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const api = useSelector(state => state.API);

  const exportIndicator = React.useCallback(async () => {
    const result = await api.call("export/indicators");

    if (!result || !result?.ok) {
      enqueueSnackbar("Операция не выполнена", { variant: "error" });
      onClose();
      return;
    }
        
    enqueueSnackbar("Операция выполнена", { variant: "success" });
    onClose();
  }, [api, enqueueSnackbar]);

  React.useEffect(() => {
    console.log("effect");
    exportIndicator();
  }, [exportIndicator]);

  return <ContourBackdrop zIndex={100000} />;
}

export default React.memo(ExportIndicator);
