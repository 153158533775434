import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import DocReg from "components/PDF/DocRegPage";

function DocRegPage() {
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);
  const api = useSelector((state) => state.API);

  React.useEffect(() => {
    const token = searchParams.get("token");
    if (!api || !token) return;
    api.setToken(token);
  }, [api, searchParams]);

  if (!api) return null;

  const code = searchParams.get("code");

  return <DocReg api={api} code={code} />;
}

export default React.memo(DocRegPage);
