import React from "react";
import styled from "styled-components";
import { Checkbox, Chip } from "@mui/material";

import { Icon } from "shared/ui/ToolBar";

import { ReactComponent as ArrowLeft } from "@mdi/svg/svg/arrow-left.svg";
import { ReactComponent as ArrowRight } from "@mdi/svg/svg/arrow-right.svg";

function TagsList({ tags, value, state, onChange, conditions }) {
  const [selectedTagsReseizeRef, setSelectedTagsReseizeRef] =
    React.useState(null);
  const [selectedTagsRef, setSelectedTagsContainerRef] = React.useState(null);
  const [containerRef, setContainerRef] = React.useState(null);

  const [rightButtonIsHidden, setRightButtonIsHidden] = React.useState(true);
  const [rightButtonIsDisabled, setRightButtonIsDisabled] =
    React.useState(true);
  const [leftButtonIsHidden, setLeftButtonIsHidden] = React.useState(true);
  const [leftButtonIsDisabled, setLeftButtonIsDisabled] = React.useState(true);

  const handleScrollTagsLeft = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const value =
      selectedTagsReseizeRef.scrollLeft > step
        ? selectedTagsReseizeRef.scrollLeft - step
        : 0;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    const maxLeftScroll =
      selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    setLeftButtonIsDisabled(value === 0);
    setRightButtonIsDisabled(value === maxLeftScroll - 1);
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  const handleScrollTagsRight = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const maxLeftScroll =
      selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    const leftScrollSpace =
      maxLeftScroll - 1 - selectedTagsReseizeRef.scrollLeft;
    const value =
      leftScrollSpace > step
        ? selectedTagsReseizeRef.scrollLeft + step
        : maxLeftScroll;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    setLeftButtonIsDisabled(value === 0);
    setRightButtonIsDisabled(value === maxLeftScroll);
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  React.useEffect(() => {
    if (!selectedTagsRef) return;

    const resizeObserver = new ResizeObserver(() => {
      const haveHiddenContent =
        selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
      setLeftButtonIsDisabled(haveHiddenContent);
      setRightButtonIsDisabled(!haveHiddenContent);
      setLeftButtonIsHidden(!haveHiddenContent);
      setRightButtonIsHidden(!haveHiddenContent);
    });

    const unobserve = () => resizeObserver.unobserve(selectedTagsRef);
    resizeObserver.observe(selectedTagsRef);

    const unobserveContainer = () => resizeObserver.unobserve(containerRef);
    resizeObserver.observe(containerRef);

    const haveHiddenContent =
      selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
    setLeftButtonIsDisabled(haveHiddenContent);
    setRightButtonIsDisabled(!haveHiddenContent);
    setLeftButtonIsHidden(!haveHiddenContent);
    setRightButtonIsHidden(!haveHiddenContent);

    return function () {
      unobserve();
      unobserveContainer();
    };
  }, [selectedTagsRef, selectedTagsReseizeRef, containerRef]);

  const handleTagClick = React.useCallback(
    (tag) => {
      const newValue = Array.isArray(value) ? value : [];
      if (newValue.includes(tag)) {
        onChange(newValue.filter((selectedTag) => selectedTag !== tag));
        return;
      }

      onChange([...newValue, tag]);
    },
    [onChange, value]
  );

  const handleConditionChange = React.useCallback(
    (code, value) => onChange(value, code),
    [onChange]
  );

  return (
    <Container ref={setContainerRef}>
      {conditions.map(({ name, code }) => (
        <ConditionContainer>
          {name}
          <Checkbox
            checked={!!state[code]}
            onChange={() => handleConditionChange(code, !state[code])}
          />
        </ConditionContainer>
      ))}
      <TagsListContainer>
        {!leftButtonIsHidden && (
          <CustomChip
            style={{ backgroundColor: "transparent" }}
            variant="outlined"
            disabled={leftButtonIsDisabled}
            onClick={handleScrollTagsLeft}
            label={Icon("action", ArrowLeft, 18, null, { margin: 0 })}
          />
        )}
        <TagsResizeContainer ref={setSelectedTagsReseizeRef}>
          <TagsContainer ref={setSelectedTagsContainerRef}>
            {tags.map((tag) => (
              <CustomChip
                onClick={() => handleTagClick(tag)}
                selected={Array.isArray(value) && value.includes(tag)}
                variant="outlined"
                label={tag}
                key={tag}
              />
            ))}
          </TagsContainer>
        </TagsResizeContainer>
        {!rightButtonIsHidden && (
          <CustomChip
            style={{ backgroundColor: "transparent" }}
            variant="outlined"
            disabled={rightButtonIsDisabled}
            onClick={handleScrollTagsRight}
            label={Icon("action", ArrowRight, 18, null, { margin: 0 })}
          />
        )}
      </TagsListContainer>
    </Container>
  );
}

const Container = styled("div")`
  margin: 0.5em 0.25em;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: hidden;
`;

const ConditionContainer = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid #d7d8d8;
  border-radius: 4px;
  padding: 3px 8px 3px 12px;
  white-space: nowrap;

  .MuiCheckbox-root {
    height: 30px;
    width: 30px;
    padding: 2px;
  }
`;

const TagsListContainer = styled("div")`
  display: flex;
  overflow-x: hidden;
  gap: 8px;
`;

const TagsContainer = styled("div")`
  display: flex;
  gap: 8px;

  .MuiChip-label {
    font-size: 14px;
    padding: 0;
  }
`;

const TagsResizeContainer = styled("div")`
  display: flex;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const CustomChip = styled(Chip)`
  gap: 4px;
  height: 32px;
  padding: 0 12px;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
  background-color: ${({ selected }) =>
    selected ? "rgba(0, 0, 0, 0.05)" : "white"};

  svg {
    margin: 0 !important;
  }
`;

export default React.memo(TagsList);
