import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Doc from "components/PDF/DocPage";

function getIndicators(indicators) {
  try {
    return JSON.parse(indicators);
  } catch (error) {
    return [];
  }
}

function DocPage() {
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);
  const api = useSelector((state) => state.API);

  React.useEffect(() => {
    const token = searchParams.get("token");
    if (!api || !token) return;
    api.setToken(token);
  }, [api, searchParams]);

  if (!api) return null;

  const indicators = searchParams.get("indicators");

  return <Doc api={api} indicators={getIndicators(indicators)} />;
}

export default React.memo(DocPage);
