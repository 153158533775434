import React from "react";
import styled from "styled-components";

import Blocks from "./Blocks";
import WelcomeBanner from "./WelcomeBanner";
import Notifications from "./Notifications";

function HomeView() {
  return (
    <MainContainer>
      <WelcomeBanner />
      <MainWrapper>
        <Blocks />
        <Notifications />
      </MainWrapper>
    </MainContainer>
  );
}

const MainContainer = styled("div")`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MainWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex: 1;
`;

export default React.memo(HomeView);
