import React from "react";
import { Button } from "@mui/material";

import { ActionDialog } from "shared/ui";

function SessionErrorModal({ onClose, openAuth }) {
  const handleButtonClick = React.useCallback(() => {
    onClose();
    openAuth();
  }, [onClose, openAuth]);

  return (
    <ActionDialog
      open
      maxWidth={600}
      onClose={onClose}
      title="Ошибка сессии"
    >
      <div style={{ textAlign: "center", marginBottom: 12 }}>Сессия истекла</div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleButtonClick} variant="contained">Открыть окно авторизации</Button>
      </div>
    </ActionDialog>
  );
}

export default React.memo(SessionErrorModal);
