import React, { Component } from "react";
import View from "./view";
import Render from "./render";

import isEqual from "lodash.isequal";
import CircularProgress from "@mui/material/CircularProgress";
import { DndContext } from "react-dnd";
import { AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import PlaceholderRendererDefault from "./placeholder-renderer-default";
import "./react-sortable-tree.css";

import {
  defaultGetNodeKey,
  defaultSearchMethod,
} from "./utils/default-handlers";

import { slideRows } from "./utils/generic-utils";
import {
  memoizedGetDescendantCount,
  memoizedGetFlatDataFromTree,
  memoizedInsertNode,
} from "./utils/memoized-tree-data-utils";
import {
  changeNodeAtPath,
  find,
  insertNode,
  removeNode,
  toggleExpandedForAll,
  walk,
} from "./utils/tree-data-utils";

let treeIdCounter = 1;

const mergeTheme = (props) => {
  const merged = {
    ...props,
    style: { ...props.theme.style, ...props.style },
    innerStyle: { ...props.theme.innerStyle, ...props.innerStyle },
  };

  const overridableDefaults = {
    nodeContentRenderer: null,
    placeholderRenderer: PlaceholderRendererDefault,
    rowHeight: 62,
    scaffoldBlockPxWidth: 44,
    slideRegionSize: 100,
    treeNodeRenderer: null,
  };
  Object.keys(overridableDefaults).forEach((propKey) => {
    // If prop has been specified, do not change it
    // If prop is specified in theme, use the theme setting
    // If all else fails, fall back to the default
    if (props[propKey] === null) {
      merged[propKey] =
        typeof props.theme[propKey] !== "undefined"
          ? props.theme[propKey]
          : overridableDefaults[propKey];
    }
  });

  return merged;
};

class ReactSortableTree extends Component {
  constructor(props) {
    super(props);

    const { dndType, nodeContentRenderer, treeNodeRenderer } = mergeTheme({
      ...props,
      theme: Render,
    });

    //    this.dndManager = new DndManager(this);

    // Wrapping classes for use with react-dnd
    this.treeId = `rst__${treeIdCounter}`;
    treeIdCounter += 1;
    this.dndType = dndType || this.treeId;
    //    this.nodeContentRenderer = this.dndManager.wrapSource(nodeContentRenderer);
    //    this.treePlaceholderRenderer = this.dndManager.wrapPlaceholder(TreePlaceholder);
    //    this.treeNodeRenderer = this.dndManager.wrapTarget(treeNodeRenderer);
    this.nodeContentRenderer = props.customNodeContentRenderer || nodeContentRenderer;
    //    this.treePlaceholderRenderer = TreePlaceholder;
    this.treeNodeRenderer = treeNodeRenderer;

    this.state = {
      isDragging: false,
      hoverIndex: { id: null, before: false },
      isLoading: false,
      draggingTreeData: null,
      draggedNode: null,
      draggedMinimumTreeIndex: null,
      draggedDepth: null,
      searchMatches: [],
      searchFocusTreeId: null,
      dragging: false,

      // props that need to be used in gDSFP or static functions will be stored here
      instanceProps: {
        treeData: [],
        ignoreOneTreeUpdate: false,
        searchQuery: null,
        searchFocusOffset: null,
      },
    };

    this.toggleChildrenVisibility = this.toggleChildrenVisibility.bind(this);
    this.moveNode = this.moveNode.bind(this);
    this.startDrag = this.startDrag.bind(this);
    this.dragHover = this.dragHover.bind(this);
    this.endDrag = this.endDrag.bind(this);
    this.drop = this.drop.bind(this);

    this.handleDndMonitorChange = this.handleDndMonitorChange.bind(this);
  }

  componentDidMount() {
    ReactSortableTree.loadLazyChildren(this.props, this.state);
    const stateUpdate = ReactSortableTree.search(
      this.props,
      this.state,
      true,
      true,
      false
    );
    this.setState(stateUpdate);

    // Hook into react-dnd state changes to detect when the drag ends
    // TODO: This is very brittle, so it needs to be replaced if react-dnd
    // offers a more official way to detect when a drag ends

    this.clearMonitorSubscription = this.props.dragDropManager
      .getMonitor()
      .subscribeToStateChange(this.handleDndMonitorChange);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { instanceProps } = prevState;
    const newState = {};
    if (nextProps.isLoading !== prevState.isLoading)
      newState.isLoading = nextProps.isLoading;

    const isTreeDataEqual = isEqual(instanceProps.treeData, nextProps.treeData);

    // make sure we have the most recent version of treeData
    instanceProps.treeData = nextProps.treeData;

    if (!isTreeDataEqual) {
      if (instanceProps.ignoreOneTreeUpdate) {
      } else {
        newState.searchFocusTreeId = null;
        ReactSortableTree.loadLazyChildren(nextProps, prevState);
        Object.assign(
          newState,
          ReactSortableTree.search(nextProps, prevState, false, false, false)
        );
      }

      newState.draggingTreeData = null;
      newState.draggedNode = null;
      newState.draggedMinimumTreeIndex = null;
      newState.draggedDepth = null;
      newState.dragging = false;
    } else if (!isEqual(instanceProps.searchQuery, nextProps.searchQuery)) {
      Object.assign(
        newState,
        ReactSortableTree.search(nextProps, prevState, true, true, false)
      );
    } else if (
      instanceProps.searchFocusOffset !== nextProps.searchFocusOffset
    ) {
      Object.assign(
        newState,
        ReactSortableTree.search(nextProps, prevState, true, true, true)
      );
    }

    instanceProps.searchQuery = nextProps.searchQuery;
    instanceProps.searchFocusOffset = nextProps.searchFocusOffset;
    instanceProps.ignoreOneTreeUpdate = false;

    newState.instanceProps = { ...instanceProps, ...newState.instanceProps };

    return newState;
  }

  // listen to dragging
  componentDidUpdate(prevProps, prevState) {
    // if it is not the same then call the onDragStateChanged
    if (this.state.dragging !== prevState.dragging) {
      if (this.props.onDragStateChanged) {
        this.props.onDragStateChanged({
          isDragging: this.state.dragging,
          draggedNode: this.state.draggedNode,
        });
      }
    }
  }

  componentWillUnmount() {
    this.clearMonitorSubscription();
  }

  getRows(treeData, hideRootNode = false, ignoreNodes = []) {
    return memoizedGetFlatDataFromTree({
      ignoreCollapsed: true,
      getNodeKey: this.props.getNodeKey,
      treeData,
      hideRootNode,
      ignoreNodes,
    });
  }

  handleDndMonitorChange() {
    const monitor = this.props.dragDropManager.getMonitor();
    this.setState({ isDragging: monitor.isDragging() });
    /*
    // If the drag ends and the tree is still in a mid-drag state,
    // it means that the drag was canceled or the dragSource dropped
    // elsewhere, and we should reset the state of this tree
    if (!monitor.isDragging() && this.state.draggingTreeData) {
      this.endDrag();
    }
*/
  }

  toggleChildrenVisibility({ node: targetNode, path }) {
    const { instanceProps } = this.state;

    let treeData = changeNodeAtPath({
      treeData: instanceProps.treeData,
      path,
      newNode: ({ node }) => ({ ...node, expanded: !node.expanded }),
      getNodeKey: this.props.getNodeKey,
    });

    if (this.state.isDragging) {
      if (
        targetNode.noChildren !== true &&
        typeof targetNode.children !== "function" &&
        targetNode.expanded !== true &&
        (!targetNode.children || targetNode.children.length === 0)
      ) {
        const childrenArray = [
          {
            id: targetNode.id + "_placeholder",
            name: () => "",
            icon: () => null,
            size: 0,
            iconType: "DnD",
            placeholder: true,
            actual: () => true,
            noChildren: true,
          },
        ];
        treeData = changeNodeAtPath({
          treeData,
          path,
          newNode: ({ node }) => ({
            ...node,
            size: childrenArray.length,
            children: childrenArray,
          }),
          getNodeKey: this.props.getNodeKey,
        });
      }
    }

    this.props.onChange(treeData);

    this.props.onVisibilityToggle({
      treeData,
      node: targetNode,
      expanded: !targetNode.expanded,
      path,
    });
  }

  moveNode({
    node,
    path: prevPath,
    treeIndex: prevTreeIndex,
    depth,
    minimumTreeIndex,
  }) {
    const {
      treeData,
      treeIndex,
      path,
      parentNode: nextParentNode,
    } = insertNode({
      treeData: this.state.draggingTreeData,
      newNode: node,
      depth,
      minimumTreeIndex,
      expandParent: true,
      getNodeKey: this.props.getNodeKey,
    });

    this.props.onChange(treeData);

    this.props.onMoveNode({
      treeData,
      node,
      treeIndex,
      path,
      nextPath: path,
      nextTreeIndex: treeIndex,
      prevPath,
      prevTreeIndex,
      nextParentNode,
    });
  }

  // returns the new state after search
  static search(props, state, seekIndex, expand, singleSearch) {
    const {
      onChange,
      getNodeKey,
      searchFinishCallback,
      searchQuery,
      searchMethod,
      searchFocusOffset,
      onlyExpandSearchedNodes,
    } = props;

    const { instanceProps } = state;

    // Skip search if no conditions are specified
    if (!searchQuery || !searchMethod) {
      //      if (searchFinishCallback) {
      //        searchFinishCallback([]);
      //      }

      return { searchMatches: [] };
    }

    const newState = { instanceProps: {} };

    // if onlyExpandSearchedNodes collapse the tree and search
    const { treeData: expandedTreeData, matches: searchMatches } = find({
      getNodeKey,
      treeData: onlyExpandSearchedNodes
        ? toggleExpandedForAll({
            treeData: instanceProps.treeData,
            expanded: false,
          })
        : instanceProps.treeData,
      searchQuery,
      searchMethod: searchMethod || defaultSearchMethod,
      searchFocusOffset,
      expandAllMatchPaths: expand && !singleSearch,
      expandFocusMatchPaths: !!expand,
    });

    // Update the tree with data leaving all paths leading to matching nodes open
    if (expand) {
      newState.instanceProps.ignoreOneTreeUpdate = true; // Prevents infinite loop
      onChange(expandedTreeData);
    }

    if (searchFinishCallback) {
      searchFinishCallback(searchMatches);
    }

    let searchFocusTreeId = null;
    if (
      seekIndex &&
      searchFocusOffset !== null &&
      searchFocusOffset < searchMatches.length
    ) {
      searchFocusTreeId = searchMatches[searchFocusOffset].node.id;
    }

    newState.searchMatches = searchMatches;
    newState.searchFocusTreeId = searchFocusTreeId;

    return newState;
  }

  startDrag({ path }) {
    this.setState((prevState) => {
      const {
        treeData: draggingTreeData,
        node: draggedNode,
        treeIndex: draggedMinimumTreeIndex,
      } = removeNode({
        treeData: prevState.instanceProps.treeData,
        path,
        getNodeKey: this.props.getNodeKey,
      });

      return {
        draggingTreeData,
        draggedNode,
        draggedDepth: path.length - 1,
        draggedMinimumTreeIndex,
        dragging: true,
      };
    });
  }

  dragHover({
    node: draggedNode,
    depth: draggedDepth,
    minimumTreeIndex: draggedMinimumTreeIndex,
  }) {
    // Ignore this hover if it is at the same position as the last hover
    if (
      this.state.draggedDepth === draggedDepth &&
      this.state.draggedMinimumTreeIndex === draggedMinimumTreeIndex
    ) {
      return;
    }

    this.setState(({ draggingTreeData, instanceProps }) => {
      // Fall back to the tree data if something is being dragged in from
      //  an external element
      const newDraggingTreeData = draggingTreeData || instanceProps.treeData;

      const addedResult = memoizedInsertNode({
        treeData: newDraggingTreeData,
        newNode: draggedNode,
        depth: draggedDepth,
        minimumTreeIndex: draggedMinimumTreeIndex,
        expandParent: true,
        getNodeKey: this.props.getNodeKey,
      });

      const rows = this.getRows(addedResult.treeData);
      const expandedParentPath = rows[addedResult.treeIndex].path;

      return {
        draggedNode,
        draggedDepth,
        draggedMinimumTreeIndex,
        draggingTreeData: changeNodeAtPath({
          treeData: newDraggingTreeData,
          path: expandedParentPath.slice(0, -1),
          newNode: ({ node }) => ({ ...node, expanded: true }),
          getNodeKey: this.props.getNodeKey,
        }),
        // reset the scroll focus so it doesn't jump back
        // to a search result while dragging
        searchFocusTreeId: null,
        dragging: true,
      };
    });
  }

  endDrag(dropResult) {
    const { instanceProps } = this.state;

    // remove placeholders
    const nodesWithPlaceholder = [];
    const callback = (info) => {
      if (
        info.node.children &&
        typeof info.node.children !== "function" &&
        info.node.children.length === 1 &&
        info.node.children[0].placeholder === true
      ) {
        nodesWithPlaceholder.push(info.node);
      }
      return true;
    };
    walk({
      treeData: instanceProps.treeData,
      getNodeKey: this.props.getNodeKey,
      callback,
      ignoreCollapsed: false,
    });
    nodesWithPlaceholder.forEach((node) => {
      delete node.children[0];
      node.children.length = 0;
      node.size = 0;
      node.expanded = undefined;
    });
    this.props.onChange([...instanceProps.treeData]);
  }

  drop(dropResult) {
    this.moveNode(dropResult);
  }

  canNodeHaveChildren(node) {
    const { canNodeHaveChildren } = this.props;
    if (canNodeHaveChildren) {
      return canNodeHaveChildren(node);
    }
    return true;
  }

  // Load any children in the tree that are given by a function
  // calls the onChange callback on the new treeData
  static loadLazyChildren(props, state) {
    const { instanceProps } = state;

    walk({
      treeData: instanceProps.treeData,
      getNodeKey: props.getNodeKey,
      callback: ({ node, path, lowerSiblingCounts, treeIndex }) => {
        if (node.expanded && Array.isArray(node.children) && node.children.length > 0) {
          props.onChange(
            changeNodeAtPath({
              treeData: instanceProps.treeData,
              path,
              newNode: ({ node: oldNode }) =>
                // Only replace the old node if it's the one we set off to find children for in the first place
                oldNode === node
                  ? {
                      ...oldNode,
                      size: node.children.length,
                      children: node.children,
                      expanded: node.children.length
                        ? oldNode.expanded
                        : undefined,
                    }
                  : oldNode,
              getNodeKey: props.getNodeKey,
            })
          );
          return;
        }

        // If the node has children defined by a function, and is either expanded
        //  or set to load even before expansion, run the function.
        if (
          node.children &&
          typeof node.children === "function" &&
          (node.expanded || props.loadCollapsedLazyChildren)
        ) {
          // Call the children fetching function
          node.children({
            node,
            path,
            lowerSiblingCounts,
            treeIndex,

            // Provide a helper to append the new data when it is received
            done: (childrenArray) => {
              if (state.isDragging) {
                if (!childrenArray || !childrenArray.length) {
                  childrenArray = [
                    {
                      id: node.id + "_placeholder",
                      name: () => "",
                      icon: () => null,
                      size: 0,
                      iconType: "DnD",
                      placeholder: true,
                      actual: () => true,
                      noChildren: true,
                    },
                  ];
                }
              }
              props.onChange(
                changeNodeAtPath({
                  treeData: instanceProps.treeData,
                  path,
                  newNode: ({ node: oldNode }) =>
                    // Only replace the old node if it's the one we set off to find children for in the first place
                    oldNode === node
                      ? {
                          ...oldNode,
                          size: childrenArray.length,
                          children: childrenArray,
                          expanded: childrenArray.length
                            ? oldNode.expanded
                            : undefined,
                        }
                      : oldNode,
                  getNodeKey: props.getNodeKey,
                })
              );
            },
          });
        }
      },
    });
  }

  onHover(id, before) {
    this.setState({ hoverIndex: { id, before } });
  }

  renderRow(
    onImageLoad,
    key,
    row,
    {
      listIndex,
      style,
      getPrevRow,
      nextRow,
      matchKeys,
      swapFrom,
      swapDepth,
      swapLength,
    }
  ) {
    const { node, parentNode, path, lowerSiblingCounts, treeIndex } = row;

    const {
      canDrag,
      generateNodeProps,
      scaffoldBlockPxWidth,
      searchFocusOffset,
      rowDirection,
    } = mergeTheme(this.props);
    const TreeNodeRenderer = this.treeNodeRenderer;
    const NodeContentRenderer = this.nodeContentRenderer;
    const nodeKey = path[path.length - 1];
    const isSearchMatch = nodeKey in matchKeys;
    const isSearchFocus =
      isSearchMatch && matchKeys[nodeKey] === searchFocusOffset;
    const callbackParams = {
      node,
      parentNode,
      path,
      lowerSiblingCounts,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
    };
    const nodeProps = !generateNodeProps
      ? {}
      : generateNodeProps(callbackParams);

    const rowCanDrag =
      typeof canDrag !== "function" ? canDrag : canDrag(callbackParams);

    const sharedProps = {
      nodeKey: this.props.getNodeKey({ node }),
      onHover: this.onHover.bind(this),
      hoverIndex: this.state.hoverIndex,
      onStartDrag: this.startDrag,
      onEndDrag: this.endDrag,
      treeInDragging: this.state.isDragging,

      treeIndex,
      scaffoldBlockPxWidth,
      node,
      path,
      nextRow,
      treeId: this.treeId,
      rowDirection,
    };

    return (
      <TreeNodeRenderer
        style={style}
        id={key}
        key={nodeKey}
        listIndex={listIndex}
        getPrevRow={getPrevRow}
        lowerSiblingCounts={lowerSiblingCounts}
        swapFrom={swapFrom}
        swapLength={swapLength}
        swapDepth={swapDepth}
        {...sharedProps}
      >
        <NodeContentRenderer
          parentNode={parentNode}
          isSearchMatch={isSearchMatch}
          isSearchFocus={isSearchFocus}
          canDrag={rowCanDrag}
          iconSize={this.props.iconSize}
          rowHeight={this.props.rowHeight}
          toggleChildrenVisibility={this.toggleChildrenVisibility}
          onImageLoad={onImageLoad}
          singleLine={this.props.singleLine}
          {...sharedProps}
          {...nodeProps}
        />
      </TreeNodeRenderer>
    );
  }

  render() {
    const { rowHeight, getNodeKey } = mergeTheme(this.props);
    const {
      searchMatches,
      searchFocusTreeId,
      draggedNode,
      draggedDepth,
      draggedMinimumTreeIndex,
      instanceProps,
    } = this.state;

    const treeData = this.state.draggingTreeData || instanceProps.treeData;

    let rows;
    let swapFrom = null;
    let swapLength = null;
    if (draggedNode && draggedMinimumTreeIndex !== null) {
      const addedResult = memoizedInsertNode({
        treeData,
        newNode: draggedNode,
        depth: draggedDepth,
        minimumTreeIndex: draggedMinimumTreeIndex,
        expandParent: true,
        getNodeKey,
      });

      const swapTo = draggedMinimumTreeIndex;
      swapFrom = addedResult.treeIndex;
      swapLength = 1 + memoizedGetDescendantCount({ node: draggedNode });
      rows = slideRows(
        this.getRows(
          addedResult.treeData,
          this.props.hideRootNode,
          this.props.ignoreNodes
        ),
        swapFrom,
        swapTo,
        swapLength
      );
    } else {
      rows = this.getRows(
        treeData,
        this.props.hideRootNode,
        this.props.ignoreNodes
      );
    }

    // Get indices for rows that match the search conditions
    const matchKeys = {};
    searchMatches.forEach(({ path }, i) => {
      matchKeys[path[path.length - 1]] = i;
    });

    // Seek to the focused search result if there is one specified
    const scrollToInfo =
      searchFocusTreeId !== null ? { scrollToId: searchFocusTreeId } : {};

    // Render list with react-virtualized
    const list = (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        <AutoSizer>
          {({ width, height }) => (
            <div style={{ width, height }}>
              <View
                data={rows}
                width={width}
                height={height - 1} // из-за округления ?
                {...scrollToInfo}
                //                onSectionRendered={onSectionRendered}
                rowRenderer={({ onImageLoad, key, index, style: rowStyle }) => {
                  // TODO: FIXME: при удаление сразу двух проектов заметил что rows[index] может быть null
                  // надо большие объекты удалять сверху (подумать)
                  return (
                    rows[index] &&
                    this.renderRow(onImageLoad, key, rows[index], {
                      listIndex: index,
                      style: rowStyle,
                      getPrevRow: () => rows[index - 1] || null,
                      nextRow: rows[index + 1],
                      matchKeys,
                      swapFrom,
                      swapDepth: draggedDepth,
                      swapLength,
                    })
                  );
                }}
              />
            </div>
          )}
        </AutoSizer>
      </div>
    );

    const loading = () => (
      <div
        style={{
          zIndex: 100,
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          boxShadow: "inset 0 0 15px 10px white",
          position: "absolute",
          display: "flex",
          height: "100%",
          width: "100%",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="primary" size={"32px"} />
      </div>
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        {this.state.isLoading ? loading() : list}
      </div>
    );
  }
}

ReactSortableTree.defaultProps = {
  canDrag: true,
  canDrop: null,
  canNodeHaveChildren: () => true,
  className: "",
  dndType: null,
  generateNodeProps: null,
  getNodeKey: defaultGetNodeKey,
  innerStyle: {},
  isVirtualized: true,
  maxDepth: null,
  treeNodeRenderer: null,
  nodeContentRenderer: null,
  onMoveNode: () => {},
  onVisibilityToggle: () => {},
  placeholderRenderer: null,
  reactVirtualizedListProps: {},
  rowHeight: null,
  scaffoldBlockPxWidth: null,
  searchFinishCallback: null,
  searchFocusOffset: null,
  searchMethod: null,
  searchQuery: null,
  shouldCopyOnOutsideDrop: false,
  slideRegionSize: null,
  style: {},
  theme: {},
  onDragStateChanged: () => {},
  onlyExpandSearchedNodes: false,
  rowDirection: "ltr",
};

const SortableTreeWithoutDndContext = (props) => (
  <DndContext.Consumer>
    {({ dragDropManager }) =>
      dragDropManager === undefined ? null : (
        <ReactSortableTree {...props} dragDropManager={dragDropManager} />
      )
    }
  </DndContext.Consumer>
);

export default SortableTreeWithoutDndContext;
