import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useSnackbar } from "notistack";
import timezone from "dayjs/plugin/timezone";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { fSelect } from "shared/ui/Editors";
import { ActionDialog, ContourBackdrop } from "shared/ui";

dayjs.extend(utc);
dayjs.extend(timezone);

const YEARS = new Array(110).fill(1).map((_, index) => ({ name: index + 1991, id: index + 1991 }));
const BASE_FIELD_PROPS = {
  fullWidth: true,
  style: "compact",
  size: "small",
};

function createDate(day, month, year) {
  const date = new Date();
  date.setDate(day);
  date.setMonth(month - 1);
  date.setFullYear(year);
  date.setHours(0, 0, 0, 0);
  return date;
}

function convertDateToGMT(date) {
  const gmtDate = dayjs(date).tz("GMT");
  return dayjs(date).add(24 - gmtDate.hour(), "hours");
}

function ShowDataComp({ onOk, title, indicatorCode }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const api = useSelector(state => state.API);

  const [value, setValue] = React.useState({ year: null, indicator: null, periodicity: null, period: null });
  const [loading, setLoading] = React.useState(true);
  const [indicators, setIndicators] = React.useState([]);

  const periodicities = React.useMemo(() => {
    if (!value.indicator) return [];
    return value.indicator.periodicity;
  }, [value]);

  const periods = React.useMemo(() => {
    if (!value.periodicity) return [];
    return value.periodicity.period.map((value, index) => ({ name: value, id: index + 1 }));
  }, [value]);

  React.useLayoutEffect(() => {
    api.send("calculation/form")
      .then(res => {
        if (!res || !Array.isArray(res)) {
          onOk();
          return;
        }
     
        if (indicatorCode) {
          const foundIndicator = res.find(({ code }) => code === indicatorCode);
          if (foundIndicator) setValue(value => ({ ...value, indicator: foundIndicator }))
        }

        setIndicators(res);
        setLoading(false);
      });
  }, [api, onOk, indicatorCode]);

  const handleValueChange = React.useCallback((key, __, fieldValue) => {
    setValue(value => ({ ...value, [key]: fieldValue }));
  }, []);
  
  const handleIndicatorChange = React.useCallback((_, __, indicator) => {
    setValue(value => ({ ...value, period: null, periodicity: null, indicator }));
  }, []);

  const handlePeriodicityChange = React.useCallback((_, __, periodicity) => {
    setValue(value => ({ ...value, period: null, periodicity }));
  }, []);

  const handleDataShow = React.useCallback(async () => {
    setLoading(true);

    const components = await api.send("registries/list", {
      associator_filter: {
        associator_code: "associator_comp",
        subject_field: "code_comp",
        object_code: value.indicator.code,
        object_field: "indicator",
        field_code: "code",
      },
      code: "indicator_np_fp",
      filter: [],
      page_number: 0,
      page_size: 50,
      sort_order: []
    });

    if (!Array.isArray(components)) {
      onOk();
      enqueueSnackbar(components?.message || "Ошибка в запросе", { variant: "error" });
      return;
    }

    const filter = components.map(({ code }, index) => {
      if (components.length === 1) return [null, "indicator_code", "=", code, false, null, "AND"];
      if (index === 0) return ["(", "indicator_code", "=", code, false, null, "OR"];
      if (index === components.length - 1) return [null, "indicator_code", "=", code, false, ")", "AND"];
      return [null, "indicator_code", "=", code, false, null, "OR"]
    });
    if (value.periodicity) filter.push([null, "periodicity", "=", value.periodicity.code, false, null, "AND"]);

    if (value.periodicity?.code === 5) {
      const startDate = convertDateToGMT(createDate(1, value.period.id, value.year));
      const endDate = startDate.add(1, "month").add(-1, "day");
      filter.push([null, "period_date_first", ">=", startDate.toISOString(), false, null, "AND"]);
      filter.push([null, "period_date_last", "<=", endDate.toISOString(), false, null, "AND"]);
    }
    
    if (value.periodicity?.code === 6) {
      const startMonth = (value.period.id - 1) * 3 + 1;
      const startDate = convertDateToGMT(createDate(1, startMonth, value.year));
      const endDate = startDate.add(3, "month").add(-1, "day");
      filter.push([null, "period_date_first", ">=", startDate.toISOString(), false, null, "AND"]);
      filter.push([null, "period_date_last", "<=", endDate.toISOString(), false, null, "AND"]);
    }
    
    if (value.periodicity?.code === 7) {
      const startDate = convertDateToGMT(createDate(1, 1, value.year));
      const endDate = startDate.add(1, "year").add(-1, "day");
      filter.push([null, "period_date_first", ">=", startDate.toISOString(), false, null, "AND"]);
      filter.push([null, "period_date_last", "<=", endDate.toISOString(), false, null, "AND"]);
    }

    const tree = await api.getTree();
    const foundTree = tree.find(({ id }) => id === "data_registry_np_fp");
    dispatch({ type: "SET_CURRENT", value: { current: { 
      id: "data_registry-filtered",
      registry: "data_registry_np_fp",
      withoutLoadSettings: true,
      hideInParams: true,
      filter,
      properties: { title: foundTree?.properties?.title, icon: foundTree?.properties?.icon },
      type: "registry"
    } } })
    onOk();
  }, [api, value, dispatch, onOk, enqueueSnackbar]);

  const isSendButtonDisabled = React.useMemo(() => {
    if (!value.indicator) return true;
    if (!value.periodicity) return false;
    if (!value.year) return true;
    if (value.periodicity.period.length === 0) return false;
    return !value.period;
  }, [value]);

  if (loading) return <ContourBackdrop />;

  const isPeriodicityDisabled = !value.indicator || periodicities.length === 0;
  const isPeriodDisabled = !value.periodicity || periods.length === 0;
  
  return (
    <ActionDialog onClose={onOk} open title={title}>
      {fSelect({ ...BASE_FIELD_PROPS, value, onChange: handleIndicatorChange, disabled: !!indicatorCode }, "Показатель", "indicator", null, { select: indicators, optionNameField: "value", optionIdField: "code" } )}
      {fSelect({ ...BASE_FIELD_PROPS, value, onChange: handlePeriodicityChange, disabled: isPeriodicityDisabled }, "Периодичность", "periodicity", null, { select: periodicities, optionNameField: "value", optionIdField: "code" } )}
      {fSelect({ ...BASE_FIELD_PROPS, value, onChange: handleValueChange, disabled: isPeriodDisabled }, "Период", "period", null, { select: periods } )}
      {fSelect({ ...BASE_FIELD_PROPS, value, onChange: handleValueChange, disabled: !value.periodicity }, "Год", "year", null, { select: YEARS, onlyIdInValue: true } )}
      <div style={{ display: "flex", justifyContent: "right", marginTop: 5 }}>
        <Button disabled={isSendButtonDisabled} onClick={handleDataShow} variant="contained">Показать данные</Button>
      </div>
    </ActionDialog>
  );
}

export default React.memo(ShowDataComp);
