import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Chip } from "@mui/material";

import { ReactComponent as ArrowLeft } from "@mdi/svg/svg/chevron-left.svg";
import { ReactComponent as ArrowRight } from "@mdi/svg/svg/chevron-right.svg";

import { Icon } from "shared/ui/ToolBar";

const CustomChip = styled(Chip)`
  border-radius: 0;
  border-right: 0;
  height: auto;
  opacity: 1 !important;
  border-color: #DCE3E5;
  background: transparent !important;
  width: 40px;
  display: flex;
  justify-content: center;

  svg {
    margin: 0 !important;
    fill: #42474D !important;
  }

  ${({ disabled }) => !disabled ? "" : `
    svg {
      fill: #B0BABF !important;
    }
  `}
`;

const FilterTagsContainer = styled("div")`
  display: flex;
  flex: 1;
  max-width: 100%;

  .MuiChip-root {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .MuiChip-label {
    font-size: 13px;
    padding: 0;
  }
`;

const FilterSelectedTagsContainer = styled("div")`
  display: flex;
  flex: 1;
  min-width: max-content;
`;

const FilterSelectedTagsResizeContainer = styled("div")`
  display: flex;
  width: 100%;
  flex: 1;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const INITIAL_TAGS_STATE = {
  leftButtonIsHidden: true,
  leftButtonIsDisabled: true,
  rightButtonIsHidden: true,
  rightButtonIsDisabled: true,
};

function ScrollableContainer({ children, style }) {
  const [selectedTagsState, setSelectedTagsState] = useState(INITIAL_TAGS_STATE);
  const [selectedTagsReseizeRef, setSelectedTagsReseizeRef] = useState(null);
  const [selectedTagsRef, setSelectedTagsContainerRef] = useState(null);

  const handleScrollTagsLeft = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const value = selectedTagsReseizeRef.scrollLeft > step ? selectedTagsReseizeRef.scrollLeft - step : 0;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    const maxLeftScroll = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    const leftButtonIsDisabled = value === 0;
    const rightButtonIsDisabled = value === maxLeftScroll - 1;
    setSelectedTagsState((state) => ({
      ...state,
      leftButtonIsDisabled,
      rightButtonIsDisabled,
    }));
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  const handleScrollTagsRight = React.useCallback(() => {
    const step = Math.floor(selectedTagsReseizeRef.offsetWidth * 0.75);
    const maxLeftScroll = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth;
    const leftScrollSpace = maxLeftScroll - 1 - selectedTagsReseizeRef.scrollLeft;
    const value = leftScrollSpace > step ? selectedTagsReseizeRef.scrollLeft + step : maxLeftScroll;
    selectedTagsReseizeRef.scrollTo({
      left: value,
      behavior: "smooth",
    });

    const leftButtonIsDisabled = value === 0;
    const rightButtonIsDisabled = value === maxLeftScroll;
    setSelectedTagsState((state) => ({
      ...state,
      leftButtonIsDisabled,
      rightButtonIsDisabled,
    }));
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  useEffect(() => {
    if (!selectedTagsRef) return;

    const resizeObserver = new ResizeObserver(() => {
      const haveHiddenContent = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
      setSelectedTagsState(
        haveHiddenContent
          ? {
              leftButtonIsHidden: false,
              leftButtonIsDisabled: true,
              rightButtonIsHidden: false,
              rightButtonIsDisabled: false,
            }
          : INITIAL_TAGS_STATE
      );
    });

    const unobserve = () => resizeObserver.unobserve(selectedTagsRef);
    resizeObserver.observe(selectedTagsRef);
    const unobserveResize = () => resizeObserver.unobserve(selectedTagsReseizeRef);
    resizeObserver.observe(selectedTagsReseizeRef);

    const haveHiddenContent = selectedTagsRef.scrollWidth - selectedTagsReseizeRef.offsetWidth > 0;
    setSelectedTagsState(
      haveHiddenContent
        ? {
            leftButtonIsHidden: false,
            leftButtonIsDisabled: true,
            rightButtonIsHidden: false,
            rightButtonIsDisabled: false,
          }
        : INITIAL_TAGS_STATE
    );

    return () => {
      unobserve();
      unobserveResize();
    };
  }, [selectedTagsRef, selectedTagsReseizeRef]);

  return (
    <FilterTagsContainer style={style}>
      <FilterSelectedTagsResizeContainer ref={setSelectedTagsReseizeRef}>
        <FilterSelectedTagsContainer ref={setSelectedTagsContainerRef}>{children}</FilterSelectedTagsContainer>
      </FilterSelectedTagsResizeContainer>        
      <CustomChip
        variant="outlined"
        disabled={selectedTagsState.leftButtonIsDisabled}
        onClick={handleScrollTagsLeft}
        label={Icon("action", ArrowLeft, 20, null, { margin: 0 })}
      />
      <CustomChip
        variant="outlined"
        disabled={selectedTagsState.rightButtonIsDisabled}
        onClick={handleScrollTagsRight}
        label={Icon("action", ArrowRight, 20, null, { margin: 0 })}
      />
    </FilterTagsContainer>
  );
}

export default React.memo(ScrollableContainer);
