import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { ActionDialog } from "shared/ui";

import { ReactComponent as UploadIcon } from "./icons/upload.svg";

import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";

import { localStorageManager } from "./localStorageManager";

export const FILE_STATUSES = {
  error: "Error",
  created: "Accepted",
  process: "InProcess",
  success: "Success",
};

function FileUploadForm({ uploadFormOpened, setUploadFormOpened }) {
  const api = useSelector((state) => state.API);

  const [filenames, setFilenames] = React.useState(null);
  const [uploadResponses, setUploadResponses] = React.useState([]);
  const [inited, setInited] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleReset = React.useCallback(() => {
    setUploadResponses([]);
    setFilenames(null);
    localStorageManager.clearData();
  }, []);

  React.useEffect(() => {
    if (uploadFormOpened) return;
    return () => {
      setFilenames(null);
      localStorageManager.clearData();
    };
  }, [uploadFormOpened]);

  const handleFilesCheck = React.useCallback(
    async (filenames) => {
      setLoading(true);
      if (!Array.isArray(filenames)) return;

      const result = await Promise.allSettled(
        filenames.map((filename) => api.send("file/check", { filename, is_ui: true }))
      );

      setUploadResponses(
        result.map(({ status, value }) => {
          if (status === "rejected") return null;
          return typeof value === "object" && value !== null && !value.error ? value : null;
        })
      );
      setLoading(false);
    },
    [api]
  );

  React.useEffect(() => {
    if (inited || !uploadFormOpened || !api) return;
    const filenames = localStorageManager.filenames;
    setInited(true);
    if (!filenames) return;
    setFilenames(filenames);
    setUploadResponses(filenames.map(() => null));
    handleFilesCheck(filenames);
  }, [inited, api, handleFilesCheck, uploadFormOpened]);

  const onFilesUploaded = React.useCallback(
    (filenames) => {
      if (!Array.isArray(filenames) || filenames.length === 0) return;
      localStorageManager.setFilenames(filenames);
      setFilenames(filenames);
      setUploadResponses(filenames.map(() => null));
      setLoading(true);
      setTimeout(() => handleFilesCheck(filenames), 3000);
    },
    [handleFilesCheck]
  );

  const onRepeatFilesCheck = React.useCallback(() => handleFilesCheck(filenames), [handleFilesCheck, filenames]);

  const title = (
    <div style={{ display: "flex", alignItems: "center", gap: "0 4px" }}>
      <IconWrapper>
        <UploadIcon />
      </IconWrapper>
      <div>Загрузка</div>
    </div>
  );

  return filenames === null ? (
    <FirstStep onFilesUploaded={onFilesUploaded} onClose={() => setUploadFormOpened(false)} opened={uploadFormOpened} title={title} />
  ) : (
    <ActionDialog onClose={() => setUploadFormOpened(false)} open={uploadFormOpened} minHeight="40vh" title={title}>
      <SecondStep
        filenames={filenames}
        uploadResponses={uploadResponses}
        onReset={handleReset}
        onRepeat={onRepeatFilesCheck}
        loading={loading}
      />
    </ActionDialog>
  );
}

const IconWrapper = styled("div")`
  svg {
    width: 24px;
    height: 24px;
    color: white;

    * {
      fill: white;
    }
  }
`;

export default React.memo(FileUploadForm);
