import React from "react";

import SortableTree, {
  getNodeAtPath,
  removeNodeAtPath,
  toggleExpandedForAll,
  walk,
} from "shared/ui/tree";
import { getNode, NodeFromItem } from "shared/ui/tree/utils";
import { OutlinedDiv } from "shared/ui";

const moveFromTo = (from, to) => {
  const getNodeKey = ({ node }) => node.id;

  from.n[0].forEach((path) => {
    const node = getNodeAtPath({
      treeData: from.d[0],
      path,
      getNodeKey,
      ignoreCollapsed: true,
    });
    from.d[0] = removeNodeAtPath({
      treeData: from.d[0],
      path,
      getNodeKey,
      ignoreCollapsed: true,
    });
    to.d[0] = [...to.d[0], node.node].sort((a, b) =>
      a.data.current.properties.title > b.data.current.properties.title ? 1 : -1
    );
  });

  from.n[1]([]);
  to.n[1]([]);

  from.s[1]([]);
  to.s[1]([]);

  to.update(to.d[0]);
  from.update(from.d[0]);
};

const moveFromToAll = (from, to) => {
  const getNodeKey = ({ node }) => node.id;

  const filterText =
    from.filter?.current?.length > 0 ? from.filter.current.toLowerCase() : null;
  if (from.n[0]) from.n[0].length = 0;
  const callback = (nodeInfo) => {
    if (filterText) {
      const text =
        nodeInfo?.node?.data?.current?.properties?.title?.toLowerCase();
      if (!text.includes(filterText)) {
        return;
      }
    }
    from.n[0].push(nodeInfo.path);
    return true;
  };
  walk({ treeData: from.d[0], getNodeKey, callback, ignoreCollapsed: true });
  moveFromTo(from, to);
};

const MultiSelectTree = (from, label, moveElement) => {
  const lastClickTime = React.useRef(null);

  const addElement = (rowInfo) => {
    from.n[0].push(rowInfo.path);
    from.s[1]([...from.s[0], rowInfo.node.id]);
  };

  const removeElement = (rowInfo) => {
    const v = [...from.s[0]];
    const ndx = v.indexOf(rowInfo.node.id);
    from.n[0].splice(ndx, 1);
    v.splice(ndx, 1);
    from.s[1](v);
  };

  return (
    <OutlinedDiv label={label}>
      <SortableTree
        enableFilter={true}
        filterRef={from.filter}
        treeData={from.d[0]}
        onChange={from.update}
        rowHeight={40}
        getNodeKey={({ node }) => node.id}
        generateNodeProps={(rowInfo) => {
          const prop = {
            onClick: (event) => {
              const isRowSelected = from.s[0].indexOf(rowInfo.node.id) !== -1;
              if (
                lastClickTime.current &&
                new Date() - lastClickTime.current <= 300
              ) {
                moveElement?.();
                return;
              }

              lastClickTime.current = new Date();
              isRowSelected ? removeElement(rowInfo) : addElement(rowInfo);
            },
          };
          prop.selected = from.s[0].indexOf(rowInfo.node.id) !== -1;
          return prop;
        }}
      />
    </OutlinedDiv>
  );
};

export {
  moveFromTo,
  moveFromToAll,
  MultiSelectTree,
  walk,
  getNode,
  getNodeAtPath,
  removeNodeAtPath,
  toggleExpandedForAll,
  NodeFromItem,
};
