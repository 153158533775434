import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { withTheme } from "@mui/styles";
import { withConfirmationDialog } from "shared/ui/ConfirmationDialog";
import ProjectTree from "components/projectTree";

const Workspace = (props) => {
  const [value, setValue] = useState({ item: null });
  const [root, setRoot] = useState(null);
  const [pendingSelectedID, setPendingSelectedID] = useState(null);
  const rootId = root?.current?.id || null;

  useEffect(() => {
    if (!props.changed) return;

    const id = value.item?.value.current.id;
    if (id && props.changed.includes(id)) {
      const name = value.item.value.current?.properties?.title;
      setValue({ item: { ...value.item, name }, pending: value.item });
    }

    if (props.changed.includes(rootId)) {
      const api = props.api;

      api &&
        api.item(id).then((res) => {
          if (!res) {
            setValue({ item: null });
          }
        });

      api &&
        pendingSelectedID &&
        api.item(pendingSelectedID).then((res) => {
          if (res) {
            setValue({
              item: {
                id: res.current?.id,
                name: res.current?.properties?.title,
                value: res,
              },
            });
          }
        });

      setPendingSelectedID(null);
    }
  }, [props.changed]);

  useEffect(() => {
    setRoot({ current: { id: "dap" } });
    props.setProjectID("dap");
  }, [props.api]);

  useEffect(() => {
    if (!value?.item && !value.pending) return;

    if (!value.item && value.pending) {
      setValue({ item: value.pending });
    } else {
      const id = value.item?.value?.current?.id;
      if (props.projectID !== id) {
        props.setProjectID(id);
      }
    }
  }, [value, value.item]);

  return rootId && <ProjectTree closeTree={props.closeTree} />;
};

const mapStateToProps = (state) => ({
  app: state.APP,
  api: state.API,
  projectID: state.projectID,
  changed: state.changed,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectID: (id) => dispatch({ type: "SET_PROJECT_ID", value: id }),
});

export default withSnackbar(withTheme(withConfirmationDialog(connect(mapStateToProps, mapDispatchToProps)(Workspace))));
