import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Chip, CircularProgress } from "@mui/material";

import BitArray from "shared/utils/BitArray";
import { TooltipR } from "shared/ui/ToolBar";
import TableEditor, { DUPLICATE_INDENTIFICATOR } from "shared/ui/TableEditor";

import { tableTypeByWidgetFieldType } from "..";

function AssociatorTable({ registryCode, actionCode, indicatorCode }) {
  const api = useSelector(state => state.API);
  
  const [columns, setColumns] = React.useState([]);
  const [registry, setRegistry] = React.useState(null);
  const [references, setReferences] = React.useState({});
  const [registryError, setRegistryError] = React.useState(false);
  const [statGridRow, setStatGridRow] = React.useState([]);
  
  const initializeRegistry = React.useCallback(async () => {
    setRegistry(null);
    setRegistryError(false);
    const registry = await api.call("registries/registry", { code: registryCode });
    if (typeof registry !== "object" || registry === null || registry?.error) return setRegistryError(true);
    const referenceKeys = Object.keys(registry.reference);
    const references = await Promise.all(referenceKeys.map((field) => api.call("registries/values", { code: registryCode, field })));
    const referencesObject = Object.fromEntries(referenceKeys.map((key, index) => [key, Array.isArray(references[index]) ? references[index] : []]));
    if (referencesObject.row_number) setStatGridRow(referencesObject.row_number);
    setReferences(referencesObject);
    setRegistry(registry);
  }, [api, registryCode]);
  
  React.useEffect(() => initializeRegistry(), [initializeRegistry]);

  React.useEffect(() => {
    if (!registry) return null;
    const foundWidget = registry.widget?.find((widget) => widget.widget_type === "grid" || widget.widget_type === "stat_grid");
    if (!foundWidget) {
      setRegistryError(true);
      return null;
    }

    const fieldGroup = Array.isArray(foundWidget.field_group) ? foundWidget.field_group[0] : foundWidget.field_group; 
    if (!fieldGroup) {
      setRegistryError(true)
      return null;
    };

    const isStatGrid = foundWidget.widget_type === "stat_grid";
    setColumns(fieldGroup.field.map((field) => {
      const isFilter = field.filter || isStatGrid ? field.source === "periodicity" || field.source === "organization" : field.code === "data_registry_np_fp_grid_data_origin";
      const isReference = field.field_type === "reference";
      const isTags = field.field_type === "json_string_array";
      const isFixedCol = field.source === "ext_code" || field.source === "value" || field.is_fixed;

      const styles = {};
      if (field.max_width) styles.maxWidth = field.max_width;

      const isFieldVisible = () => {
        if (!field.visibility) return false;
        const [code] = field.visibility.split("=");
        return actionCode === "is_comp" ? code === "is_ind" : code === "is_comp";
      }

      return {
        id: field.code,
        styles,
        properties: {
          title: field.value,
          name: field.source,
          axis: isFilter ? "filter" : isFixedCol ? "fixed" : "data",
          position: 0,
          sortOrder: "none",
          filter: [],
          textFilter: {},
          hidden: isFieldVisible(),
          source_field: field.source_field,
          field_type: field.field_type,
          type: tableTypeByWidgetFieldType(field.field_type),
          isEditable: field.editable,
          customCellRenderer: (isReference || isTags || isStatGrid) ? (value, _, rowData, column, setBackground) => {
            if (isStatGrid && column.data?.properties?.name === "fact") {
              const foundRow = statGridRow.find(({ code }) => code === rowData.row_number);
              if (!foundRow) return value;
              const foundCell = foundWidget.cell?.find(({ coordinate }) => coordinate?.includes("row=") && coordinate.split("row=")[1] === foundRow.row_number);
              if (!foundCell) return value;
              if (!!foundCell.formula) setBackground("rgba(0, 0, 0, 0.04)")
              return <div style={{ minHeight: 30 }} title={foundCell.hint}>{value}</div>
            }

            if (value === null || value === undefined) return null;

            if (isReference) {
              const source = column.data?.properties?.name?.split(DUPLICATE_INDENTIFICATOR)[0];

              if (field.source_field_type === "status" && references[source]) {
                const foundReference = references[source].find(({ code }) => code === value);
                return (
                  <TooltipR text={field.source_field ? foundReference?.[field.source_field] || null : foundReference?.value || value}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minWidth: 40 }}>
                      <div style={{ marginTop: 1, width: 12, height: 12, borderRadius: "50%", background: foundReference?.color }} />
                    </div>
                  </TooltipR>
                )
              }

              if (source && references[source]) {
                const foundReference = references[source].find(({ code }) => code === value);
                return field.source_field ? foundReference?.[field.source_field] || null : foundReference?.value || value;
              }
            }

            if (field.field_type === "json_string_array") {
              try {
                let array = null;
                try {
                  const parsedArray = JSON.parse(value);
                  if (Array.isArray(parsedArray)) array = parsedArray;
                } catch (error) {}
                if (!array) return null;
                return (
                  <div style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                    {array.map(tag => (
                      <CustomChip
                        style={{ backgroundColor: "transparent" }}
                        variant="outlined"
                        size="small"
                        label={tag}
                      />
                    ))}
                  </div>
                );
              } catch (error) {
                return value;
              }
            }

            return value;
          } : null
        }
      }
    }));
  }, [registry, references]);
  
  React.useEffect(() => {
    columns.forEach((column) => {
      if (column.properties.field_type !== "reference") return;
      const source = column.properties.name?.split(DUPLICATE_INDENTIFICATOR)[0];
      column.dict = Array.isArray(references[source]) 
        ? references[source].map((reference, index) => { 
          const name = column.properties.source_field ? reference[column.properties.source_field] || reference.value : reference.value;
          return { name, id: reference.code, isChild: !!reference.parent_code, index };
        }) 
        : [];
    });
  }, [columns, registry, references]);

  const getSelected = React.useCallback((id, ignoreAllAndNone = false, ndx = 3) => {
    const dim = columns.find(i => i.id === id)
    const f = dim.properties.filter || [];

    const result = dim.dict
    if (!Array.isArray(result)) return []

    const ba = new BitArray(result.length)
    ba.values = f

    if (dim.properties.inverse !== true)
        ba.not()

    const ret = []

    // Если выбрано все или ничего
    if (ba.count() === 0) {
        if (ignoreAllAndNone)
            return []
        result?.forEach((i) => { ret.push(i[ndx]) })
        return ret
    }
    result?.forEach((i) => { ba.get(i.index) && ret.push(i.id) })
    return ignoreAllAndNone && ret.length === result.length ? [] : ret
  }, [columns]);

  const schema = React.useMemo(() => {
    return {
      value: "roles",
      type: "Table",
      data: {
        id: "associator-table-" + indicatorCode + "-" + registryCode + "-" + actionCode,
        trDims: columns,
        apiMethod: "registries/list",
        apiPayload: { 
          code: registryCode, 
          sort_order: [["ext_code", "ASC"]], 
          associator_filter: {
            associator_code: "associator_comp",
            subject_field: actionCode === "has_comp" ? "code_comp" : "indicator",
            object_code: indicatorCode,
            object_field: actionCode === "has_comp" ? "indicator" : "code_comp",
            field_code: registryCode.startsWith("data_registry") ? "indicator_code" : "code",
          } 
        },
        selectedCounter: true,
        toolbar: [],
      },
    }
  }, [actionCode, columns, registryCode, indicatorCode])

  if (registryError) return (
    <CenteredContainer>
      Ошибка в данных
    </CenteredContainer>
  )

  if (!registry || columns.length === 0) return (
    <CenteredContainer>
      <CircularProgress color="primary" size={32} />
    </CenteredContainer>
  )

  return <TableEditor getSelected={getSelected} schema={schema} />;
}

const CenteredContainer = styled("div")`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const CustomChip = styled(Chip)`
  gap: 4px;
  svg {
    margin: 0 !important;
  }
`;

export default React.memo(AssociatorTable);
