import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";

import { ActionDialog, ContourBackdrop } from "shared/ui";
import { DoubleList } from "shared/multiList/DoubleListDialog";
import { fText } from "shared/ui/Editors";

function Components({ onOk, data }) {
  const { enqueueSnackbar } = useSnackbar();
  const api = useSelector(state => state.API);

  const updateSourceList = React.useRef();
  
  const [filter, setFilter] = React.useState({ value: "" });
  const [loading, setLoading] = React.useState(true);
  const [allIndicators, setAllIndicators] = React.useState([]);
  const [selectedIndicators, setSelectedIndicators] = React.useState([]);

  React.useEffect(() => {
    Promise.all([
      api.send("registries/list", {
        code: "indicator",
        associator_filter: {
          associator_code: "associator_comp",
          object_code: data.indicator || data.code,
          object_field: "indicator",
          subject_field: "code_comp",
          field_code: "code",
        },
        fields: ["code", "value"], 
        page_size: 99999999 
      }),
      api.send("registries/list", { 
        code: "indicator", 
        filter: [
          [null, "wf_status", "LIKE", "1.1.1%", true, null, "AND"],
          [null, "code", "<>", data.indicator || data.code, true, null, "AND"]
        ], 
        fields: ["code", "value"], 
        page_size: 99999999 
      })
    ])
      .then(([selected, all]) => {
        if (!Array.isArray(all) || !Array.isArray(selected)) return;
        setSelectedIndicators(selected
          .sort((first, second) => first.value > second.value ? 1 : -1)
          .map(({ code, value }) => ({ current: { id: code, properties: { title: value } } }))
        );
        setAllIndicators(all
          .filter(({ code }) => !selected.find((row) => row.code === code))
          .sort((first, second) => first.value > second.value ? 1 : -1)
          .map(({ code, value }) => ({ current: { id: code, properties: { title: value } } }))
        );
        setLoading(false);
      });
  }, [api, data]);

  const handleChange = React.useCallback((all, selected) => setSelectedIndicators(selected.map(({ data }) => data)), []);

  const handleSave = React.useCallback(async () => {
    setLoading(true);
    const res = await api.call("registries/components", {
      code: data.indicator || data.code,
      data: selectedIndicators.map(({ current }) => current.id)
    })

    setLoading(false);
    if (!res || res?.error) return;
    enqueueSnackbar("Сохранено", { variant: "success" });
    onOk();
  }, [api, data, enqueueSnackbar, onOk, selectedIndicators]);

  const handleValueChange = React.useCallback((key, event) => {
    const value = event.target.value;
    setFilter(filter => ({ ...filter, [key]: value }));
    const filteredIndicators = allIndicators.filter(({ current }) => {
      const isSelectedIndicator = selectedIndicators.find((selected) => selected.current.id === current.id);
      if (isSelectedIndicator) return false;
      return current.properties.title?.toLowerCase()?.includes?.(value.toLowerCase())
    });
    updateSourceList.current(filteredIndicators);
  }, [allIndicators, selectedIndicators]);

  if (loading) return <ContourBackdrop />;

  return (
    <ActionDialog minHeight="60vh" onClose={onOk} open title="Изменение состава компонент">
      {fText({ fullWidth: true, style: "compact", size: "small", value: filter, onChange: handleValueChange }, "Фильтр", "value", null)}
      <div style={{ height: 8 }} />
      <DoubleList
        onChange={handleChange}
        updateSourceList={updateSourceList}
        fromLabel="Все утвержденные показатели"
        toLabel="Выбранные показатели"
        sourceData="list"
        destinationData={["list"]}
        apiSource={() => Promise.resolve(allIndicators)}
        apiDestination={() => Promise.resolve(selectedIndicators)}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
        <Button onClick={handleSave} variant="contained">Сохранить</Button>
      </div>
    </ActionDialog>
  );
}

export default React.memo(Components);
