import React from "react";
import { useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, ContentState, EditorState } from "draft-js";

import getImageComponent from "./customRenderers/Image";
import Link from "./customRenderers/Link";
import Remove from "./RemoveToolbarButton";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

function getEditorStateFromHtml(value) {
  const blocksFromHtml = htmlToDraft(value || "", (nodeName, node) => {
    if (nodeName === "div") {
      const images = node.getElementsByTagName("img");
      if (images.length === 0) return;
      const firstImage = images.item(0);
      return {
        type: "IMAGE",
        mutability: "MUTABLE",
        data: {
          alignment: node.style.textAlign,
          alt: firstImage.alt,
          height: firstImage.style.height,
          src: firstImage.src,
          width: firstImage.style.width,
        },
      };
    }
  });
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  return EditorState.createWithContent(contentState);
}

function HtmlEditor({ value, onChange }) {
  const api = useSelector((state) => state.API);

  const [editorState, setEditorState] = React.useState(
    getEditorStateFromHtml(value)
  );

  const handleEditorStateChange = React.useCallback(
    (editorState) => {
      setEditorState(editorState);
      onChange?.(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    },
    [onChange]
  );

  const handleImageUpload = React.useCallback(
    (file) =>
      new Promise(async (res, rej) => {
        const formData = new FormData();
        formData.append("data", file);
        formData.append("token", api.token);
        const response = await fetch(api.opt.server + "/news/upload", {
          method: "POST",
          body: formData,
        }).then((response) =>
          response.status === 200 ? response.json() : null
        );
        if (!response || !response.ok || response.error || !response.url) rej();
        res({ data: { link: response.url } });
      }),
    [api]
  );

  const blockRendererFn = React.useCallback((block, config, editorState) => {
    if (block.getType() === "atomic") {
      const contentState = config.getEditorState().getCurrentContent();
      const entity = contentState.getEntity(block.getEntityAt(0));
      if (entity && entity.type === "IMAGE") {
        return {
          component: getImageComponent(config),
          editable: false,
        };
      } else if (entity && entity.type === "EMBEDDED_LINK") {
        return {
          component: Link,
          editable: false,
        };
      }
    }
  }, []);

  const toolbarConfig = React.useMemo(
    () => ({
      options: [
        "inline",
        "blockType",
        "fontSize",
        "fontFamily",
        "list",
        "textAlign",
        "colorPicker",
        "link",
        "embedded",
        "emoji",
        "image",
        "history",
      ],
      image: {
        uploadCallback: handleImageUpload,
        alt: { present: true },
        alignmentEnabled: true,
      },
    }),
    [handleImageUpload]
  );

  return (
    <div className="html-editor-container">
      <Editor
        editorState={editorState}
        toolbar={toolbarConfig}
        toolbarCustomButtons={[<Remove />]}
        customBlockRenderFunc={blockRendererFn}
        onEditorStateChange={handleEditorStateChange}
      />
    </div>
  );
}

export default React.memo(HtmlEditor);
