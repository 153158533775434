import React from "react";
import { useSelector } from "react-redux";

import { ActionDialog } from "shared/ui";
import { fText } from "shared/ui/Editors";

function getDepartmentName(user) {
  const foundShortDepartmentName = user.userProfileAttributes.find(({ key }) => key === "DEPARTMENT_SHORT_NAME");
  if (foundShortDepartmentName) return foundShortDepartmentName?.value;
  return user.userProfileAttributes.find(({ key }) => key === "DEPARTMENT_NAME")?.value || "";
}

function UserDetail({ onClose }) {
  const userData = useSelector((state) => state.userData);

  const fieldProps = React.useMemo(
    () => ({
      value: userData.userProfile,
      disabled: true,
      fullWidth: true,
      style: "compact",
      size: "small",
    }),
    [userData]
  );

  return (
    <ActionDialog open onClose={onClose} title="Профиль пользователя">
      {fText(fieldProps, "Фамилия", "nameLast", null)}
      {fText(fieldProps, "Имя", "nameFirst", null)}
      {fText(fieldProps, "Отчество", "nameMiddle", null)}
      {fText(fieldProps, "Снилс", "snils", null)}
      {fText(fieldProps, "Организация", () => getDepartmentName(userData.userProfile), null)}
      {fText(fieldProps, "Почта", "email", null)}
      {fText(fieldProps, "Номер телефона", "phoneNumber", null)}
    </ActionDialog>
  );
}

export default React.memo(UserDetail);
