import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DocRef from "components/PDF/DocRefPage";

function parseRowCodes(codes) {
  try {
    const value = JSON.parse(codes);
    return Array.isArray(value) ? value : [];
  } catch (error) {
    return [];
  }
}

function DocRefPage() {
  const { search } = useLocation();
  const [searchParams] = useSearchParams(search);
  const api = useSelector((state) => state.API);

  React.useEffect(() => {
    const token = searchParams.get("token");
    if (!api || !token) return;
    api.setToken(token);
  }, [api, searchParams]);

  if (!api) return null;

  const registryCode = searchParams.get("registry");
  const rowCode = parseRowCodes(searchParams.get("code"));

  return <DocRef api={api} registryCode={registryCode} rowCode={rowCode} />;
}

export default React.memo(DocRefPage);
