import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import { GlobalStorage } from "components/Survey/storages/GlobalStorage";

import { Button, CircularProgress } from "@mui/material";

const UPLOAD_API = process.env.REACT_APP_SURVEY_UPLOAD_API || "https://ru-teacher.contourbi.ru/api/news/upload";

interface ImageBlockInterface {
  value: null | string;
  onChange: (value: null | string) => void;
}

function ImageBlock({ value, onChange }: ImageBlockInterface) {
  const [loading, setLoading] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const openFileInput = React.useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.click();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("data", file);
    if (!GlobalStorage.user) return;
    formData.append("token", GlobalStorage.user.token);

    setLoading(true);
    fetch(UPLOAD_API, { method: "POST", body: formData })
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        if (!json || typeof json !== "object" || json.error || !json.ok) return;
        onChange(json.url);
      });
  };

  const handleImageRemove = () => {
    if (!inputRef.current) return;
    inputRef.current.value = "";
    onChange(null);
  };

  const imageSrc = value;
  const isImageExist = typeof imageSrc === "string";

  return (
    <ComponentContainer>
      <ButtonsContainer>
        <Button onClick={handleImageRemove} disabled={!isImageExist} color="primary" size="small" variant="contained">
          <span>Удалить изображение</span>
        </Button>
        <Button onClick={openFileInput} color="primary" size="small" variant="contained">
          <span>Загрузить</span>
        </Button>
      </ButtonsContainer>
      <Container>
        {isImageExist ? (
          imageSrc.includes(".svg") ? (
            <img src={imageSrc} style={{ width: "100%", height: "100%" }} />
          ) : (
            <img src={imageSrc} style={{ objectFit: "contain", width: "100%", height: "100%" }} alt="" />
          )
        ) : (
          <div
            style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}
          >
            {loading && <CircularProgress color="primary" size="32px" />}
          </div>
        )}
      </Container>
      <input
        accept="image/*"
        ref={(ref) => (inputRef.current = ref)}
        style={{ display: "none" }}
        type="file"
        onChange={handleInputChange}
      />
    </ComponentContainer>
  );
}

const ComponentContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Container = styled("div")`
  display: flex;
  overflow: hidden;
  width: 200px;
  height: 200px;
  border-radius: 4px;
  margin: 0 10px 10px 0;
  background: rgba(0, 0, 0, 0.2);
  position: relative;

  &:hover .image-block-editor-toolbar {
    top: 0%;
  }
`;

const ButtonsContainer = styled("div")`
  display: flex;
  gap: 10px;
`;

export default observer(ImageBlock);
